import React, {
  useEffect,
  useState,
  ChangeEvent,
  MouseEvent,
  useMemo,
} from "react";
import Panel from "../../Common/UI/Panel";
import ArticleApi, { Article } from "../ArticleApi";
import { container } from "./ShowArticlePage.module.scss";
import Feedback from "Article/components/Feedback";
import SpunArticlePanel from "Article/components/SpunArticlePanel";
import RawArticlePanel from "Article/components/RawArticlePanel";
import Page from "../../Common/UI/Page";

type ArticleStat = {
  thumbs_down_option: number;
  thumbs_up: boolean;
};

type ShowArticlePageProps = {
  articleId: number;
  allowWordAi: boolean;
  allowWP: boolean;
  wordAiValid: boolean;
  articleStat: ArticleStat;
};

const ShowArticlePage = (props: ShowArticlePageProps) => {
  const [wordAiValid, setWordAiValid] = useState(props.wordAiValid);
  const [articleTitle, setArticleTitle] = useState<string | null>("");
  const [updatingTitle, setUpdatingTitle] = useState(false);
  const [titleEdit, setTitleEdit] = useState(false);
  const [article, setArticle] = useState<Article | null>();

  const [showFeedback, setShowFeedback] = useState(
    props.articleStat !== null && props.articleStat.thumbs_up === null
  );
  const handleFeedbackToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowFeedback(false);
  };

  useEffect(() => {
    ArticleApi.getOne(props.articleId).then((a: Article) => {
      setArticle(a);
      setArticleTitle(a.title);
    });
  }, [props.articleId]);

  const handleTitleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setUpdatingTitle(true);

    ArticleApi.update(props.articleId, { title: articleTitle ?? "" }).then(
      (article) => {
        setUpdatingTitle(false);
        setArticle(article);
      }
    );
    setTitleEdit(false);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const button = e.target as HTMLButtonElement;
    setArticleTitle(button.value);
  };

  const handleWordAiLogin = () => {
    setWordAiValid(true);
  };

  const hasSpintax = useMemo(() => {
    return (article?.spintax ?? "").match(/\{([^{}]*\|[^{}]*)}/) !== null;
  }, [article]);

  if (!article) {
    return <div>Loading...</div>;
  }

  const handleReloadArticle = (article: Article) => {
    setArticle(article);
  };

  return (
    <Page>
      <div className={container}>
        <Panel title="Article Name">
          {titleEdit ? (
            <form className="article-title-form">
              <span className="editable-container editable-inline">
                <div
                  className="editable-input"
                  style={{ position: "relative" }}
                >
                  <input
                    disabled={updatingTitle}
                    type="text"
                    className="form-control input-sm"
                    style={{ paddingRight: "24px" }}
                    value={articleTitle ?? ""}
                    onChange={handleTitleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary editable-submit"
                  onClick={handleTitleSubmit}
                  style={{ marginLeft: "7px" }}
                >
                  <i className="ri-check-fill pr-0 mr-0"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-light editable-cancel"
                  onClick={() => setTitleEdit(!titleEdit)}
                  style={{ marginLeft: "7px" }}
                >
                  <i className="ri-close-fill pr-0 mr-0"></i>
                </button>
              </span>
            </form>
          ) : (
            <div className="article-title-form">
              <a
                className="lead"
                id="article_title"
                onClick={() => setTitleEdit(!titleEdit)}
                style={{
                  borderBottom: "1px dashed #0088cc",
                  color: "#f5831e",
                  cursor: "pointer",
                }}
              >
                {article.title}
              </a>
            </div>
          )}
        </Panel>
        {hasSpintax && (
          <SpunArticlePanel allowWP={props.allowWP} article={article} />
        )}
        <RawArticlePanel
          hasSpintax={hasSpintax}
          article={article}
          allowWordAi={props.allowWordAi}
          allowWP={props.allowWP}
          wordAiValid={wordAiValid}
          onWordAiLogin={handleWordAiLogin}
          onReloadArticle={handleReloadArticle}
        />
        {showFeedback && (
          <Feedback
            articleId={props.articleId.toString()}
            closeFeedback={handleFeedbackToggle}
          />
        )}
      </div>
    </Page>
  );
};

export default ShowArticlePage;
