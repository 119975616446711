import React from "react";
import Modal from "Common/UI/Modal";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";
import { FailedArticleRewrite } from "Article/ArticleRewriteApi";
import ModalHeader from "Common/UI/ModalHeader";

type RewriteErrorModalProps = Readonly<
  | { type?: never; rewrite: FailedArticleRewrite; onClose: () => void }
  | { type: "rewrite" | "compose"; rewrite?: never; onClose: () => void }
>;

function RewriteErrorModal({ rewrite, onClose, type }: RewriteErrorModalProps) {
  return (
    <Modal onClose={onClose}>
      <ModalHeader
        title={`Error ${type === "rewrite" ? "rewriting" : "composing"} text`}
        onClose={onClose}
      />
      <ModalBody>
        {rewrite
          ? getError(rewrite)
          : `An error was encountered when processing your ${type} request. ` +
            "Please contact customer support for assistance."}
      </ModalBody>
      <ModalFooter>
        <button onClick={onClose} className="btn btn-secondary">
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

function getError(rewrite: FailedArticleRewrite) {
  switch (rewrite.errorCode) {
    case "InsufficientUniverse":
      return (
        "Article Forge cannot write factually from this spot. " +
        "Please write a sentence or two and have Article Forge continue from there."
      );
    default:
      return (
        "Oops! Something went wrong and Article Forge was unable to write your article. " +
        "Please try again in a few minutes. We apologize for the inconvenience!"
      );
  }
}

export default RewriteErrorModal;
