import React from "react";
import PlanForm from "./PlanForm";
import Panel from "../../Common/UI/Panel";

type intervalCount = 1 | 12;

type Plan = {
  id: number;
  name: string;
  amount: number;
  unlimited: boolean;
  api_limit: number;
  sub_account_count: number;
  interval_count: intervalCount;
};

type SqueezePageProps = {
  plans: Plan[];
  isYearlyUser: boolean;
};

const SqueezePage = ({ plans, isYearlyUser }: SqueezePageProps) => {
  const children = (
    <div>
      Your current plan does not support adding multiple users or restricting
      user permissions.
      <br />
      With multiple user seats you can:
      <br />
      <ul>
        <li>Allow more than one person to use your account at a time</li>
        <li>Give additional users their own login information</li>
        <li>
          Limit access to article creation features such as posting to WordPress
          automatically
        </li>
        <li>
          Restrict access to billing information and additional usage spending
        </li>
        <li>Give others complete admin access to your account</li>
      </ul>
      To access this feature, you will need to upgrade to the plans below.
    </div>
  );

  const title = "Adding Multiple Users";

  return (
    <div className="squeeze-components">
      <Panel title={title}>{children}</Panel>
      <PlanForm plans={plans} isYearlyUser={isYearlyUser} />
    </div>
  );
};

export default SqueezePage;
