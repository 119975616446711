import { FroalaEditor, FroalaSelection } from "froala-editor";
import { ETX, STX } from "Article/ArticleParser";
import { NormalizedSuggestion } from "Article/useArticleEditor";
import styles from "./EditorSelection.module.scss";

const WORD_SEPARATORS = /[\s!?.,:;]/;

const STRING_ENDS_SENTENCE_PATTERN = /[.?!]\s*$/;

const SENTENCE_STOP_PATTERN = /\.+(?=[0-9]+)\s*|[!?]+\s*$/;

function createMarkerElement(className: string, content?: string) {
  const elem = document.createElement("span");
  elem.className = className;
  elem.style.display = "none";
  if (content) {
    elem.textContent = content;
  }

  return elem;
}

const RAW_SELECTION_START_ELEM = createMarkerElement("af-rewrite-raw-start");
const RAW_SELECTION_END_ELEM = createMarkerElement("af-rewrite-raw-end");
const COMPOSE_MARKER = createMarkerElement("af-compose-marker", `${STX}${ETX}`);
const SNAPPED_SELECTION_START_ELEM = createMarkerElement(
  "af-rewrite-snapped-start",
  STX
);
const SNAPPED_SELECTION_END_ELEM = createMarkerElement(
  "af-rewrite-snapped-end",
  ETX
);

export type SelectionElements = Readonly<{
  overflowContainer: HTMLElement;
  paragraph: HTMLElement;
  preSelection: DocumentFragment;
  postSelection: DocumentFragment;
  selection: HTMLElement;
}>;

/**
 * Given a selection, return true if it is a valid selection for compose,
 * or other return an error message to display to the user.
 */
export function validateSelectionForCompose(
  froalaSelection: FroalaSelection
): true | string {
  const selection = froalaSelection.get();

  if (
    !froalaSelection.inEditor() ||
    selection.type !== "Caret" ||
    selection.rangeCount < 1
  ) {
    return "Place your cursor anywhere in the article and click “Compose” to generate more text.";
  }
  const { startContainer, startOffset } = selection.getRangeAt(0);

  if (!paragraphOrChildOf(startContainer)) {
    return "Compose only works inside paragraphs, not headings, tables, or other elements.";
  }

  const text = startContainer.textContent;
  if (
    text &&
    startOffset !== 0 &&
    startOffset !== text.length &&
    !WORD_SEPARATORS.test(text.slice(startOffset - 1, startOffset + 1))
  ) {
    return "Compose does not work in the middle of a word.";
  }

  return true;
}

/**
 * Given a selection, return true if it is a valid selection for rewrite,
 * or otherwise return an error message to display to the user.
 */
export function validateSelectionForRewrite(
  froalaSelection: FroalaSelection
): true | string {
  const selection = froalaSelection.get();

  // If they have selected something outside the editor, disable both actions
  if (!froalaSelection.inEditor() || selection.type !== "Range") {
    return "Highlight up to a paragraph and click “Rewrite” to generate suggested rewrites.";
  }

  const { startContainer, endContainer, endOffset } = selection.getRangeAt(0);
  if (
    !textNodeOrHtmlElement(startContainer) ||
    !textNodeOrHtmlElement(endContainer)
  ) {
    return "Rewrite only works with selections inside paragraphs, not selections inside headings, tables, or other elements.";
  }

  const startParagraph = climbToParagraph(startContainer);

  // Sometimes in the browser, selecting an entire paragraph will put the endContainer
  // at the beginning of the next element.
  const endParagraph = climbToParagraph(
    endOffset === 0 &&
      endContainer instanceof HTMLElement &&
      endContainer.previousSibling instanceof HTMLElement
      ? endContainer.previousSibling
      : endContainer
  );

  if (!startParagraph || !endParagraph) {
    return "Rewrite only works with selections inside paragraphs, not selections inside headings, tables, or other elements.";
  }

  if (startParagraph !== endParagraph) {
    return "Rewrite does not work across paragraphs, only within a paragraph.";
  }

  const text = froalaSelection.text();

  if (text.length < 1) {
    return "Selection is too short to rewrite";
  }

  if (text.length > 512) {
    return "Selection is too long.";
  }

  if (/^\s+$/.test(text)) {
    return "Cannot rewrite spacing.";
  }

  return true;
}

function removeMarkers() {
  [
    RAW_SELECTION_START_ELEM,
    RAW_SELECTION_END_ELEM,
    COMPOSE_MARKER,
    SNAPPED_SELECTION_START_ELEM,

    SNAPPED_SELECTION_END_ELEM,
  ].forEach((marker) => {
    marker.remove();
  });
}

function buildComposeSelection(parent: HTMLElement, target: HTMLElement) {
  let found = false;
  const preSelection = document.createDocumentFragment();
  const postSelection = document.createDocumentFragment();
  let node = parent.firstChild;
  while (node !== null) {
    if (node === target) {
      found = true;
    } else if (node instanceof HTMLElement && node.contains(target)) {
      const subFragments = buildComposeSelection(node, target);
      preSelection.appendChild(subFragments.preSelection);
      postSelection.appendChild(subFragments.postSelection);
      found = true;
    } else if (found) {
      postSelection.appendChild(node.cloneNode(true));
    } else {
      preSelection.appendChild(node.cloneNode(true));
    }
    node = node.nextSibling;
  }

  return { preSelection, postSelection };
}

/**
 * Modifies the DOM adding in STX and ETX markers
 * where the selection starts and ends.
 *
 * For rewrite (range) selections, it snaps the selection to the sentence level,
 * adjusting the position of both the STX and ETX markers as well as the
 * selection itself.
 */
export function createSelection(
  froalaSelection: FroalaSelection
): SelectionElements {
  const selection = froalaSelection.get();
  const { startContainer, startOffset } = selection.getRangeAt(0);

  const overflowContainer = document.createElement("div");

  if (selection.type === "Caret") {
    const paragraph = climbToParagraph(startContainer);
    if (!paragraph) {
      throw new Error("Compose selection is not in a paragraph");
    }
    paragraph.insertAdjacentElement("afterend", overflowContainer);

    // mark the selection
    if (startContainer instanceof Text) {
      markSelectionInText(startContainer, startOffset, COMPOSE_MARKER);
    } else if (startContainer instanceof HTMLElement) {
      const child = startContainer.childNodes[startOffset];
      if (startContainer.childNodes.length < 1) {
        startContainer.appendChild(COMPOSE_MARKER);
      } else if (child) {
        startContainer.insertBefore(COMPOSE_MARKER, child);
      }
    }

    const { preSelection, postSelection } = buildComposeSelection(
      paragraph,
      COMPOSE_MARKER
    );

    return {
      preSelection,
      postSelection,
      selection: COMPOSE_MARKER,
      paragraph,
      overflowContainer,
    };
  }

  throw new Error("TODO: fix rewrite implementation");

  // We first need to find the start and end of the selection.
  // This is not necessarily the start and end of the selection made by the user,
  // Because we snap the selection to the sentence level and tag level.
  // Selections expand backwards until they start at the beginning of a sentence
  // or paragraph and expand forwards until the end of a sentence or paragraph.
  // Further, selections cannot start or end in the middle of a tag.
  // If a node is not a direct text node child of the paragraph being selected,
  // then then the selection expands until it is at a text node child of the
  // paragraph.
  //
  // For example (* = selection marker):
  //    <p>I'm not *anti-social. I'm just* not social.</p>
  // Expands to:
  //    <p>*I'm not anti-social. I'm just* not social.*</p>
  //
  // And:
  //    <p>I am serious. And *don't call me Shirley.*</p>
  // Expands to:
  //   <p>I am serious. *And don't call me Shirley.*</p>
  //
  // And:
  //    <p>Sentence one. Sentence two <a href="">has *weird tag <b>nesting*</b>. Very weird</a></p>
  // Expands to:
  //    <p>Sentence one. *Sentence two <a href="">has weird tag <b>nesting</b>. Very weird</a>*</p>

  // To approach this, we find the paragraph that contains both the start
  // and end of the selection. Then we recursively walk all of its children,
  // using a stack to keep track of open sentences and tags. When we find the
  // selection, we expand it backwards and forwards until it is a full
  // top level sentence.

  // Insert raw selection markers
  markRawSelectionStart(range.startContainer, range.startOffset);
  markRawSelectionEnd(range.endContainer, range.endOffset);

  // Get the parent paragraph of the selection and make sure it has both markers
  const startParagraph = climbToParagraph(RAW_SELECTION_START_ELEM);
  const endParagraph = climbToParagraph(RAW_SELECTION_END_ELEM);
  if (!startParagraph || startParagraph !== endParagraph) {
    throw new Error("Expected start and end paragraphs to be the same.");
  }
  if (
    !startParagraph.contains(RAW_SELECTION_START_ELEM) ||
    !startParagraph.contains(RAW_SELECTION_END_ELEM)
  ) {
    throw new Error(
      "Expected paragraph to contain start and end raw selection markers."
    );
  }

  // Build selection stack
  const stack = buildSelectionStack(startParagraph);

  // Wrap snapped selection and add in selection markers
  const selectionElement = document.createElement("span");
  selectionElement.appendChild(SNAPPED_SELECTION_START_ELEM);
  stack.forEach((node) => {
    selectionElement.appendChild(node.cloneNode(true));
  });
  selectionElement.appendChild(SNAPPED_SELECTION_END_ELEM);

  // Add wrapped clone to DOM
  const snappedStartNode = stack[0];
  if (!snappedStartNode || !snappedStartNode.parentElement) {
    throw new Error("Expected stack to have start node with parent.");
  }

  snappedStartNode.parentElement.insertBefore(
    selectionElement,
    snappedStartNode
  );

  // Remove original DOM nodes
  stack.forEach((node) => {
    snappedStartNode?.parentElement?.removeChild(node);
  });

  selectionElement.insertAdjacentElement("afterend", mainSuggestionElement);

  // Adjust selection range to snapped selection
  range.surroundContents(selectionElement);

  // Add overflow suggestion container
  startParagraph.insertAdjacentElement("afterend", overflowContainer);

  return {
    mainSuggestionElement,
    selectionElement,
    overflowSuggestionElement: overflowContainer,
  };
}

function buildSelectionStack(container: Node): Node[] {
  let node: Node | null = container.firstChild;
  if (!node) {
    throw new Error("Expected selection container to have children.");
  }

  // Setup stack for current top-level sentence
  let stack: Node[] = [];
  let found: "start" | "end" | null = null;

  // Walk all nodes in the paragraph
  while (node !== null) {
    let next: Node | null = null;
    if (node === RAW_SELECTION_START_ELEM) {
      found = "start";
      next = node.nextSibling;
    } else if (node === RAW_SELECTION_END_ELEM) {
      found = "end";
      next = node.nextSibling;
    } else if (node instanceof Text) {
      splitTextNodeOnSentenceBoundaries(node);

      // If this is the end of a sentence, and there is no open sentence,
      // clear the stack.
      const isEndOfSentence = STRING_ENDS_SENTENCE_PATTERN.test(
        node.textContent || ""
      );
      if (isEndOfSentence && !found) {
        stack = [];
      } else {
        // Otherwise keep pushing to the stack
        stack.push(node);
      }

      if (isEndOfSentence && found === "end") {
        return stack;
      }
      next = node.nextSibling;
    } else if (node instanceof HTMLElement) {
      const containsStart = node.contains(RAW_SELECTION_START_ELEM);
      const containsEnd = node.contains(RAW_SELECTION_END_ELEM);

      if (containsStart && containsEnd) {
        return buildSelectionStack(node);
      } else if (containsStart) {
        found = "start";
      } else if (found === "start" && containsEnd) {
        found = "end";
      }
      next = node.nextSibling;
    } else {
      // no idea what this would be
      next = node.nextSibling;
    }

    node = next;
  }
  return stack;
}

export function cancelSelection(
  elements: SelectionElements,
  editor: FroalaEditor
) {
  removeMarkers();
  elements.overflowContainer.remove();

  // Build up the original paragraph
  const nextParagraph = document.createDocumentFragment();
  nextParagraph.appendChild(elements.preSelection.cloneNode(true));
  const postSelection = elements.postSelection.cloneNode(true);
  const collapseTo = postSelection.firstChild;
  nextParagraph.appendChild(postSelection);

  // Replace paragraph in DOM
  elements.paragraph.replaceChildren(nextParagraph);

  // Turn back on editing, focus, and move cursor back to original position
  editor.edit.on();
  editor.events.focus();
  editor.selection.get().collapse(collapseTo);
}

export function renderSuggestion(
  elements: SelectionElements,
  suggestion: NormalizedSuggestion,
  mode: "display" | "apply" = "apply",
  editor: FroalaEditor
) {
  if (mode === "apply") {
    removeMarkers();
  }

  // Keep track of where to move cursor to after rendering
  let collapseTo: Node | null = null;
  let collapseOffset = 0;

  // We're going to replace contents of the paragraph in question
  const nextParagraph = document.createDocumentFragment();

  // Add pre-selection to the paragraph
  nextParagraph.appendChild(elements.preSelection.cloneNode(true));

  // Add Selection elements to to the paragraph
  if (mode === "display") {
    const selectionSpan = document.createElement("span");
    selectionSpan.className = styles.replace;
    selectionSpan.appendChild(elements.selection);
    nextParagraph.appendChild(selectionSpan);
    nextParagraph.appendChild(
      wrapSuggestionParagraphForDisplay(suggestion.headParagraph)
    );
  } else {
    nextParagraph.appendChild(
      document.createTextNode(suggestion.headParagraph)
    );
  }

  const nextOverflow = document.createDocumentFragment();
  if (suggestion.tail.length < 1) {
    const postSelection = elements.postSelection.cloneNode(true);
    collapseTo = postSelection.firstChild;
    nextParagraph.appendChild(postSelection);

    elements.paragraph.replaceChildren(nextParagraph);
  } else {
    elements.paragraph.replaceChildren(nextParagraph);

    suggestion.tail.forEach((text, index) => {
      const paragraphElem = document.createElement("p");
      const textNode = document.createTextNode(text);
      paragraphElem.appendChild(
        mode === "display" ? wrapSuggestionParagraphForDisplay(text) : textNode
      );

      collapseTo = textNode;
      collapseOffset = textNode.textContent?.length || 0;

      if (index === suggestion.tail.length - 1) {
        paragraphElem.appendChild(elements.postSelection.cloneNode(true));
      }
      nextOverflow.appendChild(paragraphElem);
    });
  }

  if (mode === "display") {
    elements.overflowContainer.replaceChildren(nextOverflow);
  } else {
    elements.overflowContainer.replaceWith(nextOverflow);

    // Move cursor to end of changed text
    editor.edit.on();
    editor.events.focus();
    editor.selection.get().collapse(collapseTo, collapseOffset);
  }
}

function wrapSuggestionParagraphForDisplay(paragraph: string) {
  const wrapper = document.createElement("span");
  wrapper.className = styles.suggestion;

  wrapper.appendChild(document.createTextNode(paragraph));
  return wrapper;
}

function splitTextNodeOnSentenceBoundaries(node: Text) {
  const content = node.textContent || "";
  const match = content.match(SENTENCE_STOP_PATTERN);
  if (match && match.index) {
    const position = match.index;
    node.splitText(position + match.length);
  }
}

function textNodeOrHtmlElement(node: Node): node is Text | HTMLElement {
  return node instanceof Text || node instanceof HTMLElement;
}

/**
 * Check if a given text node or HTML element in the froala editor
 * is a paragraph or the child of one.
 */
function paragraphOrChildOf(node: Node): boolean {
  return climbToParagraph(node) !== null;
}

/**
 * Finds the "nearest" paragraph to a given node.
 *
 * If the node itself is a paragraph, returns it.
 * Otherwise travels up tree until it finds a paragraph.
 * If it gets to the froala view container, returns null.
 *
 * @param node
 */
function climbToParagraph(node: Node): null | HTMLElement {
  const parent = node.parentElement;
  if (node instanceof HTMLElement) {
    if (node.tagName === "P") {
      return node;
    }

    if (isFroalaEditorViewNode(node)) {
      return null;
    }
  }

  if (!parent) {
    return null;
  }
  return climbToParagraph(parent);
}

function isFroalaEditorViewNode(element: HTMLElement): boolean {
  return element.classList.contains("fr-view");
}

function markRawSelectionStart(startContainer: Node, startOffset: number) {
  if (startContainer instanceof Text) {
    markSelectionInText(startContainer, startOffset, RAW_SELECTION_START_ELEM);
  } else if (startContainer instanceof HTMLElement) {
    const child = startContainer.childNodes[startOffset];
    if (child) {
      startContainer.insertBefore(RAW_SELECTION_START_ELEM, child);
    }
  }
}

function markRawSelectionEnd(endContainer: Node, endOffset: number) {
  if (endContainer instanceof Text) {
    markSelectionInText(endContainer, endOffset, RAW_SELECTION_END_ELEM);
  } else if (endContainer instanceof HTMLElement) {
    if (endOffset === 0) {
      const { previousSibling } = endContainer;
      if (previousSibling instanceof Text && previousSibling.textContent) {
        markSelectionInText(
          previousSibling,
          previousSibling.textContent.length,
          RAW_SELECTION_END_ELEM
        );
      } else if (previousSibling instanceof HTMLElement) {
        previousSibling.appendChild(RAW_SELECTION_END_ELEM);
      }
    } else {
      const child = endContainer.childNodes[endOffset];

      if (child) {
        if (child.nextSibling) {
          endContainer.insertBefore(RAW_SELECTION_END_ELEM, child.nextSibling);
        } else {
          endContainer.appendChild(RAW_SELECTION_END_ELEM);
        }
      }
    }
  }
}

function markSelectionInText(node: Text, offset: number, marker: HTMLElement) {
  const parent = node.parentElement;

  if (!parent) {
    throw new Error(
      "Invariant: expected selection container to have a parent."
    );
  }

  if (offset === 0) {
    parent?.insertBefore(marker, node);
  } else if (offset === (node.textContent || "").length - 1) {
    if (node.nextSibling) {
      parent?.insertBefore(marker, node.nextSibling);
    } else {
      parent?.appendChild(marker);
    }
  } else {
    const next = node.splitText(offset);
    parent.insertBefore(marker, next);
  }
}
