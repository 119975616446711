import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Line, Chart } from "react-chartjs-2";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import MonthlyCard from "./MonthlyCard";
import { numberToPercentage } from "../util";
import { getMrrMetrics, MrrMetrics } from "AdminMetric/AdminMetricsApi";
import { format, startOfMonth } from "date-fns";

type MrrMetricsPageProps = {
  pageTooltip: string;
};

enum ChartColor {
  Expansion = "#9ee8a7",
  Reactivation = "#73e681",
  New = "#25db3b",
  Recurring = "#06c71d",
  LengthChange = "#c5cf3c",
  Contraction = "#d96c57",
  Churn = "#e6a69a",
  Default = "#35a2eb",
}

enum MrrPeriod {
  Daily = "daily",
  Monthly = "monthly",
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);

const MrrMetricsPage = (props: MrrMetricsPageProps) => {
  const [mrrMetrics, setMrrMetrics] = useState<null | MrrMetrics>(null);

  const [fetching, setFetching] = useState(true);
  const updateBtnClickHandler = () => {
    if (dateRange.startDate && dateRange.endDate) {
      setFetching(true);
      getMrrMetrics(
        format(dateRange.startDate, "MM-dd-yyyy"),
        format(dateRange.endDate, "MM-dd-yyyy"),
        mrrPeriod
      )
        .then((res) => {
          setMrrMetrics(res);
          window.add_flash_alert_global("success", "Metrics Updated");
          setFetching(false);
        })
        .catch((err) => {
          window.add_flash_alert_global("warning", err.message);
          setFetching(false);
        });
    }
  };

  const [mrrPeriod, setMrrPeriod] = useState<MrrPeriod>(MrrPeriod.Daily);
  const mrrPeriodChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setMrrPeriod(e.target.value as MrrPeriod);
    if (dateRange.startDate) {
      setDateRange({
        ...dateRange,
        startDate: startOfMonth(dateRange.startDate),
      });
    }
  };

  const [dateRange, setDateRange] = useState<Range>({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
    key: "selection",
  });
  const dateRangeChangeHandler = (e: RangeKeyDict) => {
    if (e["selection"]) {
      let startDate = e["selection"].startDate;
      if (startDate) {
        if (mrrPeriod == MrrPeriod.Monthly) {
          startDate = startOfMonth(startDate);
        }
        setDateRange({
          startDate,
          endDate: e["selection"].endDate,
          key: e["selection"].key,
        });
      }
    }
  };

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getMrrMetrics(
        format(dateRange.startDate, "MM-dd-yyyy"),
        format(dateRange.endDate, "MM-dd-yyyy"),
        mrrPeriod
      )
        .then((res) => {
          setMrrMetrics(res);
          window.add_flash_alert_global("success", "Metrics Updated");
          setFetching(false);
        })
        .catch((err) => {
          window.add_flash_alert_global("warning", err.message);
          setFetching(false);
        });
    }
  }, []);

  const generateMrrData = () => {
    if (mrrMetrics === null) {
      return { labels: [], datasets: [] };
    }
    return {
      labels: mrrMetrics.xAxis,
      datasets: [
        {
          label: "MRR",
          data: mrrMetrics.values.map(
            (dailyMrr) =>
              dailyMrr.reactivation +
              dailyMrr.new +
              dailyMrr.expansion +
              dailyMrr.length_change +
              dailyMrr.recurring
          ),
          borderColor: ChartColor.Default,
          backgroundColor: ChartColor.Default,
        },
      ],
    };
  };

  const generateStackedData = () => {
    if (mrrMetrics === null) {
      return { labels: [], datasets: [] };
    }
    return {
      labels: mrrMetrics.xAxis,
      datasets: [
        {
          type: "line" as const,
          label: "Net MRR",
          borderColor: ChartColor.Default,
          borderWidth: 2,
          fill: false,
          data: mrrMetrics.values.map(
            (dailyMrr) =>
              dailyMrr.reactivation +
              dailyMrr.new +
              dailyMrr.expansion +
              dailyMrr.length_change +
              dailyMrr.recurring +
              dailyMrr.contraction +
              dailyMrr.churn
          ),
        },
        {
          label: "Recurring",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.recurring),
          backgroundColor: ChartColor.Recurring,
        },
        {
          label: "New",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.new),
          backgroundColor: ChartColor.New,
        },
        {
          label: "Reactivation",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.reactivation),
          backgroundColor: ChartColor.Reactivation,
        },
        {
          label: "Expansion",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.expansion),
          backgroundColor: ChartColor.Expansion,
        },
        {
          label: "Length Change",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.length_change),
          backgroundColor: ChartColor.LengthChange,
        },
        {
          label: "Contraction",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.contraction),
          backgroundColor: ChartColor.Contraction,
        },
        {
          label: "Churn",
          type: "bar" as const,
          data: mrrMetrics.values.map((dailyMrr) => dailyMrr.churn),
          backgroundColor: ChartColor.Churn,
        },
      ],
    };
  };

  return (
    <>
      <h3 className="mt-3">MRR</h3>
      <p>{props.pageTooltip}</p>

      <div style={{ display: "grid", gridTemplateColumns: "75% 25%" }}>
        {mrrMetrics === null && <div>LOADING...</div>}
        {mrrMetrics && (
          <div className="row col-sm-12 mb-3 pl-0 pr-0" id="metrics_content">
            <MonthlyCard
              colLgClass="col-lg-4"
              title="Current Month to Date"
              label={mrrMetrics.currentMonth.label}
              value={mrrMetrics.currentMonth.value}
            />
            <MonthlyCard
              colLgClass="col-lg-4"
              title="Last Month"
              label={mrrMetrics.lastMonth.label}
              value={mrrMetrics.lastMonth.value}
            />
            <MonthlyCard
              colLgClass="col-lg-4"
              title="Previous Month"
              label={mrrMetrics.twoMonth.label}
              value={mrrMetrics.twoMonth.value}
              change={numberToPercentage(
                mrrMetrics.lastMonth.value - mrrMetrics.twoMonth.value,
                mrrMetrics.twoMonth.value
              )}
            />
            <MonthlyCard
              colLgClass="col-lg-6"
              title="3 Months Ago"
              label={mrrMetrics.threeMonth.label}
              value={mrrMetrics.threeMonth.value}
              change={numberToPercentage(
                mrrMetrics.lastMonth.value - mrrMetrics.threeMonth.value,
                mrrMetrics.threeMonth.value
              )}
            />
            <MonthlyCard
              colLgClass="col-lg-6"
              title="1 Year Ago"
              label={mrrMetrics.oneYear.label}
              value={mrrMetrics.oneYear.value}
              change={numberToPercentage(
                mrrMetrics.lastMonth.value - mrrMetrics.oneYear.value,
                mrrMetrics.oneYear.value
              )}
            />
            <div id="metrics_loading" className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
            {mrrMetrics && (
              <>
                <Line data={generateMrrData()} />
                <Chart
                  type="bar"
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "MRR Stacked",
                      },
                      tooltip: {
                        mode: "index",
                      },
                    },
                    responsive: true,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  }}
                  data={generateStackedData()}
                />
              </>
            )}
          </div>
        )}
        <div className="col-sm-12" style={{ minWidth: 450 }}>
          <div className="iq-card iq-card-block">
            <div className="iq-card-body">
              <div className="row mb-3">
                <label className="control-label align-self-center mb-0 col-sm-2">
                  By:
                </label>
                <select
                  className="col-sm-10"
                  id="mrr_period_select"
                  value={mrrPeriod}
                  onChange={mrrPeriodChangeHandler}
                >
                  <option value={MrrPeriod.Daily}>{MrrPeriod.Daily}</option>
                  <option value={MrrPeriod.Monthly}>{MrrPeriod.Monthly}</option>
                </select>
              </div>
              <div className="row">
                <label className="control-label align-self-center mb-0 col-sm-2">
                  Date Range:
                </label>
                <div className="row col-sm-10">
                  <DateRange
                    ranges={[dateRange]}
                    onChange={dateRangeChangeHandler}
                    moveRangeOnFirstSelection={false}
                  />
                </div>
              </div>
              <button
                className="col-sm-12 col-sm-offset-2 btn btn-primary text-center"
                onClick={updateBtnClickHandler}
                style={{ maxWidth: 200 }}
                disabled={fetching}
              >
                {fetching ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MrrMetricsPage;
