// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ShowArticlePage-module__container___1Unvy{width:100%;margin:auto}@media (min-width: 1400px){.ShowArticlePage-module__container___1Unvy{width:970px}}\n", "",{"version":3,"sources":["/home/afweb/rails/releases/20240501201507/app/javascript/Article/components/ShowArticlePage.module.scss"],"names":[],"mappings":"AAAA,2CACE,UAAW,CACX,WAAY,CAEZ,2BAJF,2CAKI,WAAY,CAEf","file":"ShowArticlePage.module.scss","sourcesContent":[".container {\n  width: 100%;\n  margin: auto;\n\n  @media (min-width: 1400px) {\n    width: 970px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "ShowArticlePage-module__container___1Unvy"
};
module.exports = exports;
