import React, { useState, MouseEvent, useCallback } from "react";
import WordPressModal from "Article/components/WordpressModal";
import { Article } from "../ArticleApi";
import { copyHTMLToClipboard, downloadFile } from "Common/BrowserUtil";
import Panel from "Common/UI/Panel";
import ReadonlyEditor from "Article/components/ReadonlyEditor";
import ExportDropdown, { ExportType } from "Article/components/ExportDropdown";
import axios from "axios";
import { formatArticleAsText } from "Article/ArticleParser";
import useModalState from "Common/UI/useModalState";

type SpunArticlePanelProps = Readonly<{
  allowWP: boolean;
  article: Article;
}>;

const randomSpin = (spintax: string | null) => {
  while ((spintax ?? "").match(/\{([^{}]*)}/g)) {
    spintax = (spintax ?? "").replace(/\{([^{}]*)}/g, function (_str, inner) {
      const options = inner.split("|");
      return options[Math.floor(Math.random() * options.length)];
    });
  }
  return spintax;
};

const SpunArticlePanel = ({ allowWP, article }: SpunArticlePanelProps) => {
  const [spunModel, setSpunModel] = useState(randomSpin(article.spintax) || "");

  const [isCodeViewActive, setIsCodeViewActive] = useState(false);

  const [isWPModalOpen, handleOpenWPModal, handleCloseWPModal] =
    useModalState();

  const handleExportSpunModel = (type: ExportType) => {
    axios
      .post(
        "/export_article",
        new URLSearchParams({
          filename: article.title || "Article",
          content: type === "txt" ? formatArticleAsText(spunModel) : spunModel,
          type,
        }),
        { responseType: "blob" }
      )
      .then(({ data }) => {
        downloadFile(data, `${article.title}.${type}`);
      });

    window._kmq.push([
      "record",
      "Article Export",
      {
        target: "article",
        type: "Export",
        export_type: type,
      },
    ]);
  };

  const handleCopySpunModel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const html = spunModel ?? "";
    const text = formatArticleAsText(html);
    if (isCodeViewActive) {
      navigator.clipboard.writeText(html).then(() => {
        window.noty_helper(
          "Article Copied to Clipboard as HTML",
          "success",
          5000
        );
      });
    } else {
      copyHTMLToClipboard(html, text).then(() => {
        window.noty_helper(
          "Article Copied to Clipboard as Text",
          "success",
          5000
        );
      });
    }
    window._kmq.push([
      "record",
      "Article Copy",
      {
        target: "Generated Article Container",
        type: "Copy Button Clicked",
      },
    ]);
  };

  const handleRandomSpin = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setSpunModel(randomSpin(article.spintax) || "");
    },
    [article.spintax]
  );

  return (
    <div>
      {isWPModalOpen && (
        <WordPressModal
          article={article}
          content={spunModel}
          onClose={handleCloseWPModal}
        />
      )}
      <Panel title="Generated Article">
        <div
          className="btn-group"
          role="group"
          aria-label="Button group with nested dropdown"
        >
          <ExportDropdown
            onExport={handleExportSpunModel}
            onPostToWordPress={allowWP ? handleOpenWPModal : undefined}
          />
          <button className="btn btn-outline-dark" onClick={handleRandomSpin}>
            <i className="ri-refresh-fill" /> Spin
          </button>

          <button
            className="btn btn-outline-dark last-btn"
            onClick={handleCopySpunModel}
          >
            <i className="ri-clipboard-fill" />
            Copy
          </button>
        </div>
        <div style={{ paddingTop: "50px" }}>
          <ReadonlyEditor
            html={spunModel}
            onCodeViewToggled={setIsCodeViewActive}
          />
        </div>
      </Panel>
    </div>
  );
};

export default SpunArticlePanel;
