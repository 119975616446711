import React, { useEffect, useState } from "react";
import ArticleKeywordForm from "./ArticleKeywordForm";
import ArticleStructureForm from "./ArticleStructureForm";
import PostSchedulerKeywordForm from "../../PostScheduler/components/PostSchedulerKeywordForm";
import ProTips from "Article/components/ProTips";

type ArticleLength =
  | "Very Short"
  | "Short"
  | "Medium"
  | "Long"
  | "Longer"
  | "Very Long";

type NewArticleFormProps = {
  allowOutline: boolean;
  switchOutline: boolean;
  onEditPsPage: boolean;
  onNewArticlePage: boolean;
  language: string;
  isTrialUser: boolean;
  allowBulk: boolean;
  outline: string;
  switchTitle: boolean;
  selectLength: ArticleLength;
  outlineToggled: boolean;
  autoOutline: boolean;
  withProTips: boolean;
  instructions: string;
  postSchedulerKwds?: string;
  keyword: string;
};

const NewArticleForm = (props: NewArticleFormProps) => {
  const [switchOutline, setSwitchOutline] = useState(
    props.allowOutline && props.switchOutline
  );
  const [isBulk, setIsBulk] = useState(false);
  const [autoOutline, setAutoOutline] = useState(props.autoOutline);

  useEffect(() => {
    if (props.onEditPsPage) {
      window.initiate_update_ps();
    }
    if (props.onNewArticlePage) {
      window.display_mode_wordpress();
    }
  }, [props.onEditPsPage, props.onNewArticlePage]);
  return (
    <React.Fragment>
      {props.onNewArticlePage && (
        <React.Fragment>
          {props.withProTips && <ProTips />}
          <ArticleKeywordForm
            language={props.language}
            isTrialUser={props.isTrialUser}
            setIsBulk={setIsBulk}
            isBulk={isBulk}
            setAutoOutline={setAutoOutline}
            allowBulk={props.allowBulk}
            instructions={props.instructions}
            keyword={props.keyword}
          />
        </React.Fragment>
      )}

      {!props.onNewArticlePage && (
        <PostSchedulerKeywordForm keywords={props.postSchedulerKwds || ""} />
      )}

      <ArticleStructureForm
        selectLength={props.selectLength}
        onNewArticlePage={props.onNewArticlePage}
        switchOutline={switchOutline}
        onSwitchOutlineToggle={setSwitchOutline}
        outline={props.outline}
        autoOutline={autoOutline}
        onAutoOutlineToggle={setAutoOutline}
        allowOutline={props.allowOutline}
        isBulk={isBulk}
        switchTitle={props.switchTitle}
        outlineToggled={props.outlineToggled}
      />
    </React.Fragment>
  );
};

export default NewArticleForm;
