import React, { ReactNode, useCallback, useMemo, useState } from "react";
import {
  BaseToastNotification,
  ToastContext,
  ToastNotification,
  ToastState,
} from "./ToastContext";
import ToastNotificationCard from "./ToastNotificationCard";
import styles from "./Page.module.scss";

type PageProps = {
  children: ReactNode;
};

const Page = ({ children }: PageProps) => {
  const [toastState, setToastState] = useState<ToastState>({
    notifications: [],
  });
  const handleCloseNotification = useCallback((targetIndex: number) => {
    setToastState((state) => ({
      ...state,
      notifications: state.notifications.filter((_, index) => {
        return index !== targetIndex;
      }),
    }));
  }, []);

  const useToastContext = () => {
    const handleAddNotification = useCallback(
      (notification: BaseToastNotification) => {
        // default autoclose to 10s
        const ms = notification.autoCloseMs ? notification.autoCloseMs : 10000;
        const newNotification: ToastNotification = {
          ...notification,
          autoCloseMs: ms,
        };

        setToastState((state) => ({
          ...state,
          notifications: [...state.notifications, newNotification],
        }));
        const timeout = setTimeout(() => {
          setToastState((state) => ({
            ...state,
            notifications: state.notifications.filter(
              (n) => n !== newNotification
            ),
          }));
        }, ms);

        return () => {
          clearTimeout(timeout);
        };
      },
      []
    );
    return useMemo(() => ({ handleAddNotification }), [handleAddNotification]);
  };

  const toastContextValue = useToastContext();

  return (
    <ToastContext.Provider value={toastContextValue}>
      <ul className={styles.toastContainer}>
        {toastState.notifications.map((notification, index) => {
          return (
            <ToastNotificationCard
              key={index}
              onClose={() => handleCloseNotification(index)}
              notification={notification}
            />
          );
        })}
      </ul>
      {children}
    </ToastContext.Provider>
  );
};

export default Page;
