import React from "react";

type SpacerProps = Readonly<
  | {
      width: number;
      height?: never;
    }
  | { width?: never; height: number }
>;

function Spacer({ width, height }: SpacerProps) {
  return <div style={{ width: width, height: height }} />;
}

export default Spacer;
