import React from "react";
import styles from "../css/MonthlyCard.module.scss";
import { formatDollarAmount } from "Common/MoneyUtil";
import clsx from "clsx";

type MonthlyCardProps = {
  colLgClass: string;
  title: string;
  label: string;
  value: number;
  change?: number;
};

const MonthlyCard = (props: MonthlyCardProps) => {
  const positiveChange = props.change && props.change > 0;
  return (
    <div className={`col-sm-6 col-md-6 ${props.colLgClass}`}>
      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
        <div className="iq-card-body">
          <h4 className="card-title metrics-date-title">{props.title}</h4>
          <p className="card-title">{props.label}</p>
          <div className="mt-2">
            <h3 className="d-flex text-primary">
              {formatDollarAmount(props.value * 100)}
            </h3>
          </div>
          {props.change != 0 && props.change && (
            <p
              className={clsx(
                positiveChange ? styles.green : styles.red,
                "mb-0",
                "mt-1"
              )}
            >
              {`${props.change.toFixed(2)}%`}
              <i
                className={
                  positiveChange ? "ri-arrow-up-line" : "ri-arrow-down-line"
                }
                style={{ marginTop: -1 }}
              ></i>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MonthlyCard;
