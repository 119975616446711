import React from "react";
import { Dropdown, DropdownItem } from "Common/UI/Dropdown";
import { BatchExportType } from "Article/components/BatchExportModal";
import useModalState from "Common/UI/useModalState";

export type ExportType = "txt" | "pdf" | "html";

type ExportDropdownProps = Readonly<{
  onExport: (type: ExportType) => void;
  onBatchExport?: (type: BatchExportType) => void;
  onPostToWordPress?: () => void;
}>;

function ExportDropdown({
  onExport,
  onPostToWordPress,
  onBatchExport,
}: ExportDropdownProps) {
  const [open, handleOpen, handleClose] = useModalState();

  const handleExport = (type: ExportType) => {
    return () => {
      onExport(type);
      handleClose();
    };
  };

  const handleBatchExport = (type: BatchExportType) => {
    return () => {
      if (onBatchExport) {
        onBatchExport(type);
      }
      handleClose();
    };
  };

  const handlePostToWordPress = () => {
    if (onPostToWordPress) {
      onPostToWordPress();
    }
    handleClose();
  };

  return (
    <Dropdown
      label="Operations"
      firstButtonInGroup
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      <DropdownItem onClick={handleExport("txt")}>
        Export as Text (.txt)
      </DropdownItem>

      <DropdownItem onClick={handleExport("html")}>
        Export as HTML (.html)
      </DropdownItem>

      <DropdownItem onClick={handleExport("pdf")}>
        Export as PDF (.pdf)
      </DropdownItem>
      {onBatchExport && (
        <>
          <DropdownItem onClick={handleBatchExport("txt")}>
            Export Batch as Text (.txt)
          </DropdownItem>
          <DropdownItem onClick={handleBatchExport("html")}>
            Export Batch as HTML (.html)
          </DropdownItem>
        </>
      )}
      {onPostToWordPress && (
        <DropdownItem onClick={handlePostToWordPress}>
          <span className="ion-social-wordpress-outline"></span> Post to
          WordPress
        </DropdownItem>
      )}
    </Dropdown>
  );
}

export default ExportDropdown;
