// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".IconButton-module__root___30CiD{background:none;outline:none;border:0;margin:0;padding:0;display:block}.IconButton-module__root___30CiD:disabled{opacity:50%}\n", "",{"version":3,"sources":["/home/afweb/rails/releases/20240501201507/app/javascript/Article/components/rewrite/IconButton.module.scss"],"names":[],"mappings":"AAAA,iCACE,eAAgB,CAChB,YAAa,CACb,QAAS,CACT,QAAS,CACT,SAAU,CACV,aAAc,CANhB,0CASI,WAAY","file":"IconButton.module.scss","sourcesContent":[".root {\n  background: none;\n  outline: none;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  display: block;\n\n  &:disabled {\n    opacity: 50%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "IconButton-module__root___30CiD"
};
module.exports = exports;
