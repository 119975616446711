import React, { ChangeEvent, useCallback } from "react";

type ToggleProps = {
  id: string;
  checked: boolean;
  onToggle: (checked: boolean) => void;
  size: "sm" | "lg";
  onLabel?: string;
  offLabel?: string;
};

const Toggle = ({
  id,
  checked,
  onToggle,
  size,
  onLabel,
  offLabel,
}: ToggleProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onToggle(event.target.checked);
    },
    [onToggle]
  );

  return (
    <div
      className={`custom-control custom-switch custom-switch-${size} custom-control-inline`}
    >
      <input
        onChange={handleChange}
        type="checkbox"
        className="custom-control-input bg-danger"
        name={id}
        id={id}
        checked={checked}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
        data-on-label={onLabel}
        data-off-label={offLabel}
      />
    </div>
  );
};

export default Toggle;
