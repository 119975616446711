// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RewriteProcessing-module__root___uE7ut{align-items:center;background-color:rgba(255,255,255,0.6);border-radius:10px;color:var(--iq-black);display:flex;height:100%;justify-content:center;left:0;width:100%;z-index:100;position:fixed;right:0;top:50%;transform:translateY(-50%)}\n", "",{"version":3,"sources":["/home/afweb/rails/releases/20240501201507/app/javascript/Article/components/rewrite/RewriteProcessing.module.scss"],"names":[],"mappings":"AAAA,wCACE,kBAAmB,CACnB,sCAA2B,CAC3B,kBAAmB,CACnB,qBAAsB,CACtB,YAAa,CACb,WAAY,CACZ,sBAAuB,CACvB,MAAO,CACP,UAAW,CACX,WAAY,CACZ,cAAe,CACf,OAAQ,CACR,OAAQ,CACR,0BAA2B","file":"RewriteProcessing.module.scss","sourcesContent":[".root {\n  align-items: center;\n  background-color: rgba(#fff, 0.6);\n  border-radius: 10px;\n  color: var(--iq-black);\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  width: 100%;\n  z-index: 100;\n  position: fixed;\n  right: 0;\n  top: 50%;\n  transform: translateY(-50%);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "RewriteProcessing-module__root___uE7ut"
};
module.exports = exports;
