import React, { useState, ChangeEvent } from "react";
import axios from "axios";
import OverageToggleModal from "./OverageToggleModal";
import UsageLimitPanelBody from "./UsageLimitPanelBody";
import PrepayFormModal from "./PrepayFormModal";
import PrepayConfirmationModal from "./PrepayConfirmationModal";
import Panel from "../../Common/UI/Panel";
import { formatDollarAmount } from "Common/MoneyUtil";
import { useAddNotification } from "../../Common/UI/ToastContext";

type UsageLimitPanelProps = {
  isTrialUser: boolean;
  wordLimit: number;
  isOveruseAllowed: boolean;
  extraCostPerUnit: number;
  extraUnitWords: number;
  onOveragePlan: boolean;
  hasOverdueBalance: boolean;
  overdueOverageAmount: number;
  redirectFrom: string;
};

const convertDollarToWords = (dollars: number) => {
  return dollars * 4000;
};

const UsageLimitPanel = ({
  isTrialUser,
  wordLimit,
  isOveruseAllowed,
  extraCostPerUnit,
  extraUnitWords,
  onOveragePlan,
  hasOverdueBalance,
  overdueOverageAmount,
  redirectFrom,
}: UsageLimitPanelProps) => {
  const [isPrepayDisabled, setIsPrepayDisabled] = useState(false);
  const [showPrepay, setShowPrepay] = useState(isOveruseAllowed);
  const [isToggled, setIsToggled] = useState(isOveruseAllowed);
  const [isPrepayModalOpen, setIsPrepayModalOpen] = useState(false);
  const [isPrepayConfirmationModalOpen, setIsPrepayConfirmationModalOpen] =
    useState(false);
  const [isOverageModalOpen, setIsOverageModalOpen] = useState(false);
  const [prepayAmount, setPrepayAmount] = useState("");

  const addNotification = useAddNotification();

  const handleOveruseToggle = (checked: boolean) => {
    setIsToggled(!isToggled);
    if (checked) {
      setIsOverageModalOpen(true);
    } else {
      handleDisableOverage();
    }
  };

  const handlePrepayModalOpen = () => {
    setIsPrepayModalOpen(true);
  };

  const handlePrepayModalClose = () => {
    setIsPrepayModalOpen(false);
    setPrepayAmount("");
    addNotification({ type: "danger", message: "Transaction Cancelled." });
  };

  const handleOverageModalClose = () => {
    setIsOverageModalOpen(false);
    setIsToggled(false);
  };

  const handleEnableOverage = () => {
    handleOverageModalClose();
    setIsToggled(true);
    setShowPrepay(true);
    axios
      .post("/update_overuse_setting", {
        state: "false",
      })
      .then((res) => {
        if (res) {
          addNotification({
            type: "success",
            message: "Your preference is updated.",
          });
        }
      })
      .catch((res) => {
        setIsToggled(false);
        setShowPrepay(false);
        addNotification({ type: "danger", message: res.responseJSON.err_msg });
      });
  };

  const handleDisableOverage = () => {
    setShowPrepay(false);
    axios
      .post("/update_overuse_setting", {
        state: "true",
      })
      .then((res) => {
        if (res) {
          addNotification({
            type: "success",
            message: "Your preference is updated.",
          });
        }
      })
      .catch((res) => {
        setShowPrepay(true);
        addNotification({ type: "danger", message: res.responseJSON.err_msg });
      });
  };

  const handlePrepayInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPrepayAmount(e.target.value);
  };

  const handlePrepayFormSubmit = () => {
    const amount = Number(prepayAmount);
    if (isNaN(amount)) {
      addNotification({ type: "danger", message: "This is not a number" });
      setIsPrepayModalOpen(false);
      setPrepayAmount("");
    } else if (
      amount % 5 !== 0 ||
      amount <= 0 ||
      amount * 100 < overdueOverageAmount
    ) {
      addNotification({ type: "danger", message: "Invalid Amount" });
      setIsPrepayModalOpen(false);
      setPrepayAmount("");
    } else {
      setIsPrepayModalOpen(false);
      setIsPrepayConfirmationModalOpen(true);
    }
  };

  const handlePrepayConfirmationModalClose = () => {
    setPrepayAmount("");
    setIsPrepayConfirmationModalOpen(false);
    addNotification({ type: "danger", message: "Transaction Cancelled." });
  };

  const handlePrepaySubmit = () => {
    axios
      .post("/prepay_overage_charges", {
        prepay_amount: Number(prepayAmount),
      })
      .then((res) => {
        if (res.data == "Success") {
          addNotification({
            type: "success",
            message: `Payment of ${formatDollarAmount(
              Number(prepayAmount) * 100
            )} made successfully`,
          });
          setIsPrepayDisabled(true);
        } else {
          addNotification({ type: "danger", message: res.data });
        }
      })
      .catch((res) => {
        if (res.responseJSON.err_msg)
          addNotification({
            type: "danger",
            message: res.responseJSON.err_msg,
          });
      });
    setIsPrepayConfirmationModalOpen(false);
  };

  return (
    <Panel title="Increase Usage Limits">
      <UsageLimitPanelBody
        onOveragePlan={onOveragePlan}
        wordLimit={wordLimit}
        isTrialUser={isTrialUser}
        extraCostPerUnit={extraCostPerUnit}
        extraUnitWords={extraUnitWords}
        showPrepay={showPrepay}
        isPrepayDisabled={isPrepayDisabled}
        isToggled={isToggled}
        onToggle={handleOveruseToggle}
        onPrepayModalOpen={handlePrepayModalOpen}
        redirectFrom={redirectFrom}
      />
      {isPrepayModalOpen && (
        <PrepayFormModal
          prepayAmount={prepayAmount}
          hasOverdueBalance={hasOverdueBalance}
          overdueOverageAmount={overdueOverageAmount}
          onPrepayChange={handlePrepayInputChange}
          onClose={handlePrepayModalClose}
          onSubmit={handlePrepayFormSubmit}
        />
      )}
      {isPrepayConfirmationModalOpen && (
        <PrepayConfirmationModal
          prepayAmount={prepayAmount}
          wordAmount={convertDollarToWords(Number(prepayAmount))}
          onClose={handlePrepayConfirmationModalClose}
          onSubmit={handlePrepaySubmit}
        />
      )}
      {isOverageModalOpen && (
        <OverageToggleModal
          onClose={handleOverageModalClose}
          onEnableOverage={handleEnableOverage}
        />
      )}
    </Panel>
  );
};

export default UsageLimitPanel;
