import * as Decoder from "io-ts/Decoder";
import { DateDecoder, decode } from "Common/Decoders";
import axios from "axios";

export const ArticleRewriteSuggestion = Decoder.struct({
  id: Decoder.number,
  content: Decoder.string,
  number: Decoder.number,
  qualityScore: Decoder.number,
});

export type ArticleRewriteSuggestion = Decoder.TypeOf<
  typeof ArticleRewriteSuggestion
>;

export const CompleteArticleRewrite = Decoder.struct({
  id: Decoder.number,
  articleId: Decoder.number,
  userId: Decoder.number,
  status: Decoder.literal("complete"),
  instructions: Decoder.nullable(Decoder.string),
  createdAt: DateDecoder,
  updatedAt: DateDecoder,
  articleRewriteSuggestions: Decoder.array(ArticleRewriteSuggestion),
});

export type CompleteArticleRewrite = Decoder.TypeOf<
  typeof CompleteArticleRewrite
>;

export const ProcessingArticleRewrite = Decoder.struct({
  id: Decoder.number,
  articleId: Decoder.number,
  userId: Decoder.number,
  status: Decoder.literal("processing"),
  instructions: Decoder.nullable(Decoder.string),
  createdAt: DateDecoder,
  updatedAt: DateDecoder,
});

export const FailedArticleRewrite = Decoder.struct({
  id: Decoder.number,
  articleId: Decoder.number,
  userId: Decoder.number,
  status: Decoder.literal("error"),
  errorCode: Decoder.nullable(
    Decoder.literal("InsufficientUniverse", "UnknownRewriteError")
  ),
  instructions: Decoder.nullable(Decoder.string),
  createdAt: DateDecoder,
  updatedAt: DateDecoder,
});

export type FailedArticleRewrite = Decoder.TypeOf<typeof FailedArticleRewrite>;

export const ArticleRewrite = Decoder.sum("status")({
  complete: CompleteArticleRewrite,
  processing: ProcessingArticleRewrite,
  error: FailedArticleRewrite,
});

export type ArticleRewrite = Decoder.TypeOf<typeof ArticleRewrite>;

type NewArticleRewrite = Readonly<{
  articleId: number;
  instructions?: string;
  sections: readonly RewriteSection[];
}>;

export type RewriteSection = Readonly<{
  title?: string;
  body: string;
  selection?: [number, number];
}>;

export default {
  create(payload: NewArticleRewrite): Promise<ArticleRewrite> {
    return axios
      .post(`/internal_api/article_rewrites/`, payload)
      .then(({ data }) => decode(ArticleRewrite, data));
  },
  getOne(id: number): Promise<ArticleRewrite> {
    return axios
      .get(`/internal_api/article_rewrites/${id}`)
      .then(({ data }) => decode(ArticleRewrite, data));
  },
  selectSuggestion(rewriteId: number, suggestionId: number): Promise<void> {
    return axios
      .patch(
        `/internal_api/article_rewrites/${rewriteId}/article_rewrite_suggestions/${suggestionId}`
      )
      .then(() => undefined);
  },
};
