import React from "react";
import Modal from "../../Common/UI/Modal";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";

type OverageToggleModalProps = {
  onClose: () => void;
  onEnableOverage: () => void;
};

const OverageToggleModal = ({
  onClose,
  onEnableOverage,
}: OverageToggleModalProps) => {
  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <button
          className="bootbox-close-button close"
          onClick={onClose}
          style={{ marginTop: "-10px" }}
        >
          &times;
        </button>
        <div>Please confirm to enable overage use.</div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onEnableOverage}>
          OK
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default OverageToggleModal;
