export function copyHTMLToClipboard(html: string, text: string): Promise<void> {
  if (typeof ClipboardItem === "undefined") {
    return navigator.clipboard.writeText(text);
  } else {
    const htmlType = "text/html";
    const textType = "text/plain";
    const data = [
      new ClipboardItem({
        [htmlType]: new Blob([html ?? ""], { type: htmlType }),
        [textType]: new Blob([text], { type: textType }),
      }),
    ];
    return navigator.clipboard.write(data);
  }
}

export function downloadFile(data: Blob, filename: string) {
  // create file link in browser's memory
  const href = URL.createObjectURL(new Blob([data]));

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
