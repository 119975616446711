import React, { ReactNode } from "react";

type PanelProps = {
  title?: ReactNode;
  children: NonNullable<ReactNode>;
};

const Panel = ({ children, title }: PanelProps) => {
  return (
    <div className="iq-card">
      {title && (
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{title}</h4>
          </div>
        </div>
      )}
      <div className="iq-card-body">{children}</div>
    </div>
  );
};

export default Panel;
