import React from "react";
import { formatDollarAmount } from "../MoneyUtil";

type DollarAmountProps = {
  value: number;
};

const DollarAmount = ({ value }: DollarAmountProps) => {
  return <>{formatDollarAmount(value)}</>;
};

export default DollarAmount;
