import * as Decoder from "io-ts/Decoder";
import { decode } from "Common/Decoders";
import axios from "axios";

export const WordpressBlogResult = Decoder.struct({
  key: Decoder.string,
});
export type WordpressBlogResult = Decoder.TypeOf<typeof WordpressBlogResult>;

export const Blog = Decoder.struct({
  id: Decoder.number,
  blog_url: Decoder.string,
  name: Decoder.string,
  blog_id: Decoder.number,
});
export type Blog = Decoder.TypeOf<typeof Blog>;

export const BlogStatus = Decoder.struct({
  status: Decoder.boolean,
  result: Decoder.struct({
    blogs: Decoder.array(Blog),
    first_blog_categories: Decoder.array(Decoder.string),
    first_blog_tags: Decoder.array(Decoder.string),
  }),
});

export type BlogStatus = Decoder.TypeOf<typeof BlogStatus>;

type AllTerms = {
  categories: string[];
  tags: string[];
};

export default {
  getWordpressBlogs(): Promise<WordpressBlogResult> {
    return axios
      .post(`/get_wordpress_blogs`)
      .then(({ data }) => decode(WordpressBlogResult, data));
  },
  fetchBlogStatus(payload: { key: string }) {
    return axios.post("/fetch_blog_status", payload).then(({ data }) => data);
  },
  getWordpressCategoryTags(payload: {
    blog_ids: number[];
  }): Promise<WordpressBlogResult> {
    return axios
      .post("/get_wp_categories_tags", payload)
      .then(({ data }) => decode(WordpressBlogResult, data));
  },
  postArticleToWordpress(payload: {
    blog_id: string;
    article_id: string;
    content: string;
    post_title: string;
    categories: string[];
    tags: string[];
    post_status: string;
  }): Promise<WordpressBlogResult> {
    return axios
      .post("/post_article_to_blog/", payload)
      .then(({ data }) => decode(WordpressBlogResult, data));
  },
  postSpintaxToBlog(payload: {
    article_id: number;
    count_obj: Record<string, number>;
    post_status: string;
    post_style: string;
    all_terms: Record<string, string[]>;
    individual_terms: Record<string, AllTerms>;
  }) {
    return axios
      .post("/post_spintax_to_blog", payload)
      .then(({ data }) => data);
  },
};
