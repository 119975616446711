import ArticleRewriteApi, {
  ArticleRewriteSuggestion,
  CompleteArticleRewrite,
  FailedArticleRewrite,
  RewriteSection,
} from "Article/ArticleRewriteApi";
import { parseArticleSections } from "Article/ArticleParser";
import { FroalaEditor, FroalaOptions } from "froala-editor";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { captureException } from "@sentry/react";
import {
  renderSuggestion,
  createSelection,
  SelectionElements,
  validateSelectionForCompose,
  validateSelectionForRewrite,
  cancelSelection,
} from "Article/EditorSelection";
import {
  BaseToastNotification,
  ToastContext,
  useAddNotification,
} from "Common/UI/ToastContext";

export type NormalizedSuggestion = Readonly<{
  headParagraph: string;
  tail: string[];
}>;

export type RewriteActionState = Readonly<{
  disabled: boolean;
  tooltip?: string;
}>;

export type RewriteState = Readonly<
  | {
      status: "Editing";
      codeView: boolean;
      actions: {
        rewrite: RewriteActionState;
        compose: RewriteActionState;
      };
    }
  | {
      status: "Error";
      preRewriteHtml: string;
      type: "rewrite" | "compose";
    }
  | {
      status: "Failed";
      rewrite: FailedArticleRewrite;
      preRewriteHtml: string;
    }
  | {
      status: "Processing";
      sections: readonly RewriteSection[];
      preRewriteHtml: string;
    }
  | {
      status: "Selecting";
      sections: readonly RewriteSection[];
      selectionElements: SelectionElements;
      rewrite: CompleteArticleRewrite;
      normalizedSuggestions: readonly NormalizedSuggestion[];
      selectedIndex: number;
      preRewriteHtml: string;
      showTooltip: boolean;
    }
>;

const initialRewriteState: RewriteState = {
  status: "Editing",
  codeView: false,
  actions: {
    rewrite: {
      disabled: true,
      tooltip:
        "Highlight up to a paragraph and click “Rewrite” to generate suggested rewrites.",
    },
    compose: {
      disabled: true,
      tooltip:
        "Place your cursor anywhere in the article and click “Compose” to generate more text.",
    },
  },
};

export type ArticleEditorHandlers = Readonly<{
  handleCancelRewrite: () => void;
  handlePrevRewriteSuggestion: () => void;
  handleNextRewriteSuggestion: () => void;
  handleAcceptRewriteSuggestion: () => void;
  handleRewriteClick: () => void;
}>;

function useArticleEditor(
  articleId: number,
  onCodeViewChanged: (isCodeViewActive: boolean) => void
): [
  Partial<FroalaOptions>,
  RewriteState,
  FroalaEditor | null,
  ArticleEditorHandlers
] {
  const editorRef = useRef<FroalaEditor | null>(null);
  const [rewriteState, setRewriteState] = useState(initialRewriteState);
  const rewriteStateRef = useRef(rewriteState);

  useEffect(() => {
    // Keep a reference to latest rewrite state
    // Useful for references in callbacks we don't want to re-create
    rewriteStateRef.current = rewriteState;

    // If we're selecting a suggestion, render selected suggestions
    if (rewriteState.status !== "Selecting" || !editorRef.current) {
      return;
    }

    const suggestion =
      rewriteState.normalizedSuggestions[rewriteState.selectedIndex];
    if (!suggestion) {
      throw new Error(
        `No such suggestion ${rewriteState.selectedIndex} for rewrite #${rewriteState.rewrite.id}`
      );
    }
    renderSuggestion(
      rewriteState.selectionElements,
      suggestion,
      "display",
      editorRef.current
    );
  }, [rewriteState]);

  const addNotification = useAddNotification();
  const handleClick = useCallback(
    (notification: BaseToastNotification) => {
      addNotification(notification);
    },
    [addNotification]
  );

  useEffect(() => {
    // add toast notification when rewrite status is selecting
    // note: froala click event inactive when the editor is locked
    const editor = editorRef.current;
    if (rewriteState.status === "Selecting") {
      editor?.$el.parent().on("click", () => {
        handleClick({
          type: "danger",
          message: "Please select an option before editing your article.",
        });
      });
    } else {
      editor?.$el.parent().off("click");
    }
  }, [handleClick, rewriteState.status]);

  const handlePrevRewriteSuggestion = useCallback(() => {
    setRewriteState((s) => {
      if (s.status !== "Selecting") {
        throw new Error(`Unexpected rewrite state for article #${articleId}`);
      }

      return {
        ...s,
        selectedIndex: s.selectedIndex > 0 ? s.selectedIndex - 1 : 0,
      };
    });
  }, [articleId]);

  const handleNextRewriteSuggestion = useCallback(() => {
    setRewriteState((s) => {
      if (s.status !== "Selecting") {
        throw new Error(`Unexpected rewrite state for article #${articleId}`);
      }
      const max = s.rewrite.articleRewriteSuggestions.length - 1;
      return {
        ...s,
        selectedIndex: s.selectedIndex < max - 1 ? s.selectedIndex + 1 : max,
      };
    });
  }, [articleId]);

  const handleAcceptRewriteSuggestion = useCallback(() => {
    const state = rewriteStateRef.current;
    if (state.status !== "Selecting") {
      throw new Error(`Unexpected rewrite state for article #${articleId}`);
    }
    const selected =
      state.rewrite.articleRewriteSuggestions[state.selectedIndex];
    const normalizedSuggestion =
      state.normalizedSuggestions[state.selectedIndex];

    if (!selected || !normalizedSuggestion) {
      throw new Error(`No suggestion with index: ${state.selectedIndex}`);
    }
    ArticleRewriteApi.selectSuggestion(state.rewrite.id, selected.id);

    const editor = editorRef.current;
    if (!editor) {
      throw new Error("Empty editor ref; cannot not accept suggestion");
    }

    // Add in suggestion
    renderSuggestion(
      state.selectionElements,
      normalizedSuggestion,
      "apply",
      editor
    );

    editorRef.current?.events.trigger("contentChanged", [], false);

    setRewriteState(initialRewriteState);
  }, [articleId]);

  const handleCancelRewrite = useCallback(() => {
    const editor = editorRef.current;
    if (rewriteStateRef.current.status === "Editing" || !editor) {
      return;
    }
    if (rewriteStateRef.current.status === "Selecting") {
      cancelSelection(rewriteStateRef.current.selectionElements, editor);
    } else {
      editor.html.set(rewriteStateRef.current.preRewriteHtml);
      editor.edit.on();
      editor.events.focus();
    }
    setRewriteState(initialRewriteState);
  }, []);

  const handleRewriteClick = useCallback(() => {
    const editor = editorRef.current;
    editor?.events.trigger("rewrite.init", [], true);
  }, []);

  const refreshEditingState = useCallback((editor: FroalaEditor) => {
    setRewriteState((state) => {
      if (
        state.status !== "Editing" ||
        (state.codeView && editor.codeView?.isActive())
      ) {
        return state;
      }

      if (editor.codeView?.isActive()) {
        return {
          status: "Editing",
          codeView: true,
          actions: {
            rewrite: { disabled: true },
            compose: { disabled: true },
          },
        };
      }

      const compose = validateSelectionForCompose(editor.selection);
      const rewrite = validateSelectionForRewrite(editor.selection);

      return {
        status: "Editing",
        codeView: false,
        actions: {
          compose: {
            disabled: typeof compose === "string",
            tooltip:
              typeof compose === "string"
                ? compose
                : "Click or press CTRL + Enter to generate more text.",
          },
          rewrite: {
            disabled: typeof rewrite === "string",
            tooltip:
              typeof rewrite === "string"
                ? rewrite
                : "Click or press CTRL + Enter to rewrite selected text.",
          },
        },
      };
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = function (e: KeyboardEvent) {
      let handled = false;
      if (rewriteStateRef.current.status === "Selecting") {
        if (e.ctrlKey && e.key === ",") {
          handlePrevRewriteSuggestion();
          handled = true;
        } else if (e.ctrlKey && e.key === ".") {
          handleNextRewriteSuggestion();
          handled = true;
        } else if (e.ctrlKey && e.key === "/") {
          handleCancelRewrite();
          handled = true;
        } else if (e.ctrlKey && e.key === "Enter") {
          handleAcceptRewriteSuggestion();
          handled = true;
        }
      } else if (
        rewriteStateRef.current.status === "Editing" &&
        e.ctrlKey &&
        e.key === "Enter"
      ) {
        handleRewriteClick();
        handled = true;
      }

      if (handled) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("keydown", handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    handleAcceptRewriteSuggestion,
    handleCancelRewrite,
    handleNextRewriteSuggestion,
    handlePrevRewriteSuggestion,
    handleRewriteClick,
  ]);

  const config = useMemo<Partial<FroalaOptions>>(
    () => ({
      key: "WE1B5dH5H3B2A8A8D7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A4F3E3F3F2A5B4==",
      attribution: false,
      toolbarButtons: {
        edit: {
          buttons: [
            "paragraphFormat",
            "bold",
            "italic",
            "underline",
            "textColor",
            "backgroundColor",
            "align",
            "formatUL",
            "formatOLSimple",
            "insertTable",
            "insertLink",
            "insertImage",
          ],
          buttonsVisible: 12,
        },
        html: {
          buttons: ["html"],
        },
      },
      htmlUntouched: false,
      paragraphFormat: {
        H1: "Heading 1",
        H2: "Heading 2",
        H3: "Heading 3",
        H4: "Heading 4",
        N: "Normal",
      },
      events: {
        initialized: function () {
          editorRef.current = this;

          this.button.bulkRefresh();
        },
        "commands.before": function (cmd) {
          if (cmd === "html") {
            onCodeViewChanged(!this.codeView?.isActive());
          }
        },
        "image.beforeUpload": function (files: FileList) {
          const file = files[0];
          if (!file) {
            throw Error("Missing file.");
          }

          // Create a File Reader.
          const reader = new FileReader();

          // Set the reader to insert images when they are loaded.
          reader.onload = (e) => {
            if (!e.target || typeof e.target.result !== "string") {
              throw Error(
                "Image upload missing event target or target result not string"
              );
            }
            this.image.insert(e.target.result, false, {}, this.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(file);

          this.popups.hideAll();

          // Stop default upload chain.
          return false;
        },
        "rewrite.init": function (this: FroalaEditor) {
          // Disable editor
          this.edit.off();

          // Set this aside for later
          const preRewriteHtml = this.html.get();

          const selectionElements = createSelection(this.selection);

          // Parse out the selected text and section data
          const html = this.html.get();
          const sections = parseArticleSections(html);

          const errorState: RewriteState = {
            status: "Error",
            preRewriteHtml,
            type: this.selection.get().type === "Caret" ? "compose" : "rewrite",
          };

          // Update state
          setRewriteState({
            status: "Processing",
            preRewriteHtml,
            sections,
          });

          ArticleRewriteApi.create({ sections, articleId })
            .then((rewrite) => {
              pollRewrite(rewrite.id)
                .then((rewrite) => {
                  if (rewrite.status === "error") {
                    setRewriteState({
                      status: "Failed",
                      preRewriteHtml,
                      rewrite,
                    });
                    return;
                  }

                  setRewriteState({
                    status: "Selecting",
                    selectedIndex: 0,
                    rewrite,
                    preRewriteHtml,
                    sections,
                    selectionElements,
                    showTooltip: false,
                    normalizedSuggestions: normalizeSuggestions(
                      rewrite.articleRewriteSuggestions
                    ),
                  });
                })
                .catch((error) => {
                  captureException(error);
                  setRewriteState(errorState);
                });
            })
            .catch((error) => {
              captureException(error);
              setRewriteState(errorState);
            });
        },
        "buttons.refresh": function (this: FroalaEditor) {
          refreshEditingState(this);
        },
        "commands.after": function (this: FroalaEditor) {
          refreshEditingState(this);
        },
      },
    }),
    [articleId, onCodeViewChanged, refreshEditingState]
  );

  return [
    config,
    rewriteState,
    editorRef.current,
    {
      handleCancelRewrite,
      handlePrevRewriteSuggestion,
      handleNextRewriteSuggestion,
      handleAcceptRewriteSuggestion,
      handleRewriteClick,
    },
  ];
}

/**
 * Poll a given rewrite until the status changes from processing to
 * complete or error.
 * @param id Id of rewrite to poll for
 * @param interval How often to poll the backend in milliseconds
 * @param maxRetries Max number of times to retry before giving up
 */
function pollRewrite(
  id: number,
  interval = 2_000,
  maxRetries = 30
): Promise<CompleteArticleRewrite | FailedArticleRewrite> {
  return new Promise((resolve, reject) => {
    const poll = (attempt: number) => {
      if (attempt >= maxRetries) {
        reject("Exceeded max rewrite poll retries");
      }
      window.setTimeout(() => {
        ArticleRewriteApi.getOne(id).then((rewrite) => {
          if (rewrite.status === "processing") {
            poll(attempt + 1);
          } else {
            resolve(rewrite);
          }
        });
      }, interval);
    };
    poll(0);
  });
}

function normalizeSuggestions(
  suggestions: ArticleRewriteSuggestion[]
): NormalizedSuggestion[] {
  return suggestions.map((suggestion) => {
    const paragraphs = suggestion.content.split(/[\n\r]+/);

    return {
      headParagraph: paragraphs[0] || "",
      tail: paragraphs.slice(1),
    };
  });
}

export default useArticleEditor;
