import * as Decoder from "io-ts/Decoder";
import { isLeft } from "fp-ts/lib/Either";

export const DateDecoder: Decoder.Decoder<unknown, Date> = {
  decode: (u) => {
    if (typeof u !== "string") {
      return Decoder.failure(u, "Expected string");
    }
    const date = new Date(u);
    if (isNaN(date.getTime())) {
      return Decoder.failure(u, "Invalid date string");
    }
    return Decoder.success(date);
  },
};

/**
 * Takes a decoder and a JSON object to decode, runs the decoder on the JSON, and returns the
 * decoded object.
 *
 * If there is an error during decoding, in development we raise the error.
 * In production, we use a cast to return the un-decoded json as-is.
 *
 * @param decoder The Decoder.
 * @param json JSON to decode
 */
export function decode<T>(
  decoder: Decoder.Decoder<unknown, T>,
  json: unknown
): T {
  const result = decoder.decode(json);

  if (isLeft(result)) {
    const errorMsg = Decoder.draw(result.left);
    if (process.env["NODE_ENV"] === "development") {
      throw errorMsg;
    } else {
      // TODO: Log errorMsg to sentry when we add Sentry
      return json as any;
    }
  }

  return result.right;
}
