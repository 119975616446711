import React from "react";
import Modal from "../../Common/UI/Modal";
import ModalHeader from "Common/UI/ModalHeader";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";
import DollarAmount from "Common/UI/DollarAmount";

type PrepayConfirmationModalProps = {
  prepayAmount: string;
  wordAmount: number;
  onClose: () => void;
  onSubmit: () => void;
};

const PrepayConfirmationModal = ({
  prepayAmount,
  wordAmount,
  onClose,
  onSubmit,
}: PrepayConfirmationModalProps) => {
  return (
    <Modal onClose={onClose}>
      <ModalHeader title="Prepay Confirmation" onClose={onClose} />
      <ModalBody>
        <table className="table" style={{ margin: "auto" }}>
          <tbody>
            <tr>
              <th>Description</th>
              <th>Amount</th>
              <th>Words</th>
            </tr>
            <tr>
              <td>Prepay Amount</td>
              <td>
                <DollarAmount value={Number(prepayAmount) * 100} />
              </td>
              <td>{wordAmount}</td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={onSubmit}>
          Pay
        </button>
        <button className="btn btn-outline-dark" onClick={onClose}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PrepayConfirmationModal;
