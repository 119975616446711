import React, { ReactNode } from "react";

type ModalHeaderProps = Readonly<{
  title: ReactNode;
  children?: ReactNode;
  onClose: () => void;
}>;

function ModalHeader({ title, children, onClose }: ModalHeaderProps) {
  return (
    <div className="modal-header">
      <div>
        <h4 className="modal-title">{title}</h4>
        {children}
      </div>
      <button
        type="button"
        className="close"
        onClick={onClose}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

export default ModalHeader;
