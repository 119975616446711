import React, { ReactElement } from "react";
import styles from "./IconButton.module.scss";

type IconButtonProps = Readonly<{
  title: string;
  onClick: () => void;
  children: ReactElement;
  disabled?: boolean;
}>;

function IconButton({ disabled, title, onClick, children }: IconButtonProps) {
  return (
    <button
      className={styles.root}
      disabled={disabled}
      type="button"
      title={title}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default IconButton;
