import React, { MouseEvent } from "react";
import styles from "./Feedback.module.scss";

type ClickHandler<E = HTMLButtonElement> = (event: MouseEvent<E>) => void;

type FeedbackProps = {
  articleId: string;
  closeFeedback: ClickHandler;
};

const Feedback = (props: FeedbackProps) => {
  return (
    <div id="feedback_box" className={styles.feedbackBoxWithRewrite}>
      <div
        // id necessary in articles.js: feedback_panel
        id="feedback_panel_with_rewrite"
        className={`panel panel-default ${styles.feedbackPanel}`}
      >
        {/* <button
          type="button"
          className="close close-x "
          onClick={props.closeFeedback}
        >
          x
        </button> */}
        <div id="feedback_title" style={{ textAlign: "center" }}>
          <b style={{ color: "black" }}>Did you like this article?</b>
          <button
            id="positive_btn"
            className={`btn btn-default thumbs-up fa fa-thumbs-o-up btn-icon ${styles.feedbackButtons}`}
            onClick={() => window.positiveFeedback(props.articleId)}
          ></button>
          <button
            id="negative_btn"
            className={`btn btn-default thumbs-down fa fa-thumbs-o-down btn-icon ${styles.feedbackButtons}`}
            onClick={window.negativeFeedback}
          ></button>
        </div>
      </div>
      <div
        id="thanks1_panel"
        className="panel panel-default"
        style={{ display: "none" }}
      >
        <button
          type="button"
          className="close close-x "
          onClick={props.closeFeedback}
        >
          x
        </button>
        <div id="thanks_1" style={{ textAlign: "center" }}>
          <h5 className={styles.upvoteResponse}>
            Thank you for your feedback! 🎉
          </h5>
        </div>
      </div>
      <div
        id="thanks2_panel"
        className="panel panel-default"
        style={{ display: "none" }}
      >
        <button
          type="button"
          className="close close-x "
          onClick={props.closeFeedback}
        >
          x
        </button>
        <div id="thanks_2" style={{ textAlign: "center" }}>
          <h5 className={styles.downvoteResponse}>
            We appreciate your feedback, it helps Article Forge learn and helps
            us focus on what to improve in our next updates. Thanks!
          </h5>
        </div>
      </div>
      <div
        id="radio_panel"
        className="panel panel-default"
        style={{ display: "none" }}
      >
        <button
          id="radio_panel_close_button"
          type="button"
          className="close close-x "
          onClick={props.closeFeedback}
        >
          x
        </button>
        <div id="radio_title" style={{ textAlign: "center" }}>
          <h4>
            <b>What did you not like about this article?</b>
          </h4>
        </div>
        <div id="feedback_radios">
          <div className="row">
            <div className="col-md-10 col-xs-10 col-sm-10 col-md-offset-1 col-sm-offset-1 col-xs-offset-1 col-lg-10 col-lg-offset-1">
              <div className="form-group" style={{ textAlign: "left" }}>
                <div style={{ marginTop: "5px" }}>
                  <button
                    className={`btn btn-outline-dark btn-radio radio-choice ${styles.radioOptions}`}
                    onClick={() => window.radioClick(props.articleId, 1)}
                  >
                    The article is not accurate
                  </button>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <button
                    className={`btn btn-outline-dark btn-radio radio-choice ${styles.radioOptions}`}
                    onClick={() => window.radioClick(props.articleId, 2)}
                  >
                    The article is not relevant
                  </button>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <button
                    className={`btn btn-outline-dark btn-radio radio-choice ${styles.radioOptions}`}
                    onClick={() => window.radioClick(props.articleId, 3)}
                  >
                    The article is too general or lacks details
                  </button>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <button
                    className={`btn btn-outline-dark btn-radio radio-choice ${styles.radioOptions}`}
                    onClick={() => window.radioClick(props.articleId, 4)}
                  >
                    The article is repeating sentences or ideas
                  </button>
                </div>
                <div id="something" style={{ marginTop: "5px" }}>
                  <button
                    id="somethingElseButton"
                    className={`btn btn-outline-dark btn-radio ${styles.radioOptions}`}
                    onClick={window.radioClickInput}
                  >
                    Something else
                  </button>
                </div>
                <div id="else" style={{ marginTop: "5px", display: "none" }}>
                  <div className={`input-group ${styles.customInput}`}>
                    <input
                      className="form-control"
                      id="appendedInputButton"
                      type="text"
                      placeholder="Your feedback"
                      style={{ whiteSpace: "normal" }}
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary radio-choice"
                        type="button"
                        onClick={() => window.radioClick(props.articleId, 0)}
                      >
                        Submit
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
