import React, { useState, ChangeEvent } from "react";

type ArticleKeywordFormProps = {
  setAutoOutline: (value: boolean) => void;
  setIsBulk: (value: boolean) => void;
  isBulk: boolean;
  allowBulk: boolean;
  isTrialUser: boolean;
  language: string;
  instructions: string;
  keyword: string;
};

const ArticleKeywordForm = (props: ArticleKeywordFormProps) => {
  const { setAutoOutline, setIsBulk, isBulk, allowBulk } = props;

  const [language, setLanguage] = useState(props.language);
  const languageChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
    if (e.target.value !== "en" && language === "en") {
      window.hideAdvancedSetting();
    } else if (e.target.value === "en" && language !== "en") {
      window.showAdvancedSetting();
    }
  };

  const switchToBulkSpinnerHandler = () => {
    if (allowBulk) {
      window.switchToBulkSpinner(true, props.isTrialUser);
      setIsBulk(true);
      setAutoOutline(true);
    } else {
      window.add_flash_alert_global(
        "warning",
        "You cannot create articles in bulk. Your role must be at least Manager to access this feature. Please contact your account admin to update your user permissions."
      );
    }
  };

  const switchToRegularSpinnerHandler = () => {
    window.switchToBulkSpinner(false, props.isTrialUser);
    setIsBulk(false);
  };

  const bulkPlaceholderAndHint = {
    placeholder: "Keyword1\nKeyword2\nKeyword3\n...",
    hint: (
      <div>
        Keywords cannot contain special characters such as commas, parentheses,
        brackets and must be no more than 50 words.
      </div>
    ),
  };

  const validateInstruction = (instructions: string) => {
    if (instructions.length > 500) {
      return {
        instructionsValid: false,
        instructionsWarningMsg:
          "Warning: Instructions cannot exceed 500 characters",
      };
    }
    return {
      instructionsValid: true,
      instructionsWarningMsg: "",
    };
  };

  const [instructionsWarning, setInstructionsWarning] = useState({
    show: false,
    msg: "",
  });

  const [instructions, setInstructions] = useState(props.instructions);

  const instructionsChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(e.target.value);
    const { instructionsValid, instructionsWarningMsg } = validateInstruction(
      e.target.value
    );
    if (!instructionsValid) {
      setInstructionsWarning({ show: true, msg: instructionsWarningMsg });
    } else {
      setInstructionsWarning({
        show: false,
        msg: "",
      });
    }
  };

  const validateKeyword = (keyword: string) => {
    if (keyword.length > 100) {
      return {
        keywordValid: false,
        keywordWarningMsg: "Warning: keyword cannot exceed 100 characters",
      };
    }
    if (keyword.split(" ").length > 50) {
      return {
        keywordValid: false,
        keywordWarningMsg: "Warning: keyword cannot exceed 50 words",
      };
    }
    return {
      keywordValid: true,
      keywordWarningMsg: "",
    };
  };

  const [keywordWarning, setKeywordWarning] = useState({
    show: false,
    msg: "",
  });

  const [keyword, setKeyword] = useState(props.keyword);

  const keywordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    const { keywordValid, keywordWarningMsg } = validateKeyword(e.target.value);
    if (!keywordValid) {
      setKeywordWarning({ show: true, msg: keywordWarningMsg });
    } else {
      setKeywordWarning({
        show: false,
        msg: "",
      });
    }
  };

  const [bulkKeywords, setBulkKeywords] = useState("");
  const [showSubKwdWarning, setShowSubKwdWarning] = useState(false);

  const bulkKeywordsChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newBulkKeywords = e.target.value;
    setBulkKeywords(newBulkKeywords);
    setShowSubKwdWarning(newBulkKeywords.includes(","));
  };

  return (
    <div className="iq-card" id="first_field">
      <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title">
          <h4 className="card-title">Create a New Article</h4>
        </div>
        <span> Choose the topic of your article</span>
      </div>
      <div className="iq-card-body">
        <div id="regular-spin">
          <div className="form-group row">
            <label
              className="control-label col-sm-3 align-self-center mb-0 text-align-right"
              htmlFor="keyword"
            >
              Keyword
            </label>
            <div className="col-sm-6 mb-2">
              <input
                type="text"
                name="keyword"
                id="keyword"
                required={true}
                maxLength={75}
                className="form-control"
                placeholder="Enter keyword (the main topic of your article)"
                value={keyword}
                onChange={keywordChangeHandler}
              />
              <span
                style={{
                  color: "red",
                  display: keywordWarning.show ? "" : "none",
                }}
              >
                {keywordWarning.msg}
              </span>
            </div>
            <div className="col-sm-3">
              {/* also update map_language_param in article controller */}
              <select
                name="language"
                id="language"
                className="form-control"
                value={language}
                onChange={languageChangeHandler}
              >
                <option value="en">English</option>
                <option value="nl">Dutch</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="it">Italian</option>
                <option value="pt">Portuguese</option>
                <option value="es">Spanish</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label
              className="control-label col-sm-3 align-self-center mb-0 text-align-right"
              htmlFor="instructions"
            >
              Instructions
            </label>
            <div className="col-sm-6 mb-2">
              <textarea
                name="instructions"
                id="instructions"
                className="form-control"
                style={{ minHeight: 120, lineHeight: "normal" }}
                placeholder="Tell Article Forge what you want to write about (optional) e.g: Write an article
                  about the best beaches in Florida including St. Pete beach, Miami beach, Jax beach, and James Lee Beach"
                value={instructions}
                onChange={instructionsChangeHandler}
              ></textarea>
              <span
                className="help-block"
                id="instructions_help_block"
                style={{ display: instructions.length === 0 ? "none" : "" }}
              >
                Tell Article Forge what you want to write about (optional) e.g:
                Write an article about the best beaches in Florida including St.
                Pete beach, Miami beach, Jax beach, and James Lee Beach
                <br />
                <span
                  style={{
                    color: "red",
                    display: instructionsWarning.show ? "" : "none",
                  }}
                >
                  {instructionsWarning.msg}
                </span>
              </span>
            </div>
          </div>
          <div
            className="text-center"
            style={{ marginTop: 10, fontSize: 13 }}
            id="bulk_switcher"
          >
            <a
              className={`fake-link ${allowBulk ? "" : "inactive-link"}`}
              onClick={switchToBulkSpinnerHandler}
            >
              Switch to Bulk Article Generator
            </a>
          </div>
        </div>

        <div id="bulk-spin" hidden={true}>
          <div className="text-center" style={{ marginBottom: 20 }}>
            <h4>Bulk Article Generator</h4>
          </div>
          <div className="form-group row">
            <div className="col-md-3">
              <label
                className="control-label align-self-center mb-0 text-align-right"
                htmlFor="bulk_keywords"
                style={{ display: "block" }}
              >
                Keywords
              </label>
            </div>
            <div className="col-md-9 align-self-center mb-0">
              <textarea
                name="bulk_keywords"
                id="bulk_keywords"
                className="form-control"
                style={{ minHeight: 120, lineHeight: "normal" }}
                placeholder={bulkPlaceholderAndHint["placeholder"]}
                disabled={props.isTrialUser}
                value={bulkKeywords}
                onChange={bulkKeywordsChangeHandler}
              ></textarea>
            </div>
          </div>
          <div className="text-center" style={{ marginTop: 20 }}>
            <span style={{ fontSize: 13 }}>
              <strong>
                For the bulk article generator, each line will be a separate
                article.
              </strong>
            </span>
            <br />
            <span
              className="help-block"
              id="bulk_help_block"
              style={{ display: "block", fontSize: 13 }}
            >
              {bulkPlaceholderAndHint["hint"]}
            </span>
            {showSubKwdWarning && (
              <span
                className="help-block"
                id="bulk_help_block"
                style={{ display: "block", fontSize: 13, color: "red" }}
              >
                Article Forge no longer accepts sub keywords. All text after the
                first comma on each line will be ignored.
              </span>
            )}
            <div className="text-center" style={{ marginTop: 20 }}>
              <a className="fake-link" onClick={switchToRegularSpinnerHandler}>
                Switch to Regular Article Generator
              </a>
            </div>
          </div>
        </div>

        <input
          name="is_bulk_spin"
          id="is_bulk_spin"
          value={isBulk.toString()}
          hidden={true}
          readOnly={true}
        />
      </div>
    </div>
  );
};

export default ArticleKeywordForm;
