import React from "react";
import styles from "./PlanPanel.module.scss";

type intervalCount = 1 | 12;

type Plan = {
  id: number;
  name: string;
  amount: number;
  unlimited: boolean;
  api_limit: number;
  sub_account_count: number;
  interval_count: intervalCount;
};

type PricePanelProps = {
  tab: string;
  plan: Plan;
};

const PricePanel = ({ tab, plan }: PricePanelProps) => {
  const onUpdate = () => {
    window.location.href = `/upgrade_plan/${plan.id}`;
  };

  return (
    <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12">
      <div className="price-table mt-3 pb-3">
        <div className="price-inside">{plan.name.split(" ")[0]}</div>
        <div className="price-header mb-3">
          <div className="price-value">
            <h2>
              <span>$</span>
              {tab === "monthly" ? plan.amount / 100 : plan.amount / 1200}
            </h2>
            <small>/mo</small>
            <span>
              <small>
                {tab === "monthly" ? "Billed monthly" : "Billed annually"}
              </small>
            </span>
          </div>
          <h3 className="price-title">
            {(plan.name.split(" ")[0] || "").toUpperCase()}
          </h3>
          <p className="mt-2" style={{ marginBottom: "18px" }}>
            <strong>{plan.api_limit.toLocaleString("en-US")}</strong> words/mo &{" "}
            <strong>{plan.sub_account_count + 1}</strong> Users
          </p>
          <button
            className="btn btn-block btn-primary btn-action btn-current-plan"
            onClick={onUpdate}
          >
            Upgrade
          </button>
        </div>
        <div className={`price-list ${styles.priceList}`}>
          <ul className={`list-unstyled ${styles.listUnstyled}`}>
            <li>AI-powered writer</li>
            <li>Generate 1,500+ word articles</li>
            <li>Content passes Copyscape</li>
            <li>Automatically posts to blogs</li>
            <li>Bulk article generation</li>
            <li>API access</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricePanel;
