import React from "react";
import WordUsageRow from "./WordUsageRow";
import Panel from "../../Common/UI/Panel";
import styles from "./WordsUsedPanel.module.scss";

type WordsUsedPanelProps = {
  isTrialUser: boolean;
  totalInterfaceWords: number;
  totalAutoPilotWords: number;
  prepaidWordsAvailable: number;
  planWordLimit: number;
  unpaidOverageWords: number;
  resetDate: string;
  onLimitPlan: boolean;
};

const WordsUsedPanel = ({
  isTrialUser,
  totalInterfaceWords,
  totalAutoPilotWords,
  prepaidWordsAvailable,
  planWordLimit,
  unpaidOverageWords,
  resetDate,
  onLimitPlan,
}: WordsUsedPanelProps) => {
  return (
    <Panel
      title={
        <>
          Words Used
          <small style={{ fontSize: "12px" }}>
            {" "}
            Your usage will reset on{" "}
            {resetDate ? new Date(resetDate).toLocaleDateString() : "N/A"}
          </small>
        </>
      }
    >
      <div className={styles.dataTablesWrapper}>
        <table className={`table ${styles.usageTable}`}>
          <tbody>
            {onLimitPlan || isTrialUser ? (
              <WordUsageRow
                label="Total Words"
                tooltip="This is the total number of words created during your current usage period. This includes all words created from the Article Forge interface as well as all words created using an Auto-Pilot feature. Auto-Pilot features include the Bulk Generator, Post Scheduler, and API."
              >
                {(totalInterfaceWords + totalAutoPilotWords).toLocaleString()} /{" "}
                {planWordLimit.toLocaleString()} words used
              </WordUsageRow>
            ) : (
              <>
                <WordUsageRow
                  label="Total Interface Words"
                  tooltip="This is the total number of words created in the Article Forge interface during your current usage period."
                >
                  {totalInterfaceWords.toLocaleString()} / &#8734; words used
                </WordUsageRow>
                <WordUsageRow
                  label="Total Auto-Pilot Words"
                  tooltip="This is the total number of Auto-Pilot words created during your current usage period. Auto-Pilot features include the Bulk Generator, Post Scheduler, and API."
                >
                  {totalAutoPilotWords.toLocaleString()} /{" "}
                  {planWordLimit.toLocaleString()} words used
                </WordUsageRow>
              </>
            )}
            {prepaidWordsAvailable < unpaidOverageWords ? (
              <WordUsageRow
                label="Unpaid Overage Words"
                tooltip="This is the total number of additional words created this usage period that are currently unpaid."
              >
                {(unpaidOverageWords - prepaidWordsAvailable).toLocaleString()}{" "}
                words
              </WordUsageRow>
            ) : (
              prepaidWordsAvailable > 0 && (
                <WordUsageRow label="Prepaid Words Available">
                  {(
                    prepaidWordsAvailable - unpaidOverageWords
                  ).toLocaleString()}{" "}
                  words
                </WordUsageRow>
              )
            )}
          </tbody>
        </table>
      </div>
    </Panel>
  );
};

export default WordsUsedPanel;
