import * as Decoder from "io-ts/Decoder";
import { decode } from "Common/Decoders";
import axios from "axios";

export const DataPoint = Decoder.struct({
  value: Decoder.number,
  label: Decoder.string,
});
export type DataPoint = Decoder.TypeOf<typeof DataPoint>;

export const DailyMrr = Decoder.struct({
  reactivation: Decoder.number,
  expansion: Decoder.number,
  new: Decoder.number,
  contraction: Decoder.number,
  churn: Decoder.number,
  recurring: Decoder.number,
  length_change: Decoder.number,
});
export type DailyMrr = Decoder.TypeOf<typeof DailyMrr>;

export const MrrMetrics = Decoder.struct({
  xAxis: Decoder.array(Decoder.string),
  values: Decoder.array(DailyMrr),
  currentMonth: DataPoint,
  lastMonth: DataPoint,
  twoMonth: DataPoint,
  threeMonth: DataPoint,
  oneYear: DataPoint,
});

export type MrrMetrics = Decoder.TypeOf<typeof MrrMetrics>;

export const getMrrMetrics = (
  startDate: string,
  endDate: string,
  metricsPeriod: string
): Promise<MrrMetrics> =>
  axios
    .post("/internal_api/admin/metrics/mrr", {
      start_date: startDate,
      end_date: endDate,
      metrics_period: metricsPeriod,
    })
    .then(({ data }) => decode(MrrMetrics, data));
