import { useCallback, useState } from "react";

function useModalState() {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return [open, handleOpen, handleClose] as const;
}

export default useModalState;
