import React, { useState } from "react";
import axios from "axios";
import ApiKeyPanelBody from "./ApiKeyPanelBody";
import RegenerateKeyModal from "./RegenerateKeyModal";
import Panel from "../../Common/UI/Panel";
import { useAddNotification } from "../../Common/UI/ToastContext";

type ApiKeyPanelProps = {
  apiKey: string;
  allowRegenerate: boolean;
  allowDownload: boolean;
};

const ApiKeyPanel = ({
  apiKey,
  allowRegenerate,
  allowDownload,
}: ApiKeyPanelProps) => {
  const [key, setKey] = useState(apiKey);
  const [isRegenerateKeyModalOpen, setIsRegenerateKeyModalOpen] =
    useState(false);

  const addNotification = useAddNotification();

  const handleRegenerateKeyModal = () => {
    setIsRegenerateKeyModalOpen(!isRegenerateKeyModalOpen);
  };

  const handleUpdateKey = () => {
    handleRegenerateKeyModal();
    axios
      .post("/regenerate_api_key")
      .then((res) => {
        if (res) {
          setKey(res.data);
          addNotification({
            type: "success",
            message: "Your API key has been reset.",
          });
        } else
          addNotification({
            type: "danger",
            message:
              "Failed to reset your API key. Please try again or contact our support team.",
          });
      })
      .catch((data) => {
        if (data.responseJSON.err_msg)
          addNotification({
            type: "danger",
            message: data.responseJSON.err_msg,
          });
      });
  };

  return (
    <Panel title="API Info">
      <ApiKeyPanelBody
        apiKey={key}
        allowDownload={allowDownload}
        allowRegenerate={allowRegenerate}
        onRegenerateKeyModal={handleRegenerateKeyModal}
      />
      {isRegenerateKeyModalOpen && (
        <RegenerateKeyModal
          onClose={handleRegenerateKeyModal}
          onUpdate={handleUpdateKey}
        />
      )}
    </Panel>
  );
};

export default ApiKeyPanel;
