import React, { ChangeEvent, useState } from "react";
import $ from "jquery";
import WordAiLogo from "../../../assets/images/word-ai-logo.png";
import Modal from "../../Common/UI/Modal";
import ModalBody from "../../Common/UI/ModalBody";
import ModalFooter from "../../Common/UI/ModalFooter";
import ModalHeader from "Common/UI/ModalHeader";

type WordAiModalProps = {
  onClose: () => void;
  onWordAiLogin: () => void;
  articleId: number;
  wordAiValid: boolean;
  onSuccess: (text: string) => Promise<void>;
};

type WordAiLoginResponse = {
  status: string;
};

type ProgressData = {
  status: boolean;
  finished: boolean;
  text: string;
  progress: string;
  error_message: string;
};

const WordAiModal = (props: WordAiModalProps) => {
  const [rewriteValue, setRewriteValue] = useState("1");
  const [showRewriteError, setShowRewriteError] = useState(false);
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [buttonText, setButtonText] = useState("Spin");
  const checkRewriteValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.target.value) > 10) {
      setRewriteValue("10");
    } else {
      setRewriteValue(e.target.value);
    }
  };

  const onWordAiLogin = () => {
    window.connect_wordai().done(function (data: WordAiLoginResponse) {
      if (data.status === "Success") {
        setTimeout(props.onWordAiLogin, 2000);
      }
    });
  };

  const onWordAiSpin = () => {
    spinWithWordAi(props.articleId);
  };

  const spinWithWordAi = (id: number) => {
    const rewriteNum = parseInt(rewriteValue);
    if (rewriteNum < 1 || rewriteNum > 10 || isNaN(rewriteNum)) {
      setShowRewriteError(true);
      return;
    }
    // Remove spin btn on modal
    $("#spin_wordai_button").hide();
    // Validate wordai account and initiate wordai job
    const uniqueness = $("#select_quality").val();
    $.ajax({
      type: "POST",
      url: "/initiate_wordai_job/",
      data: {
        rewrite_num: rewriteNum,
        uniqueness: uniqueness,
        article_id: id,
      },
      complete: function (data) {
        if (data.responseJSON.status) {
          // Start progressbar
          $("#wordai-progress-bar").width("0%");
          $("#wordai-progress-error").html("");
          $("#wordai_modal_close_btn").hide();
          $("#wordai_modal_body_div").hide();
          $("#wordai_error_div").hide();
          $("#wordai_progress_div").show();
          $("#wai_note_save").hide();
          const itv = setInterval(function () {
            $.ajax({
              type: "POST",
              url: "/fetch_wordai_progress/",
              data: {
                job_key: data.responseJSON.job_key,
              },
              complete: function (data) {
                const progressData = data.responseJSON;
                const wordai_modal_btn = $("#spin_wordai_button");
                if (progressData.status) {
                  if (progressData.finished) {
                    clearInterval(itv);
                    $("#wordai-progress-bar").width("100%");
                    $("#wordai-progress-info").html(
                      '<p style="color: green; font-weight: bold;">Article Successfully Spun. Updating Article settings...</p>'
                    );
                    //update article settings
                    $.ajax({
                      type: "POST",
                      url: "/update_article_settings/",
                      data: {
                        new_spintax: progressData.text,
                        rewrite_num: rewriteNum,
                        uniqueness: uniqueness,
                        article_id: id,
                      },
                      complete: function () {
                        wordai_modal_btn.show();
                        wordai_modal_btn.html("Update");
                        wordai_modal_btn.on("click", () =>
                          wordaiSpinSuccessful(progressData)
                        );
                        //remove spin with wordai button
                        $("#spin_wordai_btn").hide();
                        $("#wordai-progress-info").html(
                          '<p style="color: green; font-weight: bold;">Article Successfully Spun. Click Update to see the changes.</p>'
                        );
                      },
                    });
                  } else {
                    $("#wordai-progress-bar").width(
                      (Number(progressData.progress) * 100).toString() + "%"
                    );
                  }
                } else {
                  clearInterval(itv);
                  // WordAi Error
                  $("#wordai-progress-bar").width("100%");
                  $("#wordai-progress-info").html("");
                  $("#wordai-progress-error").html(
                    '<p style="color: red; font-weight: bold;">' +
                      progressData.error_message +
                      "</p>"
                  );
                  setButtonText("Close");
                  setIsButtonToggle(true);
                }
              },
            });
          }, 2500);
        } else {
          // WordAi Auth Error on Article Forge
          setButtonText("Dismiss");
          setIsButtonToggle(true);
          let errorMsg =
            "Your WordAi Authentication failed. Please check your WordAi credentials on Article Forge or check if your WordAi account is still valid.";
          if (data.responseJSON.error_message) {
            errorMsg = data.responseJSON.error_message;
          }
          $("#wordai_error_div").html(
            '<span style="color:red; font-weight: bold;">' +
              errorMsg +
              "</span>"
          );
          $("#wordai_error_div").show();
          $("#wordai_modal_body_div").hide();
          $("#wordai_progress_div").hide();
        }
      },
    });
  };

  const wordaiSpinSuccessful = (progressData: ProgressData) => {
    props.onSuccess(progressData.text).then(() => {
      window.add_flash_alert_global(
        "success",
        "The article has been successfully spun by WordAi and is updated."
      );
      props.onClose();
    });
  };

  if (props.wordAiValid) {
    return (
      <Modal onClose={props.onClose}>
        <ModalHeader
          title={
            <>
              Rewriting Powered by{" "}
              <img
                src={WordAiLogo}
                alt="WordAi"
                style={{ display: "inline", height: "20px" }}
              />
            </>
          }
          onClose={props.onClose}
        >
          <p id="wai_note_save" style={{ color: "red" }}>
            <strong>Note:</strong> If you have edited your article manually,
            please save the article, otherwise WordAi rewrite will be based on
            the old article.
          </p>
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid" id="wordai_modal_body_div">
            <div
              className="row panel panel-default inner-card"
              id="wordai_powered"
              style={{ display: "block" }}
            >
              <div className="iq-card full-width">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">WordAi Settings</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="row form-group" style={{ display: "none" }}>
                    <label
                      htmlFor="switch_turing"
                      className="control-label col-xs-6 col-lg-4 col-lg-offset-2"
                    >
                      Turing Spinner
                    </label>
                    <div className="col-xs-6 col-lg-4">
                      <input
                        id="switch_turing"
                        defaultChecked={true}
                        className="bs_switch"
                        type="checkbox"
                      ></input>
                    </div>
                  </div>

                  <div className="row form-group">
                    <label
                      htmlFor="select_quality"
                      className="control-label offset-md-2 col-sm-4 mb-0 align-self-center pr-50"
                    >
                      Quality
                    </label>
                    <select
                      id="select_quality"
                      className="form-control col-sm-4"
                    >
                      <option value={1}>Readable</option>
                      <option value={2}>Regular</option>
                      <option value={3}>Unique</option>
                    </select>
                  </div>

                  <div className="row form-group">
                    <div className="offset-md-2 col-sm-4 pr-50">
                      <label
                        htmlFor="rewrite_num"
                        className="control-label mb-0 align-self-center"
                      >
                        Number of Rewrites
                      </label>
                      <span
                        className="help-block"
                        id="rewrite_number_help_block"
                        style={showRewriteError ? { color: "red" } : {}}
                      >
                        Integer between 1 to 10
                      </span>
                    </div>
                    <input
                      id="rewrite_num"
                      className="form-control col-sm-4"
                      type="number"
                      min="1"
                      max="10"
                      step="1"
                      onInput={checkRewriteValue}
                      value={rewriteValue}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="panel-body"
            id="wordai_progress_div"
            style={{ display: "none" }}
          >
            <div className="progress progress-striped active">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                id="wordai-progress-bar"
                role="progressbar"
                style={{ width: "0%" }}
              ></div>
            </div>
            <div id="wordai-progress-info" className="progress_info help-block">
              <span style={{ color: "green" }}>
                Your WordAi authentication was successful. Rewriting your
                article...
              </span>
              <br />
              <p style={{ color: "red", fontWeight: "bold" }}>
                NOTE: Do not close the browser tab now, if you do all your
                rewriting progress will be lost and you'll be charged on WordAi.
              </p>
            </div>
            <div id="wordai-progress-error"></div>
          </div>
          <div
            className="modal-body"
            id="wordai_error_div"
            style={{ display: "none" }}
          ></div>
        </ModalBody>
        <ModalFooter>
          <button
            id="spin_wordai_button"
            type="button"
            className="btn btn-primary pull-right"
            onClick={isButtonToggle ? props.onClose : onWordAiSpin}
          >
            {buttonText}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal onClose={props.onClose} addMargin>
      <ModalHeader
        title="Connect your WordAi account to create up to 10 unique versions of this article"
        onClose={props.onClose}
      />
      <ModalBody>
        <div
          className="alert text-white bg-success"
          id="connect_success"
          style={{ display: "none" }}
        >
          Successfully connected with WordAi!
        </div>
        <div
          className="alert text-white bg-danger"
          id="connect_failure"
          style={{ display: "none" }}
        >
          Connect request failed, wrong email/api key.
        </div>

        <p>
          WordAi is an AI powered rewriter. Enter a piece of text and WordAi
          will intelligently rewrite it.
          <br />
          By integrating WordAi, each article you create in Article Forge will
          get turned into 10 high quality, unique variations.
        </p>
        <div className="row form-group">
          <label
            htmlFor="wordai_email"
            className="control-label offset-md-2 col-sm-4 mb-0 align-self-center text-align-right pr-50"
          >
            WordAi Email
          </label>
          <input id="wordai_email" className="form-control col-sm-4"></input>
        </div>

        <div className="row form-group">
          <label
            htmlFor="wordai_password"
            className="control-label offset-md-2 col-sm-4 mb-0 align-self-center text-align-right pr-50"
          >
            WordAi API Key
          </label>
          <input
            id="wordai_password"
            className="form-control col-sm-4"
            autoComplete="off"
          ></input>
        </div>

        <div className="text-center">
          <button
            id="wordai_connect_btn"
            className="btn btn-primary text-center"
            onClick={onWordAiLogin}
          >
            Connect to Your WordAi Account
          </button>
        </div>
        <div
          className="text-center"
          style={{ marginTop: "10px", fontSize: "13px" }}
        >
          <a href="https://wordai.com/">
            Don't have a WordAi account? Click here to create one.
          </a>
        </div>
      </ModalBody>
      <div />
    </Modal>
  );
};

export default WordAiModal;
