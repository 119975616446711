import React, { memo } from "react";
import styles from "./RewriteProcessing.module.scss";

function RewriteProcessing() {
  return (
    <div className={styles.root}>
      <i className="fa fa-spinner fa-spin fa-2x" />
    </div>
  );
}

export default memo(RewriteProcessing);
