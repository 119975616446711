import React, { ChangeEvent, useState, useCallback } from "react";
import Modal from "Common/UI/Modal";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";
import ModalHeader from "Common/UI/ModalHeader";
import clsx from "clsx";
import axios from "axios";
import { downloadFile } from "Common/BrowserUtil";
import { Article } from "Article/ArticleApi";

export type BatchExportType = "txt" | "html";

type ExportPromptModalProps = Readonly<{
  article: Article;
  onClose: () => void;
  type: BatchExportType;
}>;

const BatchExportModal = ({
  article,
  onClose,
  type,
}: ExportPromptModalProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [countValue, setCountValue] = useState("");
  const [structure, setStructure] = useState<"separate" | "single">("separate");
  const [separator, setSeparator] = useState("");
  const [error, setError] = useState("");

  const handleCountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const intValue = parseInt(value);
      setCountValue(value);
      if (intValue.toString() !== value || intValue < 1 || intValue > 5000) {
        setError("Please enter a number between 1 and 5000.");
      }
    },
    []
  );

  const handleSeparate = (e: ChangeEvent<HTMLInputElement>) => {
    setStructure(e.target.checked ? "separate" : "single");
  };

  const handleSingle = (e: ChangeEvent<HTMLInputElement>) => {
    setStructure(e.target.checked ? "single" : "separate");
  };

  const handleChangeSeparator = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeparator(e.target.value);
  };

  const handleSubmit = useCallback(() => {
    setIsProcessing(true);
    axios
      .post(
        "/export_batch_spins",
        new URLSearchParams({
          separator,
          fileOptions: structure,
          count: countValue,
          article_id: article.id.toString(),
          type,
        }),
        { responseType: "blob" }
      )
      .then(({ data }) => {
        downloadFile(data, `${article.title}.zip`);
        onClose();
      });
  }, [
    article.id,
    article.title,
    countValue,
    onClose,
    separator,
    structure,
    type,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal onClose={onClose}>
        <ModalHeader title="Export Article" onClose={onClose} />
        <ModalBody>
          <div className={clsx("form-group", { "has-error": !!error })}>
            <label className="control-label" htmlFor="batch-export-count">
              How many articles do you want? (MAX: 5000)
            </label>
            <input
              id="batch-export-count"
              className="form-control"
              autoFocus
              disabled={isProcessing}
              onChange={handleCountChange}
              value={countValue}
            />
            {error && <span className="help-block">{error}</span>}
          </div>
          <div className="form-group">
            <label className="form-label">File Structure</label>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  disabled={isProcessing}
                  checked={structure === "separate"}
                  onChange={handleSeparate}
                />{" "}
                All rewrites in their own file
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  disabled={isProcessing}
                  checked={structure === "single"}
                  onChange={handleSingle}
                />{" "}
                All rewrites in single file with a custom separator
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="batch-export-separator">
              Custom Separator
            </label>
            <input
              type="text"
              id="batch-export-separator"
              className="form-control"
              value={separator}
              onChange={handleChangeSeparator}
              disabled={isProcessing || structure !== "single"}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            disabled={isProcessing}
            className="btn text-center"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            disabled={isProcessing || !!error}
            type="submit"
            className="btn btn-primary text-center"
          >
            OK
          </button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

export default BatchExportModal;
