import React from "react";
import { ToastNotification } from "./ToastContext";
import styles from "./ToastNotificationCard.module.scss";

type ToastNotificationCardProps = {
  notification: ToastNotification;
  onClose: () => void;
};

const ToastNotificationCard = ({
  notification,
  onClose,
}: ToastNotificationCardProps) => {
  return (
    <li className={`alert-${notification.type} ${styles.notificationCard}`}>
      <div className={styles.notificationBody}>
        <span className={styles.notificationText}>{notification.message}</span>
        <button className={`close ${styles.closeButton}`} onClick={onClose}>
          &times;
        </button>
      </div>
    </li>
  );
};

export default ToastNotificationCard;
