import React, { ReactNode } from "react";

type ModalFooterProps = {
  children: ReactNode;
};

const ModalFooter = (props: ModalFooterProps) => {
  return <div className="modal-footer">{props.children}</div>;
};

export default ModalFooter;
