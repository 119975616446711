import React from "react";
import ApiKeyPanel from "./ApiKeyPanel";
import UsageLimitPanel from "../../Usage/components/UsageLimitPanel";
import Page from "../../Common/UI/Page";

type ApiInfoPageProps = {
  apiKey: string;
  allowRegenerateKey: boolean;
  allowApiDownload: boolean;
  allowToggleOverage: boolean;
  isTrialUser: boolean;
  planWordLimit: number;
  isOveruseAllowed: boolean;
  extraCostPerUnit: number;
  extraUnitWords: number;
  onOveragePlan: boolean;
  hasOverdueBalance: boolean;
  overdueOverageAmount: number;
};

const ApiInfoPage = ({
  apiKey,
  allowRegenerateKey,
  allowApiDownload,
  allowToggleOverage,
  isTrialUser,
  planWordLimit,
  isOveruseAllowed,
  extraCostPerUnit,
  extraUnitWords,
  onOveragePlan,
  hasOverdueBalance,
  overdueOverageAmount,
}: ApiInfoPageProps) => {
  return (
    <Page>
      <div className="row">
        <div
          className={
            allowToggleOverage ? "col-sm-12 col-lg-6" : "col-sm-12 col-lg-12"
          }
        >
          <ApiKeyPanel
            apiKey={apiKey}
            allowRegenerate={allowRegenerateKey}
            allowDownload={allowApiDownload}
          />
        </div>
        {allowToggleOverage && (
          <div
            className="col-sm-12 col-lg-6"
            style={{ display: "inline-block" }}
          >
            <UsageLimitPanel
              isTrialUser={isTrialUser}
              wordLimit={planWordLimit}
              isOveruseAllowed={isOveruseAllowed}
              extraCostPerUnit={extraCostPerUnit}
              extraUnitWords={extraUnitWords}
              onOveragePlan={onOveragePlan}
              hasOverdueBalance={hasOverdueBalance}
              overdueOverageAmount={overdueOverageAmount}
              redirectFrom="api"
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default ApiInfoPage;
