import { createContext, useCallback, useContext } from "react";

export type BaseToastNotification = {
  type: "danger" | "success" | "warning";
  message: string;
  autoCloseMs?: number;
};

export type ToastNotification = BaseToastNotification & {
  autoCloseMs: number;
};

export type ToastState = {
  notifications: ToastNotification[];
};

export type ToastContextValue = {
  handleAddNotification: (notification: BaseToastNotification) => void;
};

export const ToastContext = createContext<ToastContextValue | null>(null);

export const useAddNotification = () => {
  const toast = useContext(ToastContext);
  return useCallback(
    (notification: BaseToastNotification) => {
      toast?.handleAddNotification(notification);
    },
    [toast]
  );
};
