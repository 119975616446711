import * as Decoder from "io-ts/Decoder";
import { DateDecoder, decode } from "Common/Decoders";
import axios from "axios";

export const Article = Decoder.struct({
  id: Decoder.number,
  title: Decoder.nullable(Decoder.string),
  spintax: Decoder.nullable(Decoder.string),
  user: Decoder.nullable(
    Decoder.struct({
      id: Decoder.number,
      firstName: Decoder.nullable(Decoder.string),
      email: Decoder.nullable(Decoder.string),
    })
  ),
  keyword: Decoder.nullable(Decoder.string),
  subKeywords: Decoder.nullable(Decoder.string),
  quality: Decoder.nullable(Decoder.string),
  backendLog: Decoder.nullable(Decoder.string),
  suggestion: Decoder.nullable(Decoder.string),
  createdAt: DateDecoder,
  updatedAt: DateDecoder,
  maxWpPosts: Decoder.nullable(Decoder.number),
  hasTitleInBody: Decoder.boolean,
  showWordaiSpin: Decoder.nullable(Decoder.boolean),
});

export type Article = Decoder.TypeOf<typeof Article>;

type UpdateArticlePayload = Readonly<{ title: string } | { spintax: string }>;

export default {
  getOne(id: number): Promise<Article> {
    return axios
      .get(`/internal_api/articles/${id}`)
      .then(({ data }) => decode(Article, data));
  },
  update(id: number, payload: UpdateArticlePayload): Promise<Article> {
    return axios
      .patch(`/internal_api/articles/${id}`, payload)
      .then(({ data }) => decode(Article, data));
  },
};
