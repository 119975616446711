import React from "react";
import { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";

type SelectedValue = {
  value: string;
  label: string;
};

type SpintaxCategoryTagInputProps = {
  id: string;
  categories: string[];
  tags: string[];
  title: string;
  handleTermsChange: (
    newValue: MultiValue<SelectedValue>,
    id: string,
    type: "categories" | "tags"
  ) => void;
  categoriesSelected: MultiValue<SelectedValue> | undefined;
  tagsSelected: MultiValue<SelectedValue> | undefined;
};

const SpintaxCategoryTagInput = (props: SpintaxCategoryTagInputProps) => {
  const handleCategoryChange = (categories: MultiValue<SelectedValue>) => {
    props.handleTermsChange(categories, props.id, "categories");
  };

  const handleTagChange = (tags: MultiValue<SelectedValue>) => {
    props.handleTermsChange(tags, props.id, "tags");
  };

  return (
    <div className="single-wp-term">
      Taxonomies for blog:{" "}
      <strong>
        <a href={`${props.title}`}>{props.title}</a>
      </strong>
      <div>
        <label className="control-label" style={{ display: "inline-block" }}>
          WordPress Categories
        </label>
        <CreatableSelect
          id="spin_blog_categories"
          name="categories"
          classNamePrefix="react-select"
          maxMenuHeight={150}
          isMulti
          placeholder=""
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          options={props.categories.map((category) => {
            return {
              value: category,
              label: category,
            };
          })}
          value={props.categoriesSelected}
          onChange={handleCategoryChange}
        />
        <label className="control-label" style={{ display: "inline-block" }}>
          WordPress Tags
        </label>
        <CreatableSelect
          id="spin_blog_tags"
          name="tags"
          classNamePrefix="react-select"
          maxMenuHeight={150}
          isMulti
          placeholder=""
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          options={props.tags.map((tag) => {
            return { value: tag, label: tag };
          })}
          value={props.tagsSelected}
          onChange={handleTagChange}
        />
      </div>
    </div>
  );
};

export default SpintaxCategoryTagInput;
