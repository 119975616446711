import React, { useMemo } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import { FroalaOptions } from "froala-editor";

type EditorViewProps = Readonly<{
  html: string;
  onCodeViewToggled: (isCodeViewActive: boolean) => void;
}>;

function ReadonlyEditor({ html, onCodeViewToggled }: EditorViewProps) {
  const config = useMemo<Partial<FroalaOptions>>(
    () => ({
      key: "WE1B5dH5H3B2A8A8D7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A4F3E3F3F2A5B4==",
      attribution: false,
      toolbarButtons: {
        moreMisc: {
          buttons: ["html"],
        },
      },
      htmlUntouched: false,
      events: {
        initialized: function () {
          this.$el[0]?.setAttribute("contenteditable", "false");
        },
        "commands.before": function (cmd) {
          if (cmd === "html") {
            onCodeViewToggled(!this.codeView?.isActive());
          }
        },
        "edit.on": function () {
          if (!this.codeView?.isActive()) {
            this.$el[0]?.setAttribute("contenteditable", "false");
          } else {
            document
              .getElementsByClassName("fr-code")[0]
              ?.setAttribute("readonly", "true");
          }
        },
      },
    }),
    [onCodeViewToggled]
  );

  return (
    <FroalaEditor
      model={html}
      tag="textarea"
      config={config}
      key="WE1B5dH5H3B2A8A8D7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A4F3E3F3F2A5B4=="
    />
  );
}

export default ReadonlyEditor;
