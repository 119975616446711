import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight, FaCheck } from "react-icons/fa";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { ImMagicWand, ImCross } from "react-icons/im";
import styles from "./ActionMenuBar.module.scss";
import { RewriteState } from "Article/useArticleEditor";
import RewriteActionButton from "Article/components/rewrite/RewriteActionButton";
import IconButton from "Article/components/rewrite/IconButton";
import Spacer from "Common/UI/Spacer";

type ActionMenuBarProps = Readonly<{
  rewriteState: RewriteState;
  suggestionLength: number;
  onRewrite: () => void;
  onCancel: () => void;
  onPrevSuggestion: () => void;
  onNextSuggestion: () => void;
  onAccept: () => void;
}>;

const ActionMenuBar = ({
  rewriteState,
  suggestionLength,
  onRewrite,
  onCancel,
  onPrevSuggestion,
  onNextSuggestion,
  onAccept,
}: ActionMenuBarProps) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const onMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // TODO: remove when rewrite is up
  const showRewrite =
    new URLSearchParams(document.location.search).get("rewrite") === "1";

  return (
    <div
      className={
        isMinimized
          ? `${styles.actionMenuBox} ${styles.elevateMagicIcon}`
          : styles.actionMenuBox
      }
    >
      <div
        className={
          showRewrite ? styles.rewritePanel : styles.panelWithoutRewrite
        }
        style={isMinimized ? { display: "none" } : {}}
        title={
          rewriteState.status === "Editing" && rewriteState.codeView
            ? "Rewrite and Compose functionality are not available in code view."
            : undefined
        }
      >
        <div className={styles.rewritePanelTitle}>
          <button
            onClick={onMinimize}
            className={`btn ${styles.minimizeButton}`}
          >
            <MdOutlineHorizontalRule />
          </button>
          {rewriteState.status === "Selecting" && (
            <div className={styles.buttons}>
              {/*TODO: this is too ugly right now to launch*/}
              {/*rewriteState.showTooltip && (*/}
              {/*  <span className={styles.rewriteTooltip}>*/}
              {/*    You must accept or reject the suggestion before continuing to*/}
              {/*    edit your article.*/}
              {/*  </span>*/}
              {/*)*/}
              <b style={{ color: "black" }}>
                {`${
                  rewriteState.selectedIndex !== undefined &&
                  rewriteState.selectedIndex + 1
                }`}{" "}
                of {suggestionLength}
              </b>
              <Spacer width={15} />
              <IconButton
                disabled={rewriteState.selectedIndex === 0}
                onClick={onPrevSuggestion}
                title="Previous (CTRL + ,)"
              >
                <FaArrowLeft size={20} />
              </IconButton>
              <Spacer width={15} />
              <IconButton
                disabled={rewriteState.selectedIndex === suggestionLength - 1}
                onClick={onNextSuggestion}
                title="Next (CTRL + .)"
              >
                <FaArrowRight size={20} />
              </IconButton>
              <Spacer width={15} />
              <IconButton onClick={onCancel} title="Reject (CTRL + /)">
                <ImCross size={15} className={styles.crossIcon} />
              </IconButton>
              <Spacer width={15} />
              <IconButton onClick={onAccept} title="Accept (CTRL + Enter)">
                <FaCheck size={20} className={styles.checkIcon} />
              </IconButton>
            </div>
          )}
          {rewriteState.status === "Selecting" && (
            <div style={{ width: "40px" }}></div>
          )}
        </div>
        {rewriteState.status !== "Editing" ? (
          <div className={showRewrite ? styles.rewritePanelBody : undefined}>
            {showRewrite && (
              <RewriteActionButton disabled showRewrite={showRewrite}>
                Rewrite
              </RewriteActionButton>
            )}
            <RewriteActionButton disabled showRewrite={showRewrite}>
              Compose
            </RewriteActionButton>
          </div>
        ) : (
          <div className={showRewrite ? styles.rewritePanelBody : undefined}>
            {showRewrite && (
              <RewriteActionButton
                onClick={onRewrite}
                showRewrite={showRewrite}
                {...rewriteState.actions.rewrite}
              >
                Rewrite
              </RewriteActionButton>
            )}
            <RewriteActionButton
              showRewrite={showRewrite}
              onClick={onRewrite}
              {...rewriteState.actions.compose}
            >
              Compose
            </RewriteActionButton>
          </div>
        )}
      </div>
      {rewriteState.status === "Selecting" && (
        <hr style={{ marginBottom: "0", marginTop: "0" }} />
      )}
      {isMinimized && (
        <div className={styles.rewritePanelTitle}>
          <button
            type="button"
            id="minimized-rewrite-button"
            className={styles.magicIcon}
            onClick={onMinimize}
          >
            <ImMagicWand size={15} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionMenuBar;
