import React, { ReactNode } from "react";

type ModalBodyProps = {
  children: ReactNode;
};

const ModalBody = (props: ModalBodyProps) => {
  return <div className="modal-body">{props.children}</div>;
};

export default ModalBody;
