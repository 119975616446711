import { Tooltip } from "@mui/material";
import React, { useState, ChangeEvent } from "react";

type ArticleLength =
  | "Very Short"
  | "Short"
  | "Medium"
  | "Long"
  | "Longer"
  | "Very Long";

type Range = [number, number];

type ArticleStructureFormProps = {
  selectLength: ArticleLength;
  onNewArticlePage: boolean;
  switchOutline: boolean;
  outline: string;
  autoOutline: boolean;
  allowOutline: boolean;
  onSwitchOutlineToggle: (value: boolean) => void;
  onAutoOutlineToggle: (value: boolean) => void;
  isBulk: boolean;
  outlineToggled: boolean;
  switchTitle: boolean;
};

const ArticleStructureForm = (props: ArticleStructureFormProps) => {
  const {
    switchOutline,
    onSwitchOutlineToggle,
    autoOutline,
    onAutoOutlineToggle,
    isBulk,
    onNewArticlePage,
    allowOutline,
  } = props;

  const [outlineToggled, setOutlineToggled] = useState(!!props.outlineToggled);

  const setDefaultOutlineSettings = (length: ArticleLength) => {
    switch (length) {
      case "Very Long":
        onAutoOutlineToggle(true);
        return;
      case "Longer":
        onAutoOutlineToggle(true);
        return;
      case "Long":
        onSwitchOutlineToggle(allowOutline);
        onAutoOutlineToggle(true);
        return;
      case "Medium":
        onSwitchOutlineToggle(false);
        onAutoOutlineToggle(true);
        return;
      default:
        return;
    }
  };

  const getOutlineRange = (length: ArticleLength): Range => {
    switch (length) {
      case "Very Short":
        return [0, 0];
      case "Short":
        return [0, 0];
      case "Medium":
        return [0, 0];
      case "Long":
        return [3, 5];
      case "Longer":
        return [4, 6];
      case "Very Long":
        return [4, 8];
      default:
        return [0, 0];
    }
  };

  const [selectLength, setSelectLength] = useState<ArticleLength>(
    props.selectLength
  );

  const [outlineRange, setOutlineRange] = useState(
    getOutlineRange(props.selectLength)
  );

  const lengthChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const currentLength = e.target.value as ArticleLength;
    setSelectLength(currentLength);
    const outlineRange = getOutlineRange(currentLength);
    setOutlineRange(outlineRange);
    setOutline(outline.slice(0, outlineRange[1]));
    if (forceOutlineOff(outlineRange)) {
      onSwitchOutlineToggle(false);
    }
    if (forceOutlineOn(currentLength)) {
      onSwitchOutlineToggle(allowOutline);
    }
    if (!outlineToggled) {
      setDefaultOutlineSettings(currentLength);
    }
  };

  const autoOutlineChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOutlineToggled(true);
    onAutoOutlineToggle(e.target.checked);
  };

  const [outline, setOutline] = useState(
    props.outline
      .split(",")
      .filter((outlineItem) => outlineItem && outlineItem.trim().length > 0)
  );

  const outlineItemsChangeHandler = (
    e: ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    outline[i] = e.target.value;
    setOutline(
      outline.map((outlineItem) =>
        outlineItem === undefined ? "" : outlineItem
      )
    );
  };

  const forceOutlineOn = (currentLength: string) => {
    return (
      currentLength === "Very Long" ||
      currentLength === "Longer" ||
      currentLength === "Long"
    );
  };

  const forceOutlineOff = (outlineRange: Range) => {
    return outlineRange[1] === 0;
  };

  const showSectionHeadingWarning = () => {
    return outline.some((outlineItem) => {
      return outlineItem.includes(",");
    });
  };

  const validateExcludedTopics = (realTimeExcludedTopics: string) => {
    const excludedTopicsArr = realTimeExcludedTopics.split("\n");
    if (excludedTopicsArr.length > 5) {
      return {
        excludedTopicsValid: false,
        excludedTopicsWarningMsg:
          "Warning: Article Forge has a five excluded topics limit and will select first five of your excluded topics.",
      };
    }
    if (excludedTopicsArr.some((excludedTopic) => excludedTopic.length > 100)) {
      return {
        excludedTopicsValid: false,
        excludedTopicsWarningMsg:
          "Warning: Article Forge will ignore any excluded topics longer than 100 characters.",
      };
    }
    if (
      excludedTopicsArr.some(
        (excludedTopic) => excludedTopic.split(" ").length > 10
      )
    ) {
      return {
        excludedTopicsValid: false,
        excludedTopicsWarningMsg:
          "Warning: Article Forge will ignore any excluded topics longer than 10 words.",
      };
    }
    return {
      excludedTopicsValid: true,
      excludedTopicsWarningMsg: "",
    };
  };

  const [excludedTopicsWarning, setExcludedTopicsWarning] = useState({
    show: false,
    msg: "",
  });

  const [excludedTopics, setExcludedTopics] = useState("");

  const excludedTopicsChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setExcludedTopics(e.target.value);
    const { excludedTopicsValid, excludedTopicsWarningMsg } =
      validateExcludedTopics(e.target.value);
    if (!excludedTopicsValid) {
      setExcludedTopicsWarning({ show: true, msg: excludedTopicsWarningMsg });
    } else {
      setExcludedTopicsWarning({
        show: false,
        msg: "",
      });
    }
  };

  return (
    <div className={onNewArticlePage ? "" : "row"}>
      <div
        className={
          onNewArticlePage ? "" : "col-sm-12 col-md-12 col-lg-10 offset-lg-1"
        }
      >
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">Customize Your Article's Structure</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <div className="row form-group">
              <label
                className="control-label article-form-offset-xl-2 col-sm-4 mb-0 align-self-center text-align-right pr-50"
                htmlFor="select_length"
              >
                Article Length
              </label>
              <div className="col-sm-8 col-md-8 col-lg-8 article-form-col-xl-4">
                <select
                  name="select_length"
                  id="select_length"
                  className="form-control"
                  value={selectLength}
                  onChange={lengthChangeHandler}
                >
                  <option value="Very Short">Very Short (~50 Words)</option>
                  <option value="Short">Short (~250 Words)</option>
                  <option value="Medium">Medium (~500 Words)</option>
                  <option value="Long">Long (~750 Words)</option>
                  <option value="Longer">Longer (~1000 Words)</option>
                  {allowOutline && (
                    <option value="Very Long">Very Long (~1,500 Words)</option>
                  )}
                </select>
              </div>
            </div>

            <div id="excluded_topics_div" className="form-group row">
              <Tooltip
                placement="top-end"
                title="Enter excluded topics to tell Article Forge what not to write about. This could be competitors you don't want mentioned, topics you don't want brought up, or anything else you want Article Forge to avoid when writing your article."
              >
                <label
                  className="control-label article-form-offset-xl-2 col-sm-4 mb-0 align-self-center text-align-right pr-50"
                  htmlFor="excluded_topics"
                >
                  Excluded Topics
                  <i className="las la-question-circle form-tooltip-icon" />
                </label>
              </Tooltip>
              <div className="col-sm-8 col-md-8 col-lg-8 article-form-col-xl-4">
                <textarea
                  name="excluded_topics"
                  id="excluded_topics"
                  className="form-control"
                  style={{ marginTop: 0, marginBottom: 0, height: 90 }}
                  placeholder="Add topics or entities you don't want Article Forge to write about (one per line)"
                  value={excludedTopics}
                  onChange={excludedTopicsChangeHandler}
                ></textarea>
                <span
                  className="help-block"
                  id="excluded_topics_help_block"
                  style={{ display: excludedTopics.length === 0 ? "none" : "" }}
                >
                  Add topics or entities you don't want Article Forge to write
                  about (one per line)
                  <br />
                  <span
                    style={{
                      color: "red",
                      display: excludedTopicsWarning.show ? "" : "none",
                    }}
                  >
                    {excludedTopicsWarning.msg}
                  </span>
                </span>
              </div>
            </div>

            <div
              id="auto_outline_div"
              className="row form-group"
              style={{ display: switchOutline ? "flex" : "none" }}
            >
              <input
                id="outline"
                style={{ display: "none" }}
                name="outline"
                value={outline}
                readOnly={true}
              />
              <Tooltip
                placement="top-end"
                title={
                  isBulk || !onNewArticlePage
                    ? "You cannot currently add your own section headings while using the bulk generator, all section headings will be generated automatically."
                    : 'When set to "Off" you can enter your own section headings'
                }
              >
                <label
                  className="control-label offset-md-2 col-sm-4 mb-0 align-self-center text-align-right pr-50"
                  htmlFor="auto_outline"
                >
                  Auto-generate Section Headings
                  <i className="las la-question-circle form-tooltip-icon" />
                </label>
              </Tooltip>
              <div className="col-sm-4">
                <div className="custom-control custom-switch custom-switch-text custom-control-inline">
                  <div className="custom-switch-inner">
                    <input
                      type="checkbox"
                      name="auto_outline"
                      id="auto_outline"
                      className="custom-control-input"
                      checked={autoOutline}
                      onChange={autoOutlineChangeHandler}
                      disabled={isBulk || !onNewArticlePage}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="auto_outline"
                      data-on-label="On"
                      data-off-label="Off"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              id="outline_div"
              className="outline-group row panel panel-default inner-card"
              style={{
                display: switchOutline && !autoOutline ? "" : "none",
                justifyContent: "center",
              }}
            >
              <div className="text-center mb-3">
                <small>
                  Enter {outlineRange[0]}-{outlineRange[1]} section headings to
                  provide an outline for Article Forge to follow.
                </small>
                <br />
                <small>
                  More section headings generally lead to longer articles.
                </small>
                <br />
                <small
                  style={{
                    color: "red",
                    display: showSectionHeadingWarning() ? "block" : "none",
                  }}
                >
                  Section headings cannot contain commas and should be simple
                  words or phrases that relate to your main keyword.
                  <br />
                  Please remove any commas or split your section heading into
                  multiple lines to resolve this issue.
                </small>
              </div>
              <div className="form-group col-md-10 offset-md-2">
                {[...Array(outlineRange[1])].map((_x, i) => {
                  return (
                    <div className="row" key={`outline_${i}`}>
                      <label
                        className="control-label col-sm-2 align-self-center mb-0 text-align-right"
                        style={{ height: 35, fontSize: 12 }}
                      >
                        Heading {i + 1}
                        {i < outlineRange[0] ? "*" : ""}
                      </label>
                      <div className="col-sm-7 mb-2">
                        <input
                          required
                          maxLength={50}
                          className="form-control"
                          placeholder={`Enter a section heading (${
                            i < outlineRange[0] ? "Required" : "Optional"
                          })`}
                          style={{ height: 35 }}
                          onChange={(e) => outlineItemsChangeHandler(e, i)}
                          value={outline[i] || ""}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleStructureForm;
