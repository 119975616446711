import React, { useCallback } from "react";
import FroalaEditorComp from "react-froala-wysiwyg";
import { Article } from "../ArticleApi";
import useArticleEditor from "Article/useArticleEditor";
import styles from "./Editor.module.scss";
import RewriteProcessing from "Article/components/rewrite/RewriteProcessing";
import RewriteErrorModal from "Article/components/rewrite/RewriteErrorModal";
import ActionMenuBar from "Article/components/rewrite/ActionMenuBar";

type EditorProps = Readonly<{
  article: Article;
  model: string | object | null;
  onModelChange: (value: string, dirty: boolean) => void;
  hasSpintax: boolean;
  onCodeViewUpdated: (isCodeViewActive: boolean) => void;
}>;

const Editor = ({
  article,
  model,
  onModelChange,
  hasSpintax,
  onCodeViewUpdated,
}: EditorProps) => {
  const [config, rewriteState, editor, handlers] = useArticleEditor(
    article.id,
    onCodeViewUpdated
  );

  const handleModelChange = useCallback(
    (value: unknown) => {
      if (!editor || typeof value !== "string") {
        return;
      }

      onModelChange(value, editor.clean.html(article.spintax || "") !== value);
    },
    [article.spintax, editor, onModelChange]
  );

  return (
    <div className={styles.root}>
      {rewriteState.status === "Processing" && <RewriteProcessing />}
      {rewriteState.status === "Error" && (
        <RewriteErrorModal
          onClose={handlers.handleCancelRewrite}
          type={rewriteState.type}
        />
      )}
      {rewriteState.status === "Failed" && (
        <RewriteErrorModal
          rewrite={rewriteState.rewrite}
          onClose={handlers.handleCancelRewrite}
        />
      )}
      <FroalaEditorComp
        model={model}
        onModelChange={handleModelChange}
        tag="textarea"
        config={config}
        key="WE1B5dH5H3B2A8A8D7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A4F3E3F3F2A5B4=="
      />
      {!hasSpintax && (
        <ActionMenuBar
          rewriteState={rewriteState}
          onRewrite={handlers.handleRewriteClick}
          onCancel={handlers.handleCancelRewrite}
          onPrevSuggestion={handlers.handlePrevRewriteSuggestion}
          onNextSuggestion={handlers.handleNextRewriteSuggestion}
          onAccept={handlers.handleAcceptRewriteSuggestion}
          suggestionLength={
            rewriteState.status === "Selecting"
              ? rewriteState.rewrite.articleRewriteSuggestions.length
              : 0
          }
        />
      )}
    </div>
  );
};

export default Editor;
