import React, { ChangeEvent, useEffect, useState } from "react";
import { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import styles from "./SpintaxToWpModal.module.scss";
import WordpressBlogApi, { Blog } from "../WordpressBlogApi";
import BlogInput from "./BlogInput";
import SpintaxCategoryTagInput from "./SpintaxCategoryTagInput";
import { Article } from "Article/ArticleApi";
import axios from "axios";
import ModalBody from "Common/UI/ModalBody";
import Modal from "Common/UI/Modal";

type SpintaxToWpModalProps = {
  article: Article;
  onClose: () => void;
};

type TagCategory = {
  categories: MultiValue<SelectedValue>;
  tags: MultiValue<SelectedValue>;
};

type SelectedValue = {
  value: string;
  label: string;
};

type TagCategoryList = {
  id: string;
  tags: string[];
  categories: string[];
  title: string;
};

type FormattedTagCategory = {
  tags: string[];
  categories: string[];
};

const stringToHTML = (htmlStr: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlStr, "text/html");
  return doc.body;
};

const stepTitles = [
  "Prospective Titles",
  "Select Count",
  "Customize Wordpress Options",
];

const SpintaxToWpModal = ({ article, onClose }: SpintaxToWpModalProps) => {
  const [allCategories, setAllCategories] = useState<MultiValue<SelectedValue>>(
    []
  );
  const [allTags, setAllTags] = useState<MultiValue<SelectedValue>>([]);
  const [tagCategoryList, setTagCategoryList] = useState<TagCategoryList[]>([]);
  const [postStyle, setPostStyle] = useState("default");
  const [postStatus, setPostStatus] = useState("publish");
  const [currentStep, setCurrentStep] = useState(0);
  const [blogsRetrieved, setBlogsRetrieved] = useState(false);
  const [blogs, setBlogs] = React.useState<Blog[]>([]);
  const [categoriesRetrieved, setCategoriesRetrieved] = useState(false);
  const [spintaxPostCount, setSpintaxPostCount] = useState<
    Record<string, number>
  >({});
  const [individualTerms, setIndividualTerms] = useState<
    Record<string, TagCategory>
  >({});
  const [prospectiveWpTitles, setProspectiveWpTitles] = useState([]);

  useEffect(() => {
    axios
      .post(
        "/validate_spintax",
        new URLSearchParams({
          article_id: article.id.toString(),
        })
      )
      .then(({ data }) => {
        if (data["message"] != "Success") {
          window.noty_helper(data["message"], "error", 10000);
        } else {
          setProspectiveWpTitles(data.titles);
        }
      });
  }, [article.id]);

  const fetchCategoriesAndTags = () => {
    const blogIds = blogs.map((blog: Blog) => {
      return blog.blog_id;
    });
    WordpressBlogApi.getWordpressCategoryTags({
      blog_ids: blogIds,
    }).then((jobData) => {
      const itv = setInterval(() => {
        WordpressBlogApi.fetchBlogStatus(jobData)
          .then((statusData) => {
            if (statusData.status) {
              clearInterval(itv);
              const result = statusData.result;
              const tagsAndCategoriesList: TagCategoryList[] = [];
              const termsList: Record<string, TagCategory> = {};
              Object.keys(result).forEach((blogId) => {
                termsList[blogId] = {
                  tags: [],
                  categories: [],
                };
                tagsAndCategoriesList.push({
                  id: blogId,
                  tags: result[blogId].blog_tags,
                  categories: result[blogId].blog_categories,
                  title: result[blogId].blog_title,
                });
              });
              setTagCategoryList(tagsAndCategoriesList);
              setIndividualTerms(termsList);
              setCategoriesRetrieved(true);
            }
          })
          .catch((err) => {
            clearInterval(itv);
            setCategoriesRetrieved(true);
            console.log(err);
          });
      }, 2500);
    });
  };

  const getCurrentPostCount = () => {
    let totalCount = 0;
    const values = Object.values(spintaxPostCount);
    values.forEach((count: number) => {
      totalCount += count;
    });
    return totalCount;
  };

  const checkPostCount = () => {
    const postCount = getCurrentPostCount();
    if (
      postCount < 1 ||
      (article.maxWpPosts && postCount > article.maxWpPosts)
    ) {
      window.noty_helper(
        "You cannot have less than 1 and more than the max specified blog post.",
        "error",
        5000
      );
      return false;
    }
    return true;
  };

  const handlePostCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const updatedValue: Record<string, number> = {};
    const blogId = e.target.attributes[2]?.value;
    if (blogId) {
      updatedValue[blogId] = parseInt(e.target.value);
      setSpintaxPostCount({ ...spintaxPostCount, ...updatedValue });
    }
  };

  const handleIndividualTermsChange = (
    newValue: MultiValue<SelectedValue>,
    id: string,
    type: "tags" | "categories"
  ) => {
    const terms: Record<string, TagCategory> = { ...individualTerms };
    if (type === "tags") {
      terms[id] = {
        tags: newValue,
        categories: individualTerms[id]?.categories ?? [],
      };
    } else {
      terms[id] = {
        tags: individualTerms[id]?.tags ?? [],
        categories: newValue,
      };
    }
    setIndividualTerms(terms);
  };

  const handleFormSubmit = () => {
    WordpressBlogApi.postSpintaxToBlog({
      all_terms: {
        categories: formatSelectedValue(allCategories),
        tags: formatSelectedValue(allTags),
      },
      individual_terms: formatIndividualTerms(),
      article_id: article.id,
      count_obj: spintaxPostCount,
      post_status: postStatus,
      post_style: postStyle,
    })
      .then(() => {
        window.noty_helper(
          "Post Successful with your chosen options",
          "success",
          5000
        );
        onClose();
      })
      .catch((data) => {
        if (data.responseJSON.message)
          window.add_flash_alert_global("warning", data.responseJSON.message);
      });
  };

  const formatSelectedValue = (data: MultiValue<SelectedValue>) => {
    return data.map((el) => {
      return el.value;
    });
  };

  const fetchBlogs = () => {
    WordpressBlogApi.getWordpressBlogs().then((jobData) => {
      const itv = setInterval(() => {
        WordpressBlogApi.fetchBlogStatus(jobData)
          .then((statusData) => {
            if (statusData.status) {
              clearInterval(itv);
              const result = statusData.result;
              if (result.blogs.length === 0) {
                return;
              }
              setBlogs(result.blogs);
              setBlogsRetrieved(true);
            }
          })
          .catch((err) => {
            clearInterval(itv);
            console.log(err);
          });
      }, 2500);
    });
  };

  const formatIndividualTerms = () => {
    const formattedTerms: Record<string, FormattedTagCategory> = {};
    Object.keys(individualTerms).forEach((id: string) => {
      formattedTerms[id] = {
        categories: [],
        tags: [],
      };
      individualTerms[id]?.categories.forEach((category) => {
        formattedTerms[id]?.categories.push(category.value);
      });
      individualTerms[id]?.tags.forEach((tag) => {
        formattedTerms[id]?.tags.push(tag.value);
      });
    });
    return formattedTerms;
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (currentStep === 0 && !blogsRetrieved) {
      fetchBlogs();
    }
    if (currentStep === 1) {
      if (!checkPostCount()) {
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const handleStepClick = (nextStep: number) => {
    if (nextStep === 1 && !blogsRetrieved) {
      fetchBlogs();
    }
    if (nextStep === 2) {
      if (!checkPostCount()) {
        return;
      }
    }
    setCurrentStep(nextStep);
  };

  const handlePostStyleChange = (style: string) => {
    setPostStyle(style);
    if (style === "individual" && !categoriesRetrieved) {
      fetchCategoriesAndTags();
    }
  };

  const handlePostStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPostStatus(e.target.value);
  };

  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <div className="row">
          <div className="col-lg-12">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 style={{ fontSize: "22px" }}>Post Article to Wordpress</h4>
              </div>
              <div className="panel-body">
                <form>
                  <div id="wizard_form">
                    <div
                      id="wizard_form_steps"
                      style={{ marginBottom: "20px" }}
                    >
                      <ul role="tablist" className={styles.titleList}>
                        {stepTitles.map((title, i) => {
                          return (
                            <li
                              role="tab"
                              key={i}
                              style={{
                                display: "inline",
                              }}
                            >
                              <a
                                className={styles.step}
                                onClick={() => handleStepClick(i)}
                                style={
                                  i === 2
                                    ? currentStep === i
                                      ? {
                                          borderBottom: "3px solid #50b5ff",
                                        }
                                      : currentStep === 0
                                      ? {
                                          pointerEvents: "none",
                                        }
                                      : {}
                                    : currentStep === i
                                    ? {
                                        borderBottom: "3px solid #50b5ff",
                                      }
                                    : {}
                                }
                              >
                                <span className={styles.number}>{i + 1}. </span>
                                <span className="title">{title}</span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {currentStep === 0 && prospectiveWpTitles.length > 0 && (
                      <section>
                        <div className="row">
                          <div className="col-md-12 scrollable">
                            <table className="table" id="prospective_wp_titles">
                              <tbody>
                                <tr>
                                  <th>Sample Titles</th>
                                </tr>
                                {prospectiveWpTitles.map((title, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {/* remove tags */}
                                        {stringToHTML(title).innerText}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </section>
                    )}
                    {currentStep === 1 && (
                      <section>
                        {!blogsRetrieved ? (
                          <div id="wiz2_waiting">
                            <p
                              style={{
                                margin: "auto",
                                textAlign: "center",
                              }}
                            >
                              <span className="ion-loading-a"></span>
                              <code>Retrieving your blogs ...</code>
                            </p>
                          </div>
                        ) : blogs.length > 0 ? (
                          <div className="container">
                            <div
                              className="row"
                              id="wiz2_blogs"
                              data-max-posts={article.maxWpPosts}
                            >
                              {blogs.map((blog: Blog) => {
                                return (
                                  <BlogInput
                                    key={blog.blog_id}
                                    blog={blog}
                                    value={
                                      spintaxPostCount[blog.blog_id]
                                        ? spintaxPostCount[blog.blog_id]
                                        : 0
                                    }
                                    handleChange={handlePostCountChange}
                                  />
                                );
                              })}
                            </div>
                            <div className="row" id="wiz2_foot_info">
                              <p>
                                <strong>NOTE: </strong>Article Forge will
                                generate approximately {article.maxWpPosts}{" "}
                                {article.maxWpPosts && article.maxWpPosts > 1
                                  ? "unique articles"
                                  : "unique article"}{" "}
                                based on the settings you have chosen. As a
                                result, you can post a maximum of{" "}
                                {article.maxWpPosts}{" "}
                                {article.maxWpPosts && article.maxWpPosts > 1
                                  ? "blog posts"
                                  : "blog post"}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <p style={{ color: "red" }}>
                              Sorry, you don't have any WordPress blogs. Please
                              go to WordPress section and connect with your
                              WordPress sites.
                            </p>
                          </div>
                        )}
                      </section>
                    )}
                    {currentStep === 2 && (
                      <section>
                        <div
                          id="article_wp_settings_panel"
                          className="panel panel-default"
                        >
                          <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                              <h5 className="text-center">
                                Customize how we post to your selected blogs
                              </h5>
                            </div>
                          </div>
                          <div className="iq-card-body">
                            <div className="form-group row">
                              <label className="control-label col-sm-4 mb-0 align-self-center text-align-right pr-50">
                                Status
                              </label>
                              <select
                                name="post_status"
                                className="form-control col-sm-4"
                                id="post_status"
                                onChange={handlePostStatusChange}
                                value={postStatus}
                              >
                                <option value="publish">Published</option>
                                <option value="pending">Pending Review</option>
                                <option value="draft">Draft</option>
                              </select>
                            </div>
                            <div className="form-group row">
                              <div className="col-md-8 offset-md-2">
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="wp_terms_option"
                                      id="default_term"
                                      value="default"
                                      checked={postStyle === "default"}
                                      onChange={() =>
                                        handlePostStyleChange("default")
                                      }
                                    />{" "}
                                    Post with default categories/tags
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="wp_terms_option"
                                      id="random_term"
                                      value="random"
                                      checked={postStyle === "random"}
                                      onChange={() =>
                                        handlePostStyleChange("random")
                                      }
                                    />{" "}
                                    Post with random category/tag retrieved from
                                    each blog
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="wp_terms_option"
                                      id="specific_wordpress_all"
                                      value="all"
                                      checked={postStyle === "all"}
                                      onChange={() =>
                                        handlePostStyleChange("all")
                                      }
                                    />{" "}
                                    Post with specific category/tag for all
                                    blogs
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="wp_terms_option"
                                      id="specific_wordpress_individual"
                                      value="individual"
                                      checked={postStyle === "individual"}
                                      onChange={() =>
                                        handlePostStyleChange("individual")
                                      }
                                    />{" "}
                                    Post with specific category/tag for each
                                    blog
                                  </label>
                                </div>
                                <span className="help-block term-help-label">
                                  Use comma or enter for tokenization
                                </span>
                              </div>
                            </div>
                            {postStyle === "all" && (
                              <div className="row" id="wp_all_settings">
                                <div className="col-md-12">
                                  <label
                                    className="control-label"
                                    style={{ display: "inline-block" }}
                                  >
                                    WordPress Categories
                                  </label>
                                  <CreatableSelect
                                    name="categories"
                                    className="control-label"
                                    isMulti
                                    placeholder=""
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                    }}
                                    value={allCategories}
                                    onChange={setAllCategories}
                                  />
                                  <label
                                    className="control-label"
                                    style={{ display: "inline-block" }}
                                  >
                                    WordPress Tags
                                  </label>
                                  <CreatableSelect
                                    name="tags"
                                    className="control-label"
                                    isMulti
                                    placeholder=""
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                    }}
                                    value={allTags}
                                    onChange={setAllTags}
                                  />
                                </div>
                              </div>
                            )}
                            {postStyle === "individual" && (
                              <div className="row" id="wp_individual_settings">
                                {!categoriesRetrieved ? (
                                  <div
                                    className="col-md-12"
                                    id="loading_individual_terms"
                                    style={{
                                      paddingBottom: "50px",
                                    }}
                                  >
                                    <span className="ion-loading-a"></span>
                                    Retrieving your selected WordPress
                                    Categories and Tags...
                                  </div>
                                ) : (
                                  <div
                                    className="col-md-12 scrollable"
                                    id="terms_collection"
                                  >
                                    {tagCategoryList.map((blog, i) => {
                                      return (
                                        <SpintaxCategoryTagInput
                                          key={i}
                                          id={blog?.id || ""}
                                          tags={blog?.tags || [""]}
                                          categories={blog?.categories || [""]}
                                          title={blog?.title || ""}
                                          handleTermsChange={
                                            handleIndividualTermsChange
                                          }
                                          categoriesSelected={
                                            individualTerms[blog.id]?.categories
                                          }
                                          tagsSelected={
                                            individualTerms[blog.id]?.tags
                                          }
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <div
        style={{ marginBottom: "17px" }}
        className={styles.spintaxModalFooter}
      >
        <button
          onClick={handlePreviousStep}
          disabled={currentStep === 0}
          className={styles.spintaxModalButton}
          style={{ marginLeft: "15px" }}
        >
          <i className="fa fa-angle-left"></i> Previous Step
        </button>
        {currentStep === 2 ? (
          <button
            id="spintax_post_to_wp"
            type="button"
            style={{ marginRight: "15px" }}
            className="btn btn-primary pull-right"
            onClick={handleFormSubmit}
          >
            Post to Wordpress
          </button>
        ) : (
          <button
            onClick={handleNextStep}
            className={styles.spintaxModalButton}
            style={{ marginRight: "32px" }}
          >
            Next Step <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </Modal>
  );
};

export default SpintaxToWpModal;
