import React, { useState } from "react";
import PricePanel from "./PricePanel";
import styles from "./PlanPanel.module.scss";

type intervalCount = 1 | 12;

type Plan = {
  id: number;
  name: string;
  amount: number;
  unlimited: boolean;
  api_limit: number;
  sub_account_count: number;
  interval_count: intervalCount;
};

type PlanFormProps = {
  plans: Plan[];
  isYearlyUser: boolean;
};

const BusinessContactForm = () => {
  return (
    <div className="col-xl-5 col-lg-6 col-md-12">
      <div className="price-table mt-3 pb-3">
        <div className="price-inside">Business</div>
        <div className="price-header mb-3">
          <div style={{ minHeight: "195px" }}>
            <div className="price-value">
              <h2>Business</h2>
              <span>
                <small>Billed monthly or yearly</small>
              </span>
            </div>
            <h4 style={{ marginTop: "10px", marginBottom: "15px" }}>
              Custom words
              <br />& users
            </h4>
          </div>
          <a
            className="btn btn-block btn-primary btn-action"
            href="https://articleforge.typeform.com/business-form"
            target="_blank"
            rel="noreferrer"
          >
            Contact Sales!
          </a>
        </div>
        <div className={`price-list ${styles.priceList}`}>
          <ul className={`list-unstyled ${styles.listUnstyled}`}>
            <li>
              <strong>All standard features plus:</strong>
            </li>
            <li>500,000+ words</li>
            <li>Custom user accounts</li>
            <li>Increased article throughput</li>
            <li>Dedicated account manager</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const PlanForm = ({ plans, isYearlyUser }: PlanFormProps) => {
  const [tab, setTab] = useState("yearly");

  const monthlyPlans = plans
    .filter((plan) => {
      return plan.interval_count === 1;
    })
    .sort((a, b) => {
      return a.amount - b.amount;
    });

  const yearlyPlans = plans
    .filter((plan) => {
      return plan.interval_count > 1;
    })
    .sort((a, b) => {
      return a.amount - b.amount;
    });

  const switchTab = (curr: string) => {
    if (curr === "monthly") {
      setTab("monthly");
    } else {
      setTab("yearly");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="tab">
            <div className="col-lg-6 col-md-12 ml-auto mr-auto">
              <nav>
                <div
                  className="nav nav-tabs border-box-toggle ml-auto mr-auto"
                  id="nav-tab"
                  role="tablist"
                  style={{ marginTop: "20px" }}
                >
                  {!isYearlyUser && (
                    <a
                      className={
                        tab === "monthly"
                          ? "nav-item nav-link mr-0 active"
                          : "nav-item nav-link mr-0"
                      }
                      onClick={() => switchTab("monthly")}
                      style={{
                        width: "140px",
                        height: "70px",
                        borderRadius: "0px",
                      }}
                    >
                      Monthly
                    </a>
                  )}
                  <a
                    className={
                      tab === "yearly"
                        ? "pt-1 pb-1 pl-2 pr-2 nav-item nav-link active"
                        : "pt-1 pb-1 pl-2 pr-2 nav-item nav-link"
                    }
                    onClick={() => switchTab("yearly")}
                    style={{
                      width: "140px",
                      height: "70px",
                      borderRadius: "0px",
                    }}
                  >
                    Yearly
                    <p
                      style={{
                        fontSize: "13px",
                        paddingTop: "0px",
                        marginBottom: "4px",
                        marginTop: "-4px",
                        fontWeight: "400",
                      }}
                    >
                      (Save 51%)
                    </p>
                  </a>
                </div>
              </nav>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12">
                <div
                  role="tabpanel"
                  className="tab-pane show active"
                  id="tab2-2"
                >
                  <div className="row align-items-center">
                    {tab === "yearly"
                      ? yearlyPlans.map((plan: Plan) => {
                          return (
                            <PricePanel tab={tab} plan={plan} key={plan.id} />
                          );
                        })
                      : monthlyPlans.map((plan) => {
                          return (
                            <PricePanel tab={tab} plan={plan} key={plan.id} />
                          );
                        })}
                    <BusinessContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default PlanForm;
