import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import styles from "./Dropdown.module.scss";
import clsx from "clsx";
import useOnClickOutside from "Common/UI/useOnClickOutside";
import uniqueId from "Common/uniqueId";

type DropdownProps = Readonly<{
  label: string;
  children: Array<ReactElement<DropdownItemProps> | undefined>;
  firstButtonInGroup?: boolean;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}>;

export function Dropdown({
  children,
  label,
  firstButtonInGroup,
  onClose,
  onOpen,
  open,
}: DropdownProps) {
  const opsDropdownRef = useRef(null);

  const id = useMemo(() => {
    return uniqueId("dropdown");
  }, []);

  useOnClickOutside(opsDropdownRef, onClose);

  return (
    <div
      ref={opsDropdownRef}
      className={clsx("btn-group", { open: open })}
      role="group"
    >
      <button
        id={id}
        type="button"
        className={clsx("btn", "btn-outline-dark", "dropdown-toggle", {
          "first-btn": firstButtonInGroup,
        })}
        aria-haspopup="true"
        aria-expanded={open}
        onClick={open ? onClose : onOpen}
      >
        {label}
      </button>
      <ul className="dropdown-menu" aria-labelledby={id}>
        {children}
      </ul>
    </div>
  );
}

type DropdownItemProps = Readonly<{
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

export function DropdownItem({ onClick, children }: DropdownItemProps) {
  return (
    <li>
      <button className={styles.dropdownItem} onClick={onClick}>
        {children}
      </button>
    </li>
  );
}
