import React, { ChangeEvent } from "react";
import Modal from "../../Common/UI/Modal";
import ModalHeader from "Common/UI/ModalHeader";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";
import DollarAmount from "Common/UI/DollarAmount";

type PrepayFormModalProps = {
  prepayAmount: string;
  hasOverdueBalance: boolean;
  overdueOverageAmount: number;
  onPrepayChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onSubmit: () => void;
};

const paymentRoundedUp = (cents: number) => {
  cents = Math.floor(cents / 500) * 500 + 500;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cents / 100);
};

const PrepayFormModal = ({
  prepayAmount,
  hasOverdueBalance,
  overdueOverageAmount,
  onPrepayChange,
  onClose,
  onSubmit,
}: PrepayFormModalProps) => {
  return (
    <Modal onClose={onClose}>
      <ModalHeader title="Prepay Form" onClose={onClose} />
      <ModalBody>
        <div className="row form-group">
          <label className="control-label-sm offset-md-1 col-sm-4 mb-0 align-self-top">
            Prepay Amount:
          </label>
          <div
            className="input-group input-group-sm mb-3"
            style={{ width: "40%" }}
          >
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              type="text"
              className="form-control"
              value={prepayAmount}
              onChange={onPrepayChange}
            />
          </div>
          {hasOverdueBalance && (
            <span className="mt-2 control-label-sm offset-md-1 mb-0 align-seslf-top pl-15 pr-15 help-block">
              We noticed that you have an overage amount of{" "}
              <DollarAmount value={overdueOverageAmount} />. Your prepay amount
              needs to cover this overdue balance first.
            </span>
          )}
          <span className="mt-2 control-label-sm offset-md-1 mb-0 align-seslf-top pl-15 pr-15 help-block">
            Please enter the amount you would like to pay in multiples of $5.00
            (20,000 words).
            {hasOverdueBalance &&
              `Your current minimum prepay amount is ${paymentRoundedUp(
                overdueOverageAmount
              )}.`}
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-dark" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Pay
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PrepayFormModal;
