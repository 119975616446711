import React from "react";
import styles from "./UsageLimitPanelBody.module.scss";
import Panel from "../../Common/UI/Panel";
import Toggle from "../../Common/UI/Toggle";
import DollarAmount from "Common/UI/DollarAmount";

type UsageLimitPanelBodyProps = {
  onOveragePlan: boolean;
  wordLimit: number;
  isTrialUser: boolean;
  extraCostPerUnit: number;
  extraUnitWords: number;
  showPrepay: boolean;
  isPrepayDisabled: boolean;
  isToggled: boolean;
  onToggle: (checked: boolean) => void;
  onPrepayModalOpen: () => void;
  redirectFrom: string;
};

const UsageLimitPanelBody = ({
  onOveragePlan,
  wordLimit,
  isTrialUser,
  extraCostPerUnit,
  extraUnitWords,
  showPrepay,
  isPrepayDisabled,
  isToggled,
  onToggle,
  onPrepayModalOpen,
  redirectFrom,
}: UsageLimitPanelBodyProps) => {
  return (
    <>
      {onOveragePlan ? (
        <>
          <h5>
            <b>
              Allow Article Forge to create more than{" "}
              {wordLimit.toLocaleString()} words in a month.
            </b>
          </h5>
          {isTrialUser ? (
            <div className="row mt-2">
              <div className="col-md-4">
                <a
                  className="btn btn-sm btn-primary mt-2"
                  href="/billings?end_trial=true"
                >
                  Manage Subscription
                </a>
              </div>
              <div className="col-md-8">
                <p>
                  You cannot use this feature in trial. Click the "Manage
                  Subscription" button to end your trial and start your full
                  subscription.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div
                className="row mt-2"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="col-md-2">
                  <Toggle
                    id="overuse_allowed"
                    checked={isToggled}
                    onToggle={onToggle}
                    size="lg"
                    onLabel="On"
                    offLabel="Off"
                  />
                </div>
                <div className="col-md-10">
                  <p>
                    When additional usage is "On", you can create unlimited
                    articles with Article Forge. Overage words cost{" "}
                    <DollarAmount value={extraCostPerUnit} /> per{" "}
                    {extraUnitWords.toLocaleString()} words and will be billed
                    to your account at the end of each usage period.
                  </p>
                </div>
              </div>
              {showPrepay && (
                <div
                  className="row mt-2"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="col-md-2">
                    <button
                      className={`btn btn-sm btn-primary ${styles.prepayButton}`}
                      onClick={onPrepayModalOpen}
                      disabled={isPrepayDisabled}
                    >
                      Prepay
                    </button>
                  </div>
                  <div className="col-md-10">
                    <p>
                      {" "}
                      Buy any number of words at a time. These words will not
                      expire and can be used with any feature.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div>
          <div className="row mt-2">
            <div className="col-md-12">
              <p>
                Your current plan does not allow additional usage. To create
                more than words per month, you will need to upgrade to a larger
                plan.
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <a
              href={`/plan/index?from=${redirectFrom}`}
              style={{ marginLeft: "10px" }}
              className="btn btn-primary pull-right"
            >
              Upgrade
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default UsageLimitPanelBody;
