import React, { ReactNode } from "react";
import { Tooltip } from "@mui/material";
import styles from "./WordUsageRow.module.scss";

type WordUsageRowProps = Readonly<{
  label: string;
  tooltip?: string;
  children: ReactNode;
}>;

const WordUsageRow = ({ label, tooltip, children }: WordUsageRowProps) => {
  return (
    <tr>
      <td className="mt-2 mb-2" style={{ fontSize: "16px" }}>
        <span className="text-primary">{label}: </span>
        {tooltip && (
          <Tooltip placement="right" title={tooltip}>
            <small className={styles.helpTip} />
          </Tooltip>
        )}
      </td>
      <td>
        <span>{children}</span>
      </td>
    </tr>
  );
};

export default WordUsageRow;
