import "core-js/stable";
import "expose-loader?exposes[]=$&exposes[]=jQuery!jquery";
import "jquery-ujs";
import "jquery.turbolinks/vendor/assets/javascripts/jquery.turbolinks";
import "rx-lite";
import "zeroclipboard";
import "icheck";
import "tinynav";
import "font-awesome/scss/font-awesome.scss";
import "bootstrap-sass";
import "jquery-autosize";
import "select2";
import "spin.js";
import "jquery-powertip/dist/jquery.powertip";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";

// for modals
import "magnific-popup";
import "jquery.noty.packaged.js";

import { init as initSentry, setUser, captureMessage } from "@sentry/react";
import ReactOnRails from "react-on-rails";
import NewArticleForm from "../Article/components/NewArticleForm";
import ShowArticlePage from "../Article/components/ShowArticlePage";
import SubAccountForm from "../Organization/components/SubAccountForm";
import SqueezePage from "../Organization/components/SqueezePage";
import ApiInfoPage from "../Api/components/ApiInfoPage";
import UsageInfoPage from "../Usage/components/UsageInfoPage";
import MrrMetricsPage from "../AdminMetric/components/MrrMetricsPage";

if (process.env.NODE_ENV === "production") {
  initSentry({
    dsn: "https://a18b156a48eb48569ea9178cfffd201c@o4504137597059072.ingest.sentry.io/4504137640181760",
    release: process.env.RELEASE,
    allowUrls: ["articleforge.com"],
  });
  document.addEventListener("DOMContentLoaded", () => {
    if (window.currentUser) {
      setUser({ email: window.currentUser.email, id: window.currentUser.id });
    }
  });
  window.sentryCaptureMessage = function (message) {
    captureMessage(message);
  };
}

ReactOnRails.setOptions({
  traceTurbolinks: process.env.NODE_ENV !== "production",
});

ReactOnRails.register({
  NewArticleForm,
  SubAccountForm,
  SqueezePage,
  ShowArticlePage,
  ApiInfoPage,
  UsageInfoPage,
  MrrMetricsPage,
});
