// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ApiKeyPanelBody-module__container___3C47k{max-width:50%;flex:0 0 100%;box-sizing:border-box;padding-right:15px;min-width:-moz-fit-content;min-width:fit-content}.ApiKeyPanelBody-module__regenerateButton___3c2Jh{color:#007bff;background-color:transparent;border:0}.ApiKeyPanelBody-module__regenerateButton___3c2Jh:hover{text-decoration:underline}.ApiKeyPanelBody-module__downloadButton___3o-T3{color:var(--iq-primary);background-color:transparent;border:0;padding:0;transition:all 0.5s ease-out 0s}.ApiKeyPanelBody-module__downloadButton___3o-T3:hover{color:black}\n", "",{"version":3,"sources":["/home/afweb/rails/releases/20240501201507/app/javascript/Api/components/ApiKeyPanelBody.module.scss"],"names":[],"mappings":"AAAA,2CACE,aAAc,CACd,aAAc,CACd,qBAAsB,CACtB,kBAAmB,CACnB,0BAAW,CAAX,qBAAsB,CACvB,kDAGC,aAAc,CACd,4BAA6B,CAC7B,QAAS,CAHX,wDAMI,yBAA0B,CAC3B,gDAID,uBAAwB,CACxB,4BAA6B,CAC7B,QAAS,CACT,SAAU,CACV,+BAAgC,CALlC,sDAQI,WAAY","file":"ApiKeyPanelBody.module.scss","sourcesContent":[".container {\n  max-width: 50%;\n  flex: 0 0 100%;\n  box-sizing: border-box;\n  padding-right: 15px;\n  min-width: fit-content;\n}\n\n.regenerateButton {\n  color: #007bff;\n  background-color: transparent;\n  border: 0;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.downloadButton {\n  color: var(--iq-primary);\n  background-color: transparent;\n  border: 0;\n  padding: 0;\n  transition: all 0.5s ease-out 0s;\n\n  &:hover {\n    color: black;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "ApiKeyPanelBody-module__container___3C47k",
	"regenerateButton": "ApiKeyPanelBody-module__regenerateButton___3c2Jh",
	"downloadButton": "ApiKeyPanelBody-module__downloadButton___3o-T3"
};
module.exports = exports;
