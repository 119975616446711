import React from "react";
import UsageLimitPanel from "./UsageLimitPanel";
import WordsUsedPanel from "./WordsUsedPanel";
import Page from "../../Common/UI/Page";

type UsageInfoPageProps = {
  isTrialUser: boolean;
  isOveruseAllowed: boolean;
  extraCostPerUnit: number;
  extraUnitWords: number;
  onOveragePlan: boolean;
  allowToggleOverage: boolean;
  hasOverdueBalance: boolean;
  overdueOverageAmount: number;
  totalInterfaceWords: number;
  totalAutoPilotWords: number;
  prepaidWordsAvailable: number;
  planWordLimit: number;
  unpaidOverageWords: number;
  upgradePageSource: string;
  resetDate: string;
  onLimitPlan: boolean;
};

const UsageInfoPage = ({
  isTrialUser,
  isOveruseAllowed,
  extraCostPerUnit,
  extraUnitWords,
  onOveragePlan,
  allowToggleOverage,
  hasOverdueBalance,
  overdueOverageAmount,
  totalInterfaceWords,
  totalAutoPilotWords,
  prepaidWordsAvailable,
  planWordLimit,
  unpaidOverageWords,
  resetDate,
  onLimitPlan,
}: UsageInfoPageProps) => {
  return (
    <Page>
      <div className="row">
        <div
          className={
            allowToggleOverage ? "col-sm-12 col-lg-6" : "col-sm-12 col-lg-12"
          }
        >
          <WordsUsedPanel
            isTrialUser={isTrialUser}
            totalInterfaceWords={totalInterfaceWords}
            totalAutoPilotWords={totalAutoPilotWords}
            prepaidWordsAvailable={prepaidWordsAvailable}
            planWordLimit={planWordLimit}
            unpaidOverageWords={unpaidOverageWords}
            resetDate={resetDate}
            onLimitPlan={onLimitPlan}
          />
        </div>
        {allowToggleOverage && (
          <div
            className="col-sm-12 col-lg-6"
            style={{ display: "inline-block" }}
          >
            <UsageLimitPanel
              isTrialUser={isTrialUser}
              wordLimit={planWordLimit}
              isOveruseAllowed={isOveruseAllowed}
              extraCostPerUnit={extraCostPerUnit}
              extraUnitWords={extraUnitWords}
              onOveragePlan={onOveragePlan}
              hasOverdueBalance={hasOverdueBalance}
              overdueOverageAmount={overdueOverageAmount}
              redirectFrom="usage"
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default UsageInfoPage;
