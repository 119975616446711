import React from "react";
import Modal from "../../Common/UI/Modal";
import ModalBody from "Common/UI/ModalBody";
import ModalFooter from "Common/UI/ModalFooter";

type RegenerateKeyModalProps = {
  onClose: () => void;
  onUpdate: () => void;
};

const RegenerateKeyModal = ({ onClose, onUpdate }: RegenerateKeyModalProps) => {
  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <button
          onClick={onClose}
          className="bootbox-close-button close"
          style={{ marginTop: "-10px" }}
        >
          &times;
        </button>
        <div>Please confirm resetting API key.</div>
      </ModalBody>
      <ModalFooter>
        {" "}
        <button onClick={onClose} className="btn btn-default">
          Cancel
        </button>
        <button onClick={onUpdate} className="btn btn-primary">
          OK
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RegenerateKeyModal;
