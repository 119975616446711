import React, { memo } from "react";
import styles from "./RewriteActionButton.module.scss";

type RewriteActionButtonProps = Readonly<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  tooltip?: string;
  children: string;
  showRewrite: boolean;
}>;

function RewriteActionButton({
  onClick,
  disabled,
  tooltip,
  children,
  showRewrite,
}: RewriteActionButtonProps) {
  return (
    <span
      className={showRewrite ? styles.title : styles.titleWithoutRewrite}
      title={tooltip}
    >
      <button
        className={showRewrite ? styles.button : styles.buttonWithoutRewrite}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </span>
  );
}

export default memo(RewriteActionButton);
