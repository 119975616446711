import React, { MouseEvent, useEffect, useState } from "react";
import Select, { SingleValue, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import Modal from "../../Common/UI/Modal";
import ModalBody from "../../Common/UI/ModalBody";
import ModalFooter from "../../Common/UI/ModalFooter";
import WordpressBlogApi, {
  Blog,
  WordpressBlogResult,
} from "../WordpressBlogApi";
import { Article } from "Article/ArticleApi";
import ModalHeader from "Common/UI/ModalHeader";

type SelectedValue = {
  value: number | string;
  label: string;
};

type WordpressModalProps = Readonly<{
  article: Article;
  content: string | null;
  onClose: () => void;
}>;

const postStatusOptions = [
  { value: "publish", label: "Published" },
  { value: "pending", label: "Pending Review" },
  { value: "draft", label: "Draft" },
];

const getDefaultTitle = (article: string, articleTitle: string) => {
  const regex = /.*?<h([12345])>(.*?)<\/h\1>/;
  const match = regex.exec(article);
  if (match) {
    return match[2];
  } else {
    return articleTitle;
  }
};

const getValues = (arr: MultiValue<SelectedValue>) => {
  return arr.map((el: SelectedValue) => {
    return el.value.toString();
  });
};

const WordpressModal = ({ article, content, onClose }: WordpressModalProps) => {
  const [postTitle, setPostTitle] = useState(
    getDefaultTitle(content ?? "", article.title ?? "")
  );
  const [postStatus, setPostStatus] = useState("publish");
  const [selectedCategories, setSelectedCategories] = React.useState<
    MultiValue<SelectedValue>
  >([]);
  const [selectedTags, setSelectedTags] = React.useState<
    MultiValue<SelectedValue>
  >([]);
  const [isPosting, setIsPosting] = useState(false);

  const handleStatusChange = (newValue: SingleValue<SelectedValue>) => {
    setPostStatus(newValue ? newValue.value.toString() : "");
  };

  const handleCategoryChange = (newValue: MultiValue<SelectedValue>) => {
    setSelectedCategories(newValue);
  };

  const handleTagChange = (newValue: MultiValue<SelectedValue>) => {
    setSelectedTags(newValue);
  };

  const handleArticleTitleChange = (e: { target: { value: string } }) => {
    setPostTitle(e.target.value);
  };

  const [isRetrievingCategories, setIsRetrievingCategories] = useState(false);
  const [selectedBlog, setSelectedBlog] =
    React.useState<SingleValue<SelectedValue>>();
  const [blogs, setBlogs] = React.useState<Blog[]>([]);
  const [categories, setCategories] = React.useState<string[]>([]);
  const [tags, setTags] = React.useState<string[]>([]);
  const [isWordpressLoading, setIsWordpressLoading] = useState(true);

  const handleBlogChange = (newBlog: SingleValue<SelectedValue>) => {
    if (!newBlog) return;
    WordpressBlogApi.getWordpressCategoryTags({
      blog_ids: [
        typeof newBlog.value === "string"
          ? parseInt(newBlog.value)
          : newBlog.value,
      ],
    }).then((jobData) => {
      setIsRetrievingCategories(true);
      const itv = setInterval(() => {
        WordpressBlogApi.fetchBlogStatus(jobData)
          .then((statusData) => {
            if (statusData.status) {
              clearInterval(itv);
              const result = statusData.result;
              const selectedBlog = result[newBlog.value];
              setSelectedBlog(newBlog);
              setCategories(selectedBlog.blog_categories);
              setTags(selectedBlog.blog_tags);
              setIsRetrievingCategories(false);
            }
          })
          .catch((err) => {
            clearInterval(itv);
            setIsRetrievingCategories(false);
            console.log(err);
          });
      }, 2500);
    });
  };

  useEffect(() => {
    console.log("running useEffect....");
    let interval: number | null = null;
    WordpressBlogApi.getWordpressBlogs().then((jobData) => {
      interval = window.setInterval(() => {
        WordpressBlogApi.fetchBlogStatus(jobData)
          .then((statusData) => {
            if (statusData.status) {
              if (interval) {
                clearInterval(interval);
              }

              const result = statusData.result;
              if (result.blogs.length === 0) {
                setIsWordpressLoading(false);
                return;
              }
              const firstBlog = result.blogs[0];
              setBlogs(result.blogs);
              setSelectedBlog({
                value: firstBlog.id,
                label: `${firstBlog.blog_url} ${firstBlog.name}`,
              });
              setCategories(result.first_blog_categories);
              setTags(result.first_blog_tags);
              setIsWordpressLoading(false);
            }
          })
          .catch((err) => {
            if (interval) {
              clearInterval(interval);
            }
            console.log(err);
          });
      }, 2500);
    });
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const handleWordpressSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const data = {
      article_id: article.id.toString(),
      blog_id: `blog_${selectedBlog ? selectedBlog.value : ""}`,
      categories: getValues(selectedCategories),
      content: content ?? "",
      post_status: postStatus,
      post_title: postTitle ?? "",
      tags: getValues(selectedTags),
    };
    WordpressBlogApi.postArticleToWordpress(data).then(
      (jobData: WordpressBlogResult) => {
        setIsPosting(true);
        window.add_flash_alert_global(
          "success",
          `We are posting you article (${postTitle}) to the selected Wordpress blog.`
        );
        const itv = setInterval(() => {
          WordpressBlogApi.fetchBlogStatus(jobData)
            .then((statusData) => {
              if (statusData.status) {
                clearInterval(itv);
                const data = statusData.result;
                window.add_flash_alert_global(data.result, data["msg"]);
                setIsPosting(false);
              }
            })
            .catch((data) => {
              clearInterval(itv);
              if (data.responseJSON.message)
                window.add_flash_alert_global(
                  "warning",
                  data.responseJSON.message
                );
            });
        }, 2500);
      }
    );
  };

  const blogOptions = blogs.map((blog) => {
    return {
      value: blog.id,
      label: `${blog.blog_url} ${blog.name}`,
    };
  });

  return (
    <Modal onClose={onClose} id="wordpress-modal">
      <ModalHeader
        title="Select the blog you want to post the article to"
        onClose={onClose}
      />
      <ModalBody>
        {isWordpressLoading ? (
          <div id="wp_waiting">
            <p style={{ margin: "auto", textAlign: "center" }}>
              <span className="ion-loading-a"></span>
              <code>Retrieving your authorized blogs ...</code>
            </p>
          </div>
        ) : blogs.length > 0 ? (
          <div>
            <label
              className="control-label"
              style={{ display: "inline-block" }}
            >
              Blog
            </label>
            <Select
              defaultValue={selectedBlog ?? null}
              name="single_blog"
              id="blog_single_select"
              onChange={handleBlogChange}
              options={blogOptions}
              isDisabled={isRetrievingCategories}
            />
            <label
              className="control-label"
              style={{ display: "inline-block" }}
            >
              Status
            </label>
            <Select
              defaultValue={postStatusOptions[0]}
              name="blog_post_status"
              id="blog_post_status"
              options={postStatusOptions}
              onChange={handleStatusChange}
            />
            <div
              id="retrieving_categories_tags"
              style={{ display: "none", marginTop: "5px" }}
            >
              <span className="ion-loading-a"></span>Retrieving WordPress
              Categories and Tags...
            </div>
            {isRetrievingCategories ? (
              <div id="retrieving_categories_tags" style={{ marginTop: "5px" }}>
                <span className="ion-loading-a"></span>Retrieving WordPress
                Categories and Tags...
              </div>
            ) : (
              <div id="categories_tags">
                <label
                  className="control-label"
                  style={{ display: "inline-block" }}
                >
                  WordPress Categories
                </label>
                <CreatableSelect
                  id="spin_blog_categories"
                  name="categories"
                  className="blog-categories"
                  isMulti
                  placeholder=""
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  options={categories.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={handleCategoryChange}
                />

                <label
                  className="control-label"
                  style={{ display: "inline-block" }}
                >
                  WordPress Tags
                </label>
                <CreatableSelect
                  id="spin_blog_tags"
                  name="tags"
                  className="blog-tags"
                  isMulti
                  placeholder=""
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  options={tags.map((tag) => ({ value: tag, label: tag }))}
                  onChange={handleTagChange}
                />
              </div>
            )}
            <span id="term_help_block" className="help-block">
              Use comma or enter for tokenization
            </span>
            <hr />
            <div className="form-group" style={{ paddingBottom: "5px" }}>
              <label
                className="control-label"
                style={{ display: "inline-block" }}
                htmlFor="post_title"
              >
                Article Title
              </label>
              <input
                defaultValue={postTitle}
                className="form-control"
                name="post_title"
                id="post_title"
                onChange={handleArticleTitleChange}
              ></input>
            </div>
          </div>
        ) : (
          <div>
            <p style={{ color: "red" }}>
              Sorry, you have no WordPress blogs. Please click the WordPress
              icon on the left navigation bar to link your WordPress account.
            </p>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          id="wp_post_article"
          type="button"
          style={{ marginLeft: "10px" }}
          className="btn btn-primary pull-right"
          data-dismiss="modal"
          onClick={handleWordpressSubmit}
          disabled={isPosting}
        >
          {isPosting ? "Posting to Wordpress" : "Post"}
        </button>
        <button
          type="button"
          className="btn btn-outline-dark pull-right"
          data-dismiss="modal"
          onClick={onClose}
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default WordpressModal;
