import React, { ChangeEvent, useState } from "react";

type PostSchedulerKeywordFormProps = {
  keywords: string;
};

const PostSchedulerKeywordForm = (props: PostSchedulerKeywordFormProps) => {
  const placeholderAndHint = {
    placeholder: "Keyword1\nKeyword2\nKeyword3\n...",
    hint: (
      <div>
        Keywords cannot contain special characters such as commas, parentheses,
        brackets and must be no more than 50 words.
      </div>
    ),
  };

  const [keywords, setKeywords] = useState(parseLines(props.keywords));
  const [showSubKwdWarning, setShowSubKwdWarning] = useState(
    keywords.includes(",")
  );

  const keywordsChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newKeywords = e.target.value;
    setKeywords(parseLines(newKeywords));
    setShowSubKwdWarning(newKeywords.includes(","));
  };
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">Create Post Scheduler</h4>
            </div>
            <span>Enter the list of keywords</span>
          </div>

          <div className="iq-card-body">
            <div className="row form-group">
              <label
                className="control-label col-sm-3 align-self-top mb-0 text-align-right"
                htmlFor="keywords_for_post"
              >
                Keywords
              </label>
              <div className="col-sm-9">
                <textarea
                  name="keywords_for_post"
                  id="keywords_for_post"
                  className="form-control"
                  style={{ minHeight: 120, lineHeight: "normal" }}
                  placeholder={placeholderAndHint["placeholder"]}
                  value={keywords}
                  onChange={keywordsChangeHandler}
                ></textarea>
                <div style={{ marginTop: 20, fontSize: 13 }}>
                  <span>
                    <strong>
                      Each line will be a separate keyword. For each WP Post, a
                      keyword will be chosen in round-robin fashion.
                    </strong>
                  </span>
                  <br />
                  <span
                    className="help-block"
                    id="bulk_help_block"
                    style={{ display: "block" }}
                  >
                    {placeholderAndHint["hint"]}
                  </span>
                  {showSubKwdWarning && (
                    <span
                      className="help-block"
                      id="bulk_help_block"
                      style={{ display: "block", color: "red" }}
                    >
                      Article Forge no longer accepts sub keywords. All text
                      after the first comma on each line will be ignored.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSchedulerKeywordForm;

function parseLines(str: string) {
  return str.replace(/(\\n)/g, "\n");
}
