import axios from "axios";
import React, { useState, useEffect, ChangeEvent } from "react";
import Panel from "../../Common/UI/Panel";

type SubAccountFormProps = {
  onCreatePage: boolean;
  showChargeReminder: boolean;
  maxSubAccountCount: number;
  initFirstName: string;
  initEmail: string;
  initRole: string;
  subAccountId: number;
  isMonthlyPlan: boolean;
};

const SubAccountForm = ({
  onCreatePage,
  showChargeReminder,
  maxSubAccountCount,
  initFirstName,
  initEmail,
  initRole,
  subAccountId,
  isMonthlyPlan,
}: SubAccountFormProps) => {
  const roleOptions = [
    {
      role: "Admin",
      value: "admin",
      description:
        "Full control of the account including adding, editing, and removing other users.",
    },
    {
      role: "Biller",
      value: "biller",
      description:
        "Full access to billing, including allowing overages, updating billing, and changing Affiliate payment information.",
    },
    {
      role: "Manager",
      value: "manager",
      description:
        " Full article creation abilities including access to bulk generator, API, and Wordpress functionality.",
    },
    {
      role: "Specialist",
      value: "specialist",
      description:
        "Can create articles in the interface, no access to Auto-Pilot features.",
    },
    {
      role: "Editor",
      value: "editor",
      description:
        "Can view, edit, and save existing articles. Cannot create new articles.",
    },
  ];
  const validRoles = roleOptions.map((obj) => obj.value);
  const validateEmail = (newEmail: string) => {
    const regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.exec(newEmail) !== null;
  };
  const initialFieldValidation = {
    valid: false,
    errMessage: "This field cannot be blank.",
    value: "",
  };

  const initialEditField = {
    valid: true,
    errMessage: "",
    value: "",
  };

  const validateField = (field: string, newValue: string) => {
    let fieldIsValid = true;
    switch (field) {
      case "firstName":
        if (newValue.trim() === "") {
          setFirstNameField({
            value: newValue,
            valid: false,
            errMessage: "First name cannot be blank.",
          });
          fieldIsValid = false;
        } else {
          setFirstNameField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
        }
        break;
      case "email":
        if (!validateEmail(newValue)) {
          setEmailField({
            value: newValue,
            valid: false,
            errMessage: "Email is invalid.",
          });
          fieldIsValid = false;
        } else {
          setEmailField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
        }
        break;
      case "password":
        // case where length is 0 in creating user is taken care of in initial state
        if (newValue.length < 6 && newValue.length > 0) {
          setPasswordField({
            value: newValue,
            valid: false,
            errMessage: "Password needs to be longer than 6 characters.",
          });
          fieldIsValid = false;
        } else if (newValue != passwordConfirmationField.value) {
          setPasswordField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
          setPasswordConfirmationField({
            ...passwordConfirmationField,
            valid: false,
            errMessage: "Password confirmation is not the same.",
          });
          fieldIsValid = false;
        } else {
          setPasswordField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
          setPasswordConfirmationField({
            ...passwordConfirmationField,
            valid: true,
            errMessage: "",
          });
        }
        break;
      case "passwordConfirmation":
        if (newValue != passwordField.value) {
          setPasswordConfirmationField({
            value: newValue,
            valid: false,
            errMessage: "Password confirmation is not the same.",
          });
          fieldIsValid = false;
        } else {
          setPasswordConfirmationField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
        }
        break;
      case "role":
        if (!validRoles.includes(newValue)) {
          setRoleField({
            value: newValue,
            valid: false,
            errMessage: "Please select a role.",
          });
          fieldIsValid = false;
        } else {
          setRoleField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
        }
        break;
      case "chargeConfirmation":
        if (showChargeReminder && !newValue) {
          setChargeConfirmationField({
            value: newValue,
            valid: false,
            errMessage: "Please confirm.",
          });
          fieldIsValid = false;
        } else {
          setChargeConfirmationField({
            value: newValue,
            valid: true,
            errMessage: "",
          });
        }
        break;
      default:
        break;
    }

    return fieldIsValid;
  };

  const [firstNameField, setFirstNameField] = useState(
    onCreatePage
      ? initialFieldValidation
      : {
          ...initialEditField,
          value: initFirstName,
        }
  );
  const firstNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validateField("firstName", e.target.value);
  };
  const [emailField, setEmailField] = useState(
    onCreatePage
      ? initialFieldValidation
      : {
          ...initialEditField,
          value: initEmail,
        }
  );
  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validateField("email", e.target.value);
  };
  const [passwordField, setPasswordField] = useState(
    onCreatePage ? initialFieldValidation : initialEditField
  );
  const passwordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validateField("password", e.target.value);
  };
  const [passwordConfirmationField, setPasswordConfirmationField] = useState(
    onCreatePage ? initialFieldValidation : initialEditField
  );
  const passwordConfirmationChangeHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    validateField("passwordConfirmation", e.target.value);
  };
  const [roleField, setRoleField] = useState(
    onCreatePage
      ? initialFieldValidation
      : {
          ...initialEditField,
          value: initRole,
        }
  );
  const roleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validateField("role", e.target.value);
  };
  const [chargeConfirmationField, setChargeConfirmationField] = useState({
    ...initialFieldValidation,
    valid: !showChargeReminder,
    errMessage: showChargeReminder ? "This must be checked." : "",
  });
  const chargeConfirmationChangeHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    validateField("chargeConfirmation", e.target.checked.toString());
  };

  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [liveCheck, setLiveCheck] = useState(false);
  const [upserting, setUpserting] = useState(false);

  const validateForm = () => {
    return (
      firstNameField.valid &&
      emailField.valid &&
      passwordField.valid &&
      passwordConfirmationField.valid &&
      roleField.valid &&
      chargeConfirmationField.valid
    );
  };

  const addNewUserHandler = () => {
    setLiveCheck(true);
    if (validateForm()) {
      setUpserting(true);
      setSubmitButtonEnabled(false);
      const data = {
        first_name: firstNameField.value,
        email: emailField.value,
        password: passwordField.value,
        role: roleField.value,
        charge_confirmation_check: showChargeReminder || undefined,
      };

      axios
        .post("/create_sub_account", data)
        .then((res) => {
          if (res.data.status) {
            window.location.href = "/sub_accounts";
          } else {
            window.location.href = "/create_sub_account";
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message)
            window.add_flash_alert_global("warning", err.response.data.message);
        });
    }
  };
  const updateUserHandler = () => {
    setLiveCheck(true);
    if (validateForm()) {
      setUpserting(true);
      setSubmitButtonEnabled(false);
      const data = {
        first_name: firstNameField.value,
        email: emailField.value,
        password: passwordField.value,
        role: roleField.value,
        sub_account_id: subAccountId,
      };
      axios
        .post("/update_sub_account", data)
        .then((res) => {
          if (res.data.status) {
            window.location.href = "/sub_accounts";
          } else {
            window.location.href = `/update_sub_account/${subAccountId}`;
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message)
            window.add_flash_alert_global("warning", err.response.data.message);
          window.location.href = "/update_sub_account";
        });
    }
  };
  const cancelHandler = () => {
    window.location.href = "/sub_accounts";
  };

  const showError = (isValid: boolean) => {
    return !isValid && liveCheck;
  };

  useEffect(() => {
    if (liveCheck) {
      setSubmitButtonEnabled(validateForm());
    }
  }, [
    firstNameField,
    emailField,
    passwordField,
    passwordConfirmationField,
    roleField,
    chargeConfirmationField,
    liveCheck,
    validateForm,
  ]);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
        <Panel title={onCreatePage ? "Add New User" : "Edit User"}>
          <React.Fragment>
            <div className="form-group row">
              <div className="col-md-6">
                <input
                  autoComplete="off"
                  className={`form-control ${
                    showError(firstNameField.valid) ? "is-invalid" : ""
                  }`}
                  placeholder="First Name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={firstNameChangeHandler}
                  value={firstNameField.value}
                />
                <div className="invalid-feedback">
                  {firstNameField.errMessage}
                </div>
              </div>
              <div className="col-md-6">
                <input
                  autoComplete="off"
                  className={`form-control ${
                    showError(emailField.valid) ? "is-invalid" : ""
                  }`}
                  placeholder="Email"
                  type="email"
                  name="email"
                  id="email"
                  onChange={emailChangeHandler}
                  value={emailField.value}
                />
                <div className="invalid-feedback">{emailField.errMessage}</div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6">
                <input
                  autoComplete="new-password"
                  className={`form-control ${
                    showError(passwordField.valid) ? "is-invalid" : ""
                  }`}
                  placeholder={
                    onCreatePage
                      ? "Password (6 characters minimum)"
                      : "New Password (6 characters minimum, optional)"
                  }
                  type="password"
                  name="password"
                  id="password"
                  onChange={passwordChangeHandler}
                />
                <div className="invalid-feedback">
                  {passwordField.errMessage}
                </div>
              </div>
              <div className="col-md-6">
                <input
                  autoComplete="new-password"
                  className={`form-control ${
                    showError(passwordConfirmationField.valid)
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder={
                    onCreatePage
                      ? "Password Confirmation"
                      : "New Password Confirmation"
                  }
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  onChange={passwordConfirmationChangeHandler}
                />
                <div className="invalid-feedback">
                  {passwordConfirmationField.errMessage}
                </div>
              </div>
            </div>
          </React.Fragment>
        </Panel>

        <Panel title="Select Role and Permissions">
          <React.Fragment>
            <div className="form-group row">
              <div className="col-md-12">
                {roleOptions.map((roleOption, i) => (
                  <div key={i} className="radio">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="role"
                        id={`role_${roleOption.value}`}
                        value={roleOption.value}
                        className="custom-control-input"
                        onChange={roleChangeHandler}
                        checked={roleOption.value === roleField.value}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`role_${roleOption.value}`}
                      >
                        <b>{roleOption.role}</b>
                      </label>
                    </div>
                    <p>{roleOption.description}</p>
                  </div>
                ))}
                {showError(roleField.valid) && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {roleField.errMessage}
                  </div>
                )}
              </div>
            </div>
            {showChargeReminder && (
              <React.Fragment>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        showError(chargeConfirmationField.valid)
                          ? "is-invalid"
                          : ""
                      }`}
                      type="checkbox"
                      id="confirm_charge"
                      onChange={chargeConfirmationChangeHandler}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="confirm_charge"
                    >
                      I understand that new users above {maxSubAccountCount}{" "}
                      will be billed at{" "}
                      {isMonthlyPlan ? "$10 per month" : "$120 per year"} until
                      removed. (Your account will be charged the prorated amount
                      immediately.)
                    </label>
                  </div>
                </div>
                <div className="invalid-feedback">
                  {chargeConfirmationField.errMessage}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Panel>

        <Panel>
          <div className="text-center">
            {onCreatePage && (
              <button
                type="button"
                id="create_sub_account"
                className="btn btn-primary text-center"
                style={{ width: 200 }}
                onClick={addNewUserHandler}
                disabled={!submitButtonEnabled}
              >
                {upserting ? "Creating User..." : "Add New User"}
              </button>
            )}
            {!onCreatePage && (
              <button
                type="button"
                id="create_sub_account"
                className="mt-3 btn btn-primary text-center"
                style={{ width: 200 }}
                onClick={updateUserHandler}
                disabled={!submitButtonEnabled}
              >
                {upserting ? "Updating User..." : "Update User"}
              </button>
            )}
            <button
              type="button"
              className="mt-2 btn btn-outline-link rounded-pill btn-block"
              style={{ color: "var(--iq-primary)", width: 200, margin: "auto" }}
              onClick={cancelHandler}
            >
              Cancel
            </button>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default SubAccountForm;
