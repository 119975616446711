import React from "react";
import styles from "./ApiKeyPanelBody.module.scss";

type ApiKeyPanelBodyProps = {
  apiKey: string;
  allowRegenerate: boolean;
  allowDownload: boolean;
  onRegenerateKeyModal: () => void;
};

const ApiKeyPanelBody = ({
  apiKey,
  allowRegenerate,
  allowDownload,
  onRegenerateKeyModal,
}: ApiKeyPanelBodyProps) => {
  return (
    <div>
      <p>
        <strong>API KEY: </strong>
        <code>{apiKey} </code>{" "}
        {allowRegenerate && (
          <button
            className={styles.regenerateButton}
            onClick={onRegenerateKeyModal}
          >
            Regenerate Key
          </button>
        )}
      </p>
      {allowDownload && (
        <div>
          <div style={{ marginBottom: "1em" }}>
            <a className={styles.downloadButton} href="/download_api">
              Click Here to Download API Documentation
            </a>
            <span>
              {" "}
              <span>Last Update: 2023-05-10</span>
            </span>
          </div>
          <div style={{ marginBottom: "1em" }}>
            <a className={styles.downloadButton} href="/download_changelog">
              Click Here to Download API Changelog
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeyPanelBody;
