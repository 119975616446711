import React, { ReactNode, useRef } from "react";
import clsx from "clsx";
import useOnClickOutside from "Common/UI/useOnClickOutside";
import styles from "./Modal.module.scss";

type ModalProps = {
  children: ReactNode;
  onClose: () => void;
  id?: string;
  addMargin?: boolean;
  size?: "sm" | "lg";
};

const Modal = ({ size, onClose, children, addMargin, id }: ModalProps) => {
  const elemRef = useRef(null);
  useOnClickOutside(elemRef, onClose);
  return (
    <div className={styles.modal} id={id}>
      <div
        ref={elemRef}
        className={clsx("modal-dialog", {
          "modal-lg": size === "lg",
          "modal-sm": size === "sm",
        })}
        style={{ top: "-50px" }}
      >
        <div className="modal-content">
          {addMargin ? <div className="iq-card">{children}</div> : children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
