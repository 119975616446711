import React from "react";
import { Blog } from "../WordpressBlogApi";
import styles from "./BlogInput.module.scss";

type BlogInputProps = {
  blog: Blog;
  value: number | undefined;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const truncateBlogName = (blogUrl: string, blogName: string) => {
  const displayName = blogUrl + " (" + blogName + ")";
  if (displayName.length > 72) return displayName.substring(0, 71) + "...";
  else return displayName;
};

const BlogInput = (props: BlogInputProps) => {
  return (
    <div
      className="input-group"
      style={{
        paddingBottom: "5px",
      }}
      key={props.blog.blog_id}
    >
      <input
        className="form-control"
        data-wp-blog-name={props.blog.name}
        data-wp-blog-id={props.blog.blog_id}
        type="number"
        min={0}
        onChange={props.handleChange}
        value={props.value}
        style={{ maxWidth: "15%" }}
      />
      <span className={styles.blogUrlSpan}>
        <div
          style={{
            position: "relative",
            top: "10px",
            paddingLeft: "10px",
          }}
        >
          {truncateBlogName(props.blog.blog_url, props.blog.name)}
        </div>
      </span>
    </div>
  );
};

export default BlogInput;
