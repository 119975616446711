import React, { useState, MouseEvent, useEffect } from "react";
import Editor from "./Editor";
import WordPressModal from "Article/components/WordpressModal";
import WordAiModal from "Article/components/WordAiModal";
import BatchExportModal, {
  BatchExportType,
} from "Article/components/BatchExportModal";
import ArticleApi, { Article } from "../ArticleApi";
import SpintaxToWpModal from "Article/components/SpintaxToWpModal";
import { copyHTMLToClipboard } from "Common/BrowserUtil";
import Panel from "Common/UI/Panel";
import useModalState from "Common/UI/useModalState";
import ExportDropdown, { ExportType } from "Article/components/ExportDropdown";
import clsx from "clsx";
import WordAiLogo from "../../../assets/images/word-ai-logo.png";
import { formatArticleAsText } from "Article/ArticleParser";

type RawArticlePanelProps = Readonly<{
  allowWordAi: boolean;
  allowWP: boolean;
  article: Article;
  wordAiValid: boolean;
  onWordAiLogin: () => void;
  hasSpintax: boolean;
  onReloadArticle: (article: Article) => void;
}>;

const RawArticlePanel = (props: RawArticlePanelProps) => {
  const [codeViewActive, setCodeViewActive] = useState(false);
  const [model, setModel] = useState(props.article.spintax);
  const [isModelEdited, setIsModelEdited] = useState(false);
  const [isWPModalOpen, handleOpenWPModal, handleCloseWPModal] =
    useModalState();
  const [
    isSpintaxWPModalOpen,
    handleOpenSpintaxWPModal,
    handleCloseSpintaxWPModal,
  ] = useModalState();
  const [batchExportModal, setBatchExportModal] = useState<
    BatchExportType | false
  >(false);
  const [isWordAiModalOpen, handleOpenWordAiModal, handleCloseWordAiModal] =
    useModalState();
  const [showWordaiSpin, setShowWordaiSpin] = useState(
    props.article.showWordaiSpin
  );

  const handleModelChange = (model: string, dirty: boolean) => {
    setModel(model);
    setIsModelEdited(dirty);
  };

  const handleExport = (type: ExportType) => {
    const source = props.hasSpintax ? "spintax" : "article";
    window.location.href = `/export_${type}_article?id=${props.article.id}`;

    window._kmq.push([
      "record",
      "Article Export",
      {
        target: source,
        type: "Export",
        export_type: type,
      },
    ]);
  };

  const handleSave = () => {
    ArticleApi.update(props.article.id, { spintax: model ?? "" }).then(() => {
      setIsModelEdited(false);
      window.add_flash_alert_global("success", "Article Saved Successfully!");
      window._kmq.push([
        "record",
        "Article Export",
        {
          target: "Spintax Container",
          type: "Save Button Clicked",
        },
      ]);
    });
  };

  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const html = model ?? "";
    const text = formatArticleAsText(html);
    if (codeViewActive) {
      navigator.clipboard.writeText(html).then(() => {
        window.noty_helper(
          "Article Copied to Clipboard as HTML",
          "success",
          5000
        );
      });
    } else {
      copyHTMLToClipboard(html, text).then(() => {
        window.noty_helper(
          "Article Copied to Clipboard as Text",
          "success",
          5000
        );
      });
    }
    window._kmq.push([
      "record",
      "Article Export",
      {
        target: props.hasSpintax
          ? "Spintax Container"
          : "Generated Article Container",
        type: "Copy Button Clicked",
      },
    ]);
  };

  const handleWordAiSuccess = (text: string) => {
    return ArticleApi.update(props.article.id, { spintax: text }).then(
      (article) => {
        handleModelChange(text, false);
        setIsModelEdited(false);
        setShowWordaiSpin(false);
        props.onReloadArticle(article);
      }
    );
  };

  useEffect(() => {
    const alertIfModified = (e: BeforeUnloadEvent) => {
      if (isModelEdited) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", alertIfModified);
    return () => {
      window.removeEventListener("beforeunload", alertIfModified);
    };
  }, [isModelEdited]);

  return (
    <div>
      {isWPModalOpen && (
        <WordPressModal
          article={props.article}
          content={model}
          onClose={handleCloseWPModal}
        />
      )}
      {isWordAiModalOpen && (
        <WordAiModal
          wordAiValid={props.wordAiValid}
          onClose={handleCloseWordAiModal}
          articleId={props.article.id}
          onWordAiLogin={props.onWordAiLogin}
          onSuccess={handleWordAiSuccess}
        />
      )}
      {isSpintaxWPModalOpen && (
        <SpintaxToWpModal
          article={props.article}
          onClose={handleCloseSpintaxWPModal}
        />
      )}
      {batchExportModal && (
        <BatchExportModal
          article={props.article}
          onClose={() => setBatchExportModal(false)}
          type={batchExportModal}
        />
      )}
      <Panel title={props.hasSpintax ? "Spintax" : "Generated Article"}>
        <div
          className="btn-group"
          role="group"
          aria-label="Button group with nested dropdown"
        >
          <ExportDropdown
            onExport={handleExport}
            onPostToWordPress={
              props.hasSpintax ? handleOpenSpintaxWPModal : handleOpenWPModal
            }
            onBatchExport={props.hasSpintax ? setBatchExportModal : undefined}
          />
          <button className="btn btn-outline-dark" onClick={handleCopy}>
            <i className="ri-clipboard-fill" />
            Copy
          </button>
          <button
            id="refresh_save_btn"
            className={clsx("btn", "btn-outline-dark", {
              "last-btn": !showWordaiSpin,
            })}
            onClick={handleSave}
          >
            <i className="ri-save-fill" /> Save
          </button>
          {props.allowWordAi && showWordaiSpin && (
            <button
              id="spin_wordai_btn"
              className="btn btn-outline-dark last-btn"
              onClick={handleOpenWordAiModal}
            >
              Rewrite with{" "}
              <img
                src={WordAiLogo}
                alt="WordAi"
                style={{
                  display: "inline",
                  height: "14px",
                  marginBottom: "2px",
                }}
              />
            </button>
          )}
        </div>

        {isModelEdited && (
          <div className="col-xs-12 help-block" style={{ color: "red" }}>
            Don't forget to save the article or your changes will be lost.
          </div>
        )}
        <div style={{ paddingTop: "50px" }}>
          <Editor
            article={props.article}
            onModelChange={handleModelChange}
            model={model}
            hasSpintax={props.hasSpintax}
            onCodeViewUpdated={setCodeViewActive}
          />
        </div>
      </Panel>
    </div>
  );
};

export default RawArticlePanel;
